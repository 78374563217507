import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import * as actions from "../../actions/product";
import { CHARGE_NVOD, TOAST_INFO } from "../../actions/types";
import { get } from "../../helper/requests";
import { lineAdder, timeAdder } from "../../helper/helper";
import PleaseLogin from "../modals/PleaseLogin";
import * as messageActions from "../../actions/messages";

function Movie(props) {
  const { id, inDate, productId, smsCode, time } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [movie, setMovie] = useState(null);
  const auth = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  function onClose() {
    setIsOpen(false);
  }
  const isLoggedIn = auth.isLoggedIn;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoaded) {
      get(`vodlist/prm/${id}/${productId}/${inDate}`, (res) => {
        setMovie(res.data);
      });
      setIsLoaded(true);
    }
  }, [movie, id, inDate, productId, isLoaded]);
  return (
    <>
      <div
        className="page-content"
        style={{
          backgroundImage: `url("${movie?.posterUrl}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: " 100%",
          zIndex: "0",
        }}
      >
        <div
          className="section-for-entertainment-first movie-one-by "
          style={{ marginTop: "13%" }}
        >
          <div className="bluuur"></div>
          <div className="row container section-for-movie-slider one-movie">
            <div className="small-12 columns ">
              <div className="medium-5 columns text-center poster">
                <div>
                  <img src={movie?.posterUrl} alt={movie?.contentNameMon} />
                </div>
                <div className="row">
                  <div className="small-5 medium-5 column">
                    <div
                      className="dd-values-con-big"
                      style={{ marginTop: "-12px" }}
                    >
                      <v>{movie?.contentPrice}₮</v>
                    </div>
                  </div>
                  <div className="small-7 medium-7 column">
                    <a
                      onClick={() => {
                        if (isLoggedIn) {
                          if (movie?.isOrdered) {
                            props.setMessage({
                              type: TOAST_INFO,
                              text: "Та энэ киног захиалсан байна",
                            });
                          } else {
                            props.selectProduct({
                              orderType: CHARGE_NVOD,
                              product: {
                                productName: `${
                                  movie.contentNameMon
                                } (${lineAdder(inDate)})`,
                                productId: productId,
                                smsCode: smsCode,
                                inDate,
                              },
                              price: parseInt(
                                movie?.isPromo
                                  ? movie?.contentPromoPrice
                                  : movie?.contentPrice
                              ),
                            });
                            navigate(`/order`);
                          }
                        } else setIsOpen(true);
                      }}
                      href
                      disabled={isLoggedIn && movie?.isOrdered}
                      className={"button had-icon icon-active"}
                    >
                      <div style={{ fontSize: 17 }}>
                        {isLoggedIn && movie?.isOrdered
                          ? "ТҮРЭЭСЛЭСЭН"
                          : " ТҮРЭЭСЛЭХ"}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="medium-7 columns short-desc">
                <h2>
                  {movie?.contentNameMon} ({movie?.contentYear})
                </h2>
                <div className="row details">
                  <div className="small-6 medium-3 columns">Найруулагч:</div>
                  <div className="small-6 medium-9 columns">
                    <span>{movie?.directors}</span>
                  </div>
                </div>
                <div className="row details">
                  <div className="small-6 medium-3 columns">Жүжигчид:</div>
                  <div className="small-6 medium-9 columns">
                    <span>{movie?.actors}</span>
                  </div>
                </div>
                <div className="row details">
                  <div className=" small-6 medium-3 columns">
                    Үргэлжлэх хугацаа:
                  </div>
                  <div className=" small-6 medium-9 columns">
                    {movie?.contentDuration} минут
                  </div>
                </div>
                <div className="row details">
                  <div className="small-12 columns">
                    <div className="movie-content-synopsis">
                      {movie?.contentDescr}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <ul className="dd-programs">
                    <span>Өдөр: {lineAdder(inDate)}</span>
                    <span>Цаг: {timeAdder(time)}</span>
                    <span>Код: {smsCode}</span>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PleaseLogin isOpen={isOpen} onHide={onClose} />
    </>
  );
}

const mapActionToProps = (dispatch) => {
  return {
    selectProduct: (product) => dispatch(actions.selectProduct(product)),
    setMessage: (payload) => dispatch(messageActions.setMessage(payload)),
  };
};

export default connect(null, mapActionToProps)(Movie);
