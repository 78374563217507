import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Form, Row, Accordion } from "react-bootstrap";
import { webApiUrl, webUrl } from "../../helper/globals";

export default function AllChannels() {
  const [isLoaded, setIsLoaded] = useState(false);
  const categories = [
    { value: "all", name: "Бүх суваг" },
    { value: "mongol", name: "Монгол суваг" },
    { value: "sport", name: "Спорт суваг" },
    { value: "foreign", name: "Гадаад суваг" },
    { value: "movie", name: "Кино суваг" },
    { value: "paid", name: "Төлбөрт суваг" },
  ];
  const [mongol, setMongol] = useState([]);
  const [mongolFiltered, setMongolFiltered] = useState([]);
  const [sport, setSport] = useState([]);
  const [sportFiltered, setSportFiltered] = useState([]);
  const [foreign, setForeign] = useState([]);
  const [foreignFiltered, setForeignFiltered] = useState([]);
  const [movie, setMovie] = useState([]);
  const [movieFiltered, setMovieFiltered] = useState([]);
  const [paid, setPaid] = useState([]);
  const [paidFiltered, setPaidFiltered] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedFilter, setSelectedFilter] = useState("all");
  useEffect(() => {
    if (!isLoaded) {
      axios.get(webApiUrl + `get_channels/mongol`).then((res) => {
        setMongol({
          channelText: res.data.channelText,
          channels: res.data.result,
        });
      });
      axios.get(webApiUrl + `get_channels/sport`).then((res) => {
        setSport({
          channelText: res.data.channelText,
          channels: res.data.result,
        });
      });
      axios.get(webApiUrl + `get_channels/foreign`).then((res) => {
        setForeign({
          channelText: res.data.channelText,
          channels: res.data.result,
        });
      });
      axios.get(webApiUrl + `get_channels/movie`).then((res) => {
        setMovie({
          channelText: res.data.channelText,
          channels: res.data.result,
        });
      });
      axios.get(webApiUrl + `get_channels/paid`).then((res) => {
        setPaid({
          channelText: res.data.channelText,
          channels: res.data.result,
        });
      });
      setIsLoaded(true);
    }
  }, [isLoaded]);
  useEffect(() => {
    setMongolFiltered(filter(mongol, selectedFilter));
    setSportFiltered(filter(sport, selectedFilter));
    setForeignFiltered(filter(foreign, selectedFilter));
    setMovieFiltered(filter(movie, selectedFilter));
    setPaidFiltered(filter(paid, selectedFilter));
  }, [selectedFilter]);
  function filter(channels, filter) {
    let rChannel = [];
    let res = { channelText: channels.channelText, channels: [] };
    if (channels.channels) {
      Array.from(channels?.channels).forEach((c) => {
        if (c.package.indexOf(filter) > -1) {
          rChannel.push(c);
        }
      });
      return { ...res, channels: rChannel };
    }
  }
  function channel(channels) {
    return (
      <>
        <ul className="medium-up-2 large-up-3 block m-channel">
          {channels?.channels?.length > 0 ? (
            channels?.channels?.map((channel) => {
              return (
                <li className="column">
                  {
                    <div
                      className={
                        (channel.format === "HD" ? "hd" : "sd") + " item "
                      }
                    >
                      <img
                        src={webUrl + channel.logo}
                        alt={channel.name}
                        width="60"
                        height="42"
                      />

                      <h6>
                        <span class="dd-product-name">{channel.number}</span>
                      </h6>
                      <span class="dd-product-package-group">
                        <span class="dd-product-package">
                          <img
                            src={
                              channel.package.indexOf("S") > -1
                                ? "/Standard_icon.jpg"
                                : "/silver.jfif"
                            }
                            alt="S"
                            style={{
                              width: "18px",
                              height: "18px",
                              borderRadius: "3px",
                            }}
                          />
                        </span>
                        <span class="dd-product-package">
                          <img
                            src={
                              channel.package.indexOf("M") > -1
                                ? "/Happy_icon.jpg"
                                : "/silver.jfif"
                            }
                            alt="M"
                            style={{
                              width: "18px",
                              height: "18px",
                              borderRadius: "3px",
                            }}
                          />
                        </span>
                        <span class="dd-product-package">
                          <img
                            src={
                              channel.package.indexOf(`L,`) > -1
                                ? "/Super_icon.jpg"
                                : "/silver.jfif"
                            }
                            style={{
                              width: "18px",
                              height: "18px",
                              borderRadius: "3px",
                            }}
                            alt="L"
                          />
                        </span>
                      </span>
                    </div>
                  }
                </li>
              );
            })
          ) : (
            <span>Илэрц хоосон байна.</span>
          )}
        </ul>
      </>
    );
  }
  return (
    <>
      <Row>
        <Col sm={8} md={6} style={{ display: "flex", gap: "10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src="/Standard_icon.jpg"
              className={selectedFilter === "S" ? "selected" : "unselected"}
              alt="s"
              onClick={() => {
                setSelectedFilter("S");
              }}
            />
            <span
              style={{
                fontWeight: "700",
                fontSize: "22px",
                color: "#141450",
              }}
            >
              Standard
            </span>
          </div>
          <div>
            <img
              src="/Happy_icon.jpg"
              className={selectedFilter === "M" ? "selected" : "unselected"}
              alt="m"
              onClick={() => {
                setSelectedFilter("M");
              }}
            />
            <span
              style={{
                fontWeight: "700",
                fontSize: "22px",
                color: "#141450",
              }}
            >
              Happy
            </span>
          </div>
          <div>
            <img
              src="/Super_icon.jpg"
              className={selectedFilter === "L," ? "selected" : "unselected"}
              alt="l"
              onClick={() => {
                setSelectedFilter(`L,`);
              }}
            />
            <span
              style={{
                fontWeight: "700",
                fontSize: "22px",
                color: "#141450",
              }}
            >
              Super
            </span>
          </div>
        </Col>
        <Col sm={1} md={3}>
          &nbsp;
        </Col>

        <Col sm={12} md={3}>
          <Form
            name="category"
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <Form.Select
              name="categorySelect"
              onChange={(e) => {
                setSelectedCategory(e.target.value);
              }}
            >
              {categories.map((e) => {
                return <option value={e.value}>{e.name}</option>;
              })}
            </Form.Select>
          </Form>
        </Col>
      </Row>
      <Row>
        <Row>
          <div className="mt-2"></div>
        </Row>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h6 class="">МОНГОЛ СУВГУУД</h6>
            </Accordion.Header>
            <Accordion.Body>
              {selectedCategory === "all" || selectedCategory === "mongol" ? (
                selectedFilter === "all" ? (
                  channel(mongol)
                ) : (
                  channel(mongolFiltered)
                )
              ) : (
                <span>Илэрц олдсонгүй</span>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h6 class="">СПОРТ СУВГУУД</h6>
            </Accordion.Header>
            <Accordion.Body>
              {selectedCategory === "all" || selectedCategory === "sport" ? (
                selectedFilter === "all" ? (
                  channel(sport)
                ) : (
                  channel(sportFiltered)
                )
              ) : (
                <span>Илэрц олдсонгүй</span>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <h6 class="">ГАДААД СУВГУУД</h6>
            </Accordion.Header>
            <Accordion.Body>
              {selectedCategory === "all" || selectedCategory === "foreign" ? (
                selectedFilter === "all" ? (
                  channel(foreign)
                ) : (
                  channel(foreignFiltered)
                )
              ) : (
                <span>Илэрц олдсонгүй</span>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              {" "}
              <h6 class="">КИНО СУВГУУД</h6>
            </Accordion.Header>
            <Accordion.Body>
              {selectedCategory === "all" || selectedCategory === "movie" ? (
                selectedFilter === "all" ? (
                  channel(movie)
                ) : (
                  channel(movieFiltered)
                )
              ) : (
                <span>Илэрц олдсонгүй</span>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <h6 class="">ТӨЛБӨРТ СУВГУУД</h6>
            </Accordion.Header>
            <Accordion.Body>
              {selectedCategory === "all" || selectedCategory === "paid" ? (
                selectedFilter === "all" ? (
                  channel(paid)
                ) : (
                  channel(paidFiltered)
                )
              ) : (
                <span>Илэрц олдсонгүй</span>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </>
  );
}
