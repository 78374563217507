import {
  GET_PRODUCT_FAIL,
  GET_PRODUCT_SUCCESS,
  GET_ID,
  GET_ADDITIONAL_PRODUCT_SUCCESS,
  GET_ADDITIONAL_PRODUCT_FAIL,
  GET_UPGRADE_PRODUCT_SUCCESS,
  GET_UPGRADE_PRODUCT_FAIL,
  SELECT_PRODUCT,
  CLEAR_PRODUCT,
  GET_NOTT,
  LOGOUT,
} from "../actions/types";

const initialState = null;
const productReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PRODUCT_SUCCESS: {
      return {
        ...state,
        isLeasingUser: payload.isLeasingUser,
        productList: payload.productList,
        err: null,
      };
    }
    case GET_PRODUCT_FAIL: {
      return { ...state, err: payload.err };
    }

    case GET_ID: {
      return {
        ...state,
        productId: payload.productId,
      };
    }

    case GET_ADDITIONAL_PRODUCT_SUCCESS: {
      return {
        ...state,
        additionalProductList: payload.additionalProducts,
        err: null,
      };
    }
    case GET_ADDITIONAL_PRODUCT_FAIL: {
      return state;
    }
    case GET_UPGRADE_PRODUCT_SUCCESS: {
      return {
        ...state,
        upgradeProductList: payload.upgradeProducts,
        err: null,
      };
    }
    case GET_UPGRADE_PRODUCT_FAIL: {
      return { ...state, err: payload.err };
    }

    case GET_NOTT: {
      return {
        ...state,
        nott: payload.nott,
      };
    }

    case SELECT_PRODUCT: {
      return {
        ...state,
        selectedProduct: payload,
      };
    }

    case CLEAR_PRODUCT: {
      return {
        ...state,
        selectedProduct: null,
      };
    }

    case LOGOUT:{
      return null;
    }
    default:
      return state;
  }
};

export default productReducer;
