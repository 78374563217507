import {
  Button,
  Col,
  Form,
  Row,
  FormControl,
  Tab,
  ListGroup,
  Modal,
} from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import placeHolder from "../../assets/img/placeholder.png";
import { useNavigate } from "react-router";
import { useState } from "react";

import * as actions from "../../actions/product";
import * as rvodActions from "../../actions/rvod";
import * as userActions from "../../actions/user";

import * as messageActions from "../../actions/messages";

import NOTT from "./NOTT";

import { CHARGE_RVOD, TOAST_INFO } from "../../actions/types";
import { getSecured } from "../../helper/requests";

function RVOD(props) {
  const rvod = useSelector((state) => state.rvod);
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);

  const [modalShow, setModalShow] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  if (rvod == null) {
    props.getNewArrivals();
    props.checkType();
  }
  const navigate = useNavigate();
  function onHide() {
    setModalShow(false);
  }

  function searchMovies(movieId) {
    getSecured(
      `searchaRvod/${movieId}`,
      (res) => {
        setSearchResults({ ...res.data, contentId: movieId });
        setModalShow(true);
      },
      (err) => {
        console.log(err);
        if (user?.isPostpaid) {
          props.setMessage({
            type: TOAST_INFO,
            text: "Дараа төлбөрт хэрэглэгч тул уг үйлчилгээг авах боломжгүй",
          });
        } else
          props.setMessage({ type: TOAST_INFO, text: err.data.resultMessage });
      }
    );
  }
  var search = () => {
    return (
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          searchMovies(event.target[0].value);
        }}
      >
        <Row>
          <Col md={8}>
            <FormControl
              name="movieId"
              type="text"
              component="input"
              placeHolder="Хайх киноны ID"
            />
          </Col>
          <Col md={4}>
            <Row>
              <Button type="submit" className="mb-2">
                Хайх
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  };

  var movieCard = (movie) => {
    return (
      <Row style={{ bottomMargin: "10px" }}>
        <Col sm="3">
          <img
            src={movie.contentImgUrl !== "" ? movie.contentImgUrl : placeHolder}
            onError={(err) => {
              err.target.onError = null;
              err.target.src = placeHolder;
            }}
            style={{
              width: "240px",
              height: "310px",
            }}
            alt={movie.contentNameMon}
          />
        </Col>
        <Col sm="8">
          <h3 style={{ color: "#0047ba" }}>{movie.contentName}</h3>
          <br />
          <p>{movie.contentDesc}</p>
          <p>
            Код:<span className="movie-content-code">{movie.contentId}</span>
          </p>
          Үнэ:
          <span className="movie-content-code">{movie.contentPrice}₮</span>
        </Col>
      </Row>
    );
  };
  var newArrivals = () => {
    return (
      <>
        <h2>Шинээр нэмэгдэх контентууд</h2>

        {rvod?.newArrivals?.map((movie) => {
          return <Row style={{ marginBottom: "10px" }}>{movieCard(movie)}</Row>;
        })}
      </>
    );
  };
  return (
    <>
      <Tab.Container defaultActiveKey="#rvod">
        <ListGroup horizontal>
          <ListGroup.Item action href="#rvod" className="tabs-title tabs">
            Кино сан
          </ListGroup.Item>
          <ListGroup.Item action href="#nott" className="tabs-title tabs">
            Кино багц
          </ListGroup.Item>
        </ListGroup>

        <Tab.Content></Tab.Content>
        <Tab.Content>
          <Tab.Pane eventKey="#rvod">
            {auth.isLoggedIn ? <>{search()}</> : null}

            {newArrivals()}
          </Tab.Pane>
        </Tab.Content>
        <Tab.Content>
          <Tab.Pane eventKey="#nott">
            <NOTT />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      <Modal centered show={modalShow}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Анхааруулга
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Та <b>{searchResults?.contentNameMon}</b> киног &nbsp;
            <b>
              {searchResults?.isPromo
                ? searchResults?.promoArPrice
                : searchResults?.arPrice}
            </b>
            ₮-р түрээслэх гэж байна.
          </p>
          <p>
            <b>
              <i>Анхаарах:</i>
            </b>
            ТА 32 GB дээш багтаамжтай флаш ашигладаг бол дараах цэсээр
            үргэлжлүүлэн киногоо захиалан үзээрэй.
          </p>
          <p>
            Хэрвээ <b>800</b> сувгаас кино түрээслэж үзэх бол <b>Кино театр</b>
            &nbsp; цэсрүү орж захиалаарай
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.selectProduct({
                orderType: CHARGE_RVOD,
                product: {
                  productName: searchResults?.contentNameMon,
                  contentId: searchResults?.contentId,
                },

                price: parseInt(
                  searchResults?.isPromo
                    ? searchResults?.promoArPrice
                    : searchResults?.arPrice
                ),
              });
              navigate("/order");
            }}
          >
            Түрээслэх
          </Button>
          <Button onClick={onHide}>Болих</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapActionToProps = (dispatch) => {
  return {
    getNewArrivals: () => dispatch(rvodActions.getNewArrivals()),
    selectProduct: (product) => dispatch(actions.selectProduct(product)),
    setMessage: (message) => dispatch(messageActions.setMessage(message)),
    checkType: () => dispatch(userActions.checkType()),
  };
};
export default connect(null, mapActionToProps)(RVOD);
