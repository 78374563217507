import { getSecured } from "../helper/requests";
import { setMessage } from "./messages";
import {
  CHARGE_BEGIN,
  CHARGE_FAIL,
  CHARGE_NVOD,
  CHARGE_PRODUCT,
  CHARGE_SUCCESS,
  ORDER_START,
  ORDER_SUCCESS,
  UPOINT_PAYMENT,
  CHARGE_LIVE,
  ORDER_LIVE_BEGIN,
  ORDER_LIVE_SUCCESS,
  ORDER_LIVE_ERROR,
  ORDER_NVOD_BEGIN,
  ORDER_NVOD_SUCCESS,
  ORDER_NVOD_ERROR,
  TOAST_INFO,
  TOAST_WARNING,
  CHARGE_ACCOUNT,
  CHARGE_OTHER_ACCOUNT,
} from "./types";

export const upointOrder = (order) => {
  return function (dispatch) {
    dispatch({
      type: ORDER_START,
      payload: {
        order: order,
        method: UPOINT_PAYMENT,
      },
    });
    switch (order.orderType.code) {
      case CHARGE_ACCOUNT.code: {
        return dispatch(chargeAccount(order.price));
      }
      case CHARGE_OTHER_ACCOUNT.code: {
        return dispatch(chargeOtherAccount(order.product.cardNo, order.price));
      }
      case CHARGE_PRODUCT.code: {
        return dispatch(
          charge(
            order.product.productId,
            order.product.selected.month,
            order.price
          )
        );
      }

      case CHARGE_LIVE.code: {
        return dispatch(
          orderLive(
            order.product.liveChannel,
            order.product.smsCode,
            order.price
          )
        );
      }
      case CHARGE_NVOD.code: {
        return dispatch(
          orderNvod(
            order.product.productId,
            order.product.smsCode,
            order.product.inDate,
            order.price
          )
        );
      }
      default:
        return;
    }
  };
};

export const charge = (productId, month, amount) => {
  return function (dispatch) {
    try {
      dispatch({
        type: CHARGE_BEGIN,
        payload: {
          productId: productId,
          month: month,
          amount: amount,
        },
      });
      getSecured(
        `upoint/${productId}/${month}/${amount}/`,
        (res) => {
          dispatch({
            type: CHARGE_SUCCESS,
            payload: res.data,
          });
          dispatch({
            type: ORDER_SUCCESS,
            payload: {
              isSuccess: res.data.isSuccess,
              method: UPOINT_PAYMENT,
            },
          });
          dispatch(
            setMessage({ type: TOAST_INFO, text: "Багцыг амжилттай сунгалаа." })
          );
        },
        (err) => {
          dispatch({ type: CHARGE_FAIL, payload: err });
          dispatch(
            setMessage({ type: TOAST_WARNING, text: err.data.resultMessage })
          );
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
};
export const chargeAccount = (amount) => {
  return function (dispatch) {
    try {
      dispatch({
        type: CHARGE_BEGIN,
        payload: {
          amount: amount,
        },
      });
      getSecured(
        `upoint/${amount}/`,
        (res) => {
          dispatch({
            type: CHARGE_SUCCESS,
            payload: res.data,
          });
          dispatch({
            type: ORDER_SUCCESS,
            payload: {
              isSuccess: res.data.isSuccess,
              method: UPOINT_PAYMENT,
            },
          });
          dispatch(
            setMessage({ type: TOAST_INFO, text: "Данс амжилттай цэнэглэлээ." })
          );
        },
        (err) => {
          dispatch({ type: CHARGE_FAIL, payload: err });
          dispatch(
            setMessage({ type: TOAST_WARNING, text: err.data.resultMessage })
          );
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
};
export const chargeOtherAccount = (cardNo, amount) => {
  return function (dispatch) {
    try {
      dispatch({
        type: CHARGE_BEGIN,
        payload: {
          amount: amount,
        },
      });
      getSecured(
        `upoint/${cardNo}/${amount}/`,
        (res) => {
          dispatch({
            type: CHARGE_SUCCESS,
            payload: res.data,
          });
          dispatch({
            type: ORDER_SUCCESS,
            payload: {
              isSuccess: res.data.isSuccess,
              method: UPOINT_PAYMENT,
            },
          });
          dispatch(
            setMessage({ type: TOAST_INFO, text: "Данс амжилттай цэнэглэлээ." })
          );
        },
        (err) => {
          dispatch({ type: CHARGE_FAIL, payload: err });
          dispatch(
            setMessage({ type: TOAST_WARNING, text: err.data.resultMessage })
          );
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
};

export const orderNvod = (productId, smsCode, inDate, amount) => {
  return function (dispatch) {
    dispatch({
      type: ORDER_NVOD_BEGIN,
      payload: {
        productId: productId,
        smsCode: smsCode,
        inDate: inDate,
      },
    });

    getSecured(
      `upoint/${productId}/${smsCode}/${inDate}/${amount}`,
      (res) => {
        dispatch({
          type: ORDER_NVOD_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: ORDER_SUCCESS,
          payload: {
            isSuccess: res.data.isSuccess,
            method: UPOINT_PAYMENT,
          },
        });
        dispatch(
          setMessage({
            type: TOAST_INFO,
            text: "Контентыг амжилттай захиаллаа.",
          })
        );
      },
      (err) => {
        dispatch({
          type: ORDER_NVOD_ERROR,
          payload: err,
        });
        dispatch(
          setMessage({ type: TOAST_WARNING, text: err.data.resultMessage })
        );
      }
    );
  };
};

export const orderLive = (channel, smsCode, amount) => {
  return function (dispatch) {
    dispatch({
      type: ORDER_LIVE_BEGIN,
      payload: {
        channel: channel,
        smsCode: smsCode,
        amount: amount,
      },
    });

    getSecured(
      `upoint/live/${channel}/${smsCode}/${amount}`,
      (res) => {
        dispatch({
          type: ORDER_LIVE_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: ORDER_SUCCESS,
          payload: {
            isSuccess: res.data.isSuccess,
            method: UPOINT_PAYMENT,
          },
        });
        dispatch(
          setMessage({
            type: TOAST_INFO,
            text: "Live амжилттай захиаллаа.",
          })
        );
      },
      (err) => {
        dispatch({
          type: ORDER_LIVE_ERROR,
          payload: err,
        });
        dispatch(
          setMessage({ type: TOAST_WARNING, text: err.data.resultMessage })
        );
      }
    );
  };
};
