import {
  KHAN_CREATE_ORDER,
  ORDER_CLEAR,
  QPAY_CREATE_ORDER,
  QPAY_CLEAR
} from "../actions/types";

const initialState = null;
export const merchantReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case KHAN_CREATE_ORDER: {
      return {
        ...state,
        orderId: payload,
      };
    }

    case QPAY_CREATE_ORDER: {
      return {
        ...state,
        qpay: payload,
      };
    }
    case QPAY_CLEAR: {
      return {
        ...state,
        qpay: null,
      };
    }
    case ORDER_CLEAR: {
      return null;
    }
    default: {
      return state;
    }
  }
};
