import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Row, Col, Container, Button, Alert } from "react-bootstrap";

import { CHARGE_LIVE } from "../../actions/types";
import * as userActions from "../../actions/user";
import * as liveActions from "../../actions/live";
import * as actions from "../../actions/product";
import * as messageActions from "../../actions/messages";
import { TOAST_WARNING } from "../../actions/types";

import PleaseLogin from "../modals/PleaseLogin";

import placeHolder from "../../assets/img/placeholder.png";
import { useEffect, useState } from "react";
import Banner from "../Layout/Banner";

function Live(props) {
  const live = useSelector((state) => state.live);
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    props.getLive();
    if (auth.isLoggedIn) {
      props.getInfo();
      props.checkType();
    }
  }, [auth.isLoggedIn]);

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  function onClose() {
    setIsOpen(false);
  }
  var liveBox = (l, index) => {
    const tempoString = "Super 15";
    return (
      <Row key={index} style={{ marginTop: "10px" }}>
        <Col md={3}>
          <img
            src={l.posterUrl === "" ? placeHolder : l.posterUrl}
            alt={l.eventName}
          />
        </Col>
        <Col md={9}>
          <h3 style={{ color: "#0047ba" }}>{l.eventName}</h3>
          <p>
            Цаг:
            <span className="movie-content-code">{l.eventStartDate}</span>
          </p>
          {l.eventName.startsWith(tempoString) ? (
            <p>
              Үнэ:
              <span className="movie-content-code">
                {" "}
                0₮
                <span
                  style={{
                    textDecoration: "line-through",
                    color: "red",
                    marginLeft: "5px",
                  }}
                >
                  {l.eventPrice} ₮
                </span>
              </span>
            </p>
          ) : (
            <p>
              Үнэ:
              <span className="movie-content-code">
                {" "}
                {l.isPromo ? l.eventPromoPrice : l.eventPrice}₮
                <span
                  style={{
                    textDecoration: "line-through",
                    color: "red",
                    marginLeft: "5px",
                  }}
                >
                  {l.isPromo ? `${l.eventPrice} ₮` : ""}
                </span>
              </span>
            </p>
          )}
          {/* <p>
            Үнэ:
            <span className="movie-content-code">
              {" "}
              {l.isPromo ? l.eventPromoPrice : l.eventPrice}₮
              <span
                style={{
                  textDecoration: "line-through",
                  color: "red",
                  marginLeft: "5px",
                }}
              >
                {l.isPromo ? `${l.eventPrice} ₮` : ""}
              </span>
            </span>
          </p> */}
          <p>
            Код:
            <span className="movie-content-code">{l.smsCode}</span>
          </p>
          <p className="text-justify">{l.eventDesc}</p>
          <Button
            className="float-right float-bottom"
            disabled={l.isOrdered}
            onClick={() => {
              if (auth.isLoggedIn) {
                if (user?.activeProducts.length === 0) {
                  if (user?.isPostpaid) {
                    props.setMessage({
                      type: TOAST_WARNING,
                      text: "Дараа төлбөрт хэрэглэгч тул уг үйлчилгээг авах боломжгүй",
                    });
                  } else
                    props.setMessage({
                      type: TOAST_WARNING,
                      text: "Уг үйлчилгээг идэвхижүүлэх боломжгүй байна. Та үндсэн багцаа идэвхижүүлнэ үү.",
                    });
                } else {
                  props.selectProduct({
                    orderType: CHARGE_LIVE,
                    product: {
                      productName: l.eventName,
                      smsCode: l.smsCode,
                      liveChannel: l.liveChannel,
                    },
                    price: l.isPromo ? l.promoPrice : l.eventPrice,
                  });
                  navigate("/order");
                }
              } else setIsOpen(true);
            }}
          >
            {l.isOrdered ? "Захиалсан" : "Захиалах"}
          </Button>
        </Col>
      </Row>
    );
  };
  return (
    <>
      <Banner type="live" />
      <Container
        className="section-content"
        style={{ marginTop: "-58px", position: "relative", zIndex: 19 }}
      >
        <Row xs={1} md={3} className="g-4 section-content">
          <img
            src="https://my.ddishtv.mn:5001/nvod/LIVE.png"
            alt="ddish live"
            width="25%"
          />
        </Row>
        {live?.liveList ? (
          live?.liveList?.map((l, index) => {
            return liveBox(l, index);
          })
        ) : (
          <Alert variant="info">
            Түрээслэх боломжтой хөтөлбөрийн мэдээлэл ороогүй байна.
          </Alert>
        )}
        <PleaseLogin isOpen={isOpen} onHide={onClose} />
      </Container>
    </>
  );
}

const mapActionToProps = (dispatch) => {
  return {
    getInfo: () => dispatch(userActions.getInfo()),
    getLive: () => dispatch(liveActions.getLive()),
    checkType: () => dispatch(userActions.checkType()),
    selectProduct: (product) => dispatch(actions.selectProduct(product)),
    setMessage: (payload) => dispatch(messageActions.setMessage(payload)),
  };
};

export default connect(null, mapActionToProps)(Live);
