import {  post } from "../helper/requests";
import {
  CHARGE_ACCOUNT,
  CHARGE_LIVE,
  CHARGE_NVOD,
  CHARGE_OTHER_ACCOUNT,
  CHARGE_PRODUCT,
  MERCHANT_PAYMENT,
  ORDER_GET_ORDER_ID,
  UPGRADE_PRODUCT,
  ORDER_CLEAR,
  CHARGE_OTHER_ACCOUNT_NOLOGIN,
  ORDER_LOAD,
  CHARGE_OTHER_PRODUCT,
  ORDER_START,
  QPAY_CLEAR,
  CARD,
  QPAY,
  QPAY_CREATE_ORDER,
  SOCIAL_PAY,
  CHARGE_OTHER_PRODUCT_NOLOGIN,
} from "./types";
export const merchantOrder = (bank, selectedProduct, saveCard = true) => {
  var paymentType;
  switch (bank) {
    case CARD: {
      paymentType = 303;
      break;
    }
    case QPAY: {
      paymentType = 300;
      break;
    }
    case SOCIAL_PAY: {
      paymentType = 306;
      break;
    }
    default: {
      break;
    }
  }
  var body = {
    requestType: selectedProduct.orderType.code,
    paymentType,
    saveCard,
  };
  switch (selectedProduct.orderType.code) {
    case CHARGE_PRODUCT.code:
      body = {
        ...body,
        cProduct: {
          productId: selectedProduct.product.productId,
          month: selectedProduct.product.selected.month,
          amount: selectedProduct.price,
          bankName: bank,
          promoId: selectedProduct.product.selected.promoId,
        },
      };
      break;
    case CHARGE_NVOD.code:
      body = {
        ...body,
        cNvod: {
          productId: selectedProduct.product.productId,
          smsCode: selectedProduct.product.smsCode,
          inDate: selectedProduct.product.inDate,
          amount: selectedProduct.price,
          bankName: bank,
        },
      };
      break;
    case CHARGE_ACCOUNT.code:
      body = {
        ...body,
        cAccount: { amount: selectedProduct.price, bankName: bank },
      };
      break;
    case CHARGE_OTHER_ACCOUNT.code:
      body = {
        ...body,
        cOAccount: {
          amount: selectedProduct.price,
          bankName: bank,
          cardNo: selectedProduct.product.cardNo,
        },
      };
      break;
    case UPGRADE_PRODUCT.code:
      body = {
        ...body,
        uProduct: {
          toProductId: selectedProduct.product.productId,
          amount: selectedProduct.price,
          bankName: bank,
        },
      };
      break;
    case CHARGE_LIVE.code:
      body = {
        ...body,
        cLive: {
          liveChannel: selectedProduct.product.liveChannel,
          smsCode: selectedProduct.product.smsCode,
          bankName: bank,
          amount: selectedProduct.price,
        },
      };
      break;
    case CHARGE_OTHER_ACCOUNT_NOLOGIN.code: {
      body = {
        ...body,
        requestType: "1017",
        cOAccountNoLogin: {
          amount: selectedProduct.price,
          bankName: bank,
          cardNo: selectedProduct.product.cardNo,
          isVat: selectedProduct.isVat,
          email: selectedProduct.email,
          deviceImei: selectedProduct.deviceImei,
        },
      };
      break;
    }
    case CHARGE_OTHER_PRODUCT_NOLOGIN.code:{
      body= {
        ...body,
        cProductNoLogin:{
          productId: selectedProduct.product.productId,
          month: selectedProduct.product.selected.month,
          amount: selectedProduct.price,
          cardNo: selectedProduct.cardNo,
          bankName: bank,
          promoId: selectedProduct.product.selected.promoId,
          channel:4,
          isVat:selectedProduct.isVat,
          email:selectedProduct.email
        }
      }
    };
      break;
    default:
      break;
  }
  var order = {
    order: { ...body, price: selectedProduct.price },
    method: MERCHANT_PAYMENT,
    bankName: bank,
    product: selectedProduct,
  };
  return function (dispatch) {
    dispatch({
      type: ORDER_START,
      payload: {
        order: order,
      },
    });
    post(
      "bankPayment/createbill",
      body,
      (res) => {
        // console.log('res.data', res.data)
        dispatch({
          type: ORDER_GET_ORDER_ID,
          payload: {
            orderId: res.data.transactionId,
            qpay: res.data.qpay,
            savedCards: res.data.savedCards,
            paymentUrl: res.data.card?.paymentUrl,
          },
        });
        storeOrder(
          order,
          body,
          bank,
          selectedProduct,
          res.data,
          res.data.transactionId
        );
        switch (bank) {
          case SOCIAL_PAY: {
            window.location.replace(res.data.social.paymentUrl);
            break;
          }
          case QPAY: {
            dispatch({
              type: QPAY_CREATE_ORDER,
              payload: {
                qrImageUrl: res.data.qpay.qrImage,
                qpayUrl: res.data.qpay.qpayUrl,
                invoiceNo: res.data.transactionId,
              },
            });
          }
          default:
            break;
        }
      },
      (err) => {}
    );
  };
};

export const storeOrder = (
  order,
  body,
  bankName,
  selectedProduct,
  bankResult,
  orderId
) => {
  localStorage.removeItem("order");
  localStorage.setItem(
    "order",
    JSON.stringify({
      order,
      body,
      method: MERCHANT_PAYMENT,
      bankName: bankName,
      product: selectedProduct.product,
      bankResult: bankResult,
      orderId: orderId,
    })
  );
};

export const loadOrder = () => {
  return function (dispatch) {
    let order = JSON.parse(localStorage.getItem("order"));
    if (order !== null) {
      dispatch({
        type: ORDER_LOAD,
        payload: order,
      });
      dispatch(removeFromStorage());
    }
  };
};

export const qpayClear = () => {
  return function (dispatch) {
    dispatch({
      type: QPAY_CLEAR,
    });
  };
};
export const removeFromStorage = () => {
  return function (dispatch) {
    localStorage.removeItem("order");
  };
};
export const orderClear = () => {
  return function (dispatch) {
    dispatch({
      type: ORDER_CLEAR,
    });
  };
};
