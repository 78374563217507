import { info } from "./moduleInfo";

export default function ChannelList() {
  return (
    <>
      <div>
        <div className="ch-container">
          <div className="ch-header"></div>
          <div className="ch-hash"></div>
          <div className="ch-cell"></div>
          <div className="ch-cell2"></div>
          <div className="ch-cell3"></div>
          <div className="ch-absolute">
            <div className="ch-header-absolute">Сувгуудын нэр</div>
            <div className="ch-hash-absolute">
              HD/
              <br />
              SD
            </div>
            <div className="ch-cell-absolute">
              Standard <br />
              багц
            </div>
            <div className="ch-cell2-absolute">
              Happy
              <br /> багц
            </div>
            <div className="ch-cell3-absolute">
              Super
              <br /> багц
            </div>
          </div>
        </div>
        {info.map((item, index) => (
          <div key={index} className="ch-channel-header">
            <div className="ch-container2">
              <div className="ch-header2-top"></div>
              <div className="ch-hash2"></div>
              <div className="ch-cell1"></div>
              <div className="ch-cell22"></div>
              <div className="ch-cell32"></div>
            </div>
            <div className="ch-absolute2">{item.header}</div>
            <div className="relative">
              {item.nullable === true && item.unique === 1 && (
                <div className="absolute-chat">
                  Оршин суугаа аймгийн бүртгэлийн дагуу орон нутгийн сувгийг
                  идэвхжүүлнэ
                </div>
              )}
              {item.nullable === true && item.unique === 2 && (
                <div className="absolute-chat2">
                  Контент тус бүрээр захиална
                </div>
              )}
              {item.nullable === true && item.unique === 2 && (
                <div className="absolute-chat3">5900₮/30хоног</div>
              )}
            </div>
            {item.channels.map((el, ind) => (
              <div className="ch-container2">
                <div className="ch-header2">
                  {el.name}
                  <div className="front-bottom"></div>
                </div>
                <div className="ch-hash2">{el.status}</div>
                <div
                  className={`ch-cell1 ${
                    item.nullable == true ? "" : "border-bottom"
                  }`}
                >
                  {el.property[0] === 1 && item.nullable !== true ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="white"
                      class="bi bi-check2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`ch-cell22 ${
                    item.nullable == true ? "" : "border-bottom"
                  }`}
                >
                  {el.property[1] === 1 && item.nullable !== true ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="white"
                      class="bi bi-check2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                  ) : (
                    ""
                  )}
                </div>
                <div className="ch-cell32">
                  {item.nullable == true ? (
                    ""
                  ) : (
                    <div className="back-bottom"></div>
                  )}
                  {el.property[2] === 1 && item.nullable !== true ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="white"
                      class="bi bi-check2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}
