import axios from "axios";

import { useState, useEffect } from "react";
import { webApiUrl, mainUrl } from "../../helper/globals";
import { useParams } from "react-router";
import { Row, Col, Alert } from "react-bootstrap";
import Breadcrumbs from "../Layout/Breadcrumb";
import { Twitter, Facebook } from "react-social-sharing";
import dateFormat from "dateformat";
import MetaTags from "react-meta-tags";
var h2p = require("html2plaintext");

export default function Promo() {
  const [post, setPost] = useState({
    post_content: "",
    post_title: "",
    post_id: 0,
    post_type: "",
    created_at: "",
    is_active: 1,
    ffrom: "",
    tto: "",
    type: "",
    picture: "",
    backimage: "/u/promo/1-1.jpg",
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const time = new Date();
  const end = new Date(dateFormat(post.end_date));
  const { id } = useParams();
  useEffect(() => {
    if (!isLoaded) {
      axios
        .get(webApiUrl + "promo/" + id)
        .then((result) => {
          const post = result.data.result[0];
          setPost(post);
          setIsLoaded(true);
        })
        .catch((err) => console.log(err));
    }
  });
  console.log("time",time);
  console.log("end",end);
  return (
    <>
      <MetaTags>
        <meta
          property="og:description"
          content={h2p(post.post_content).substring(0, 50) + "..."}
        />
        <meta
          property="og:url"
          content={["https://ddishtv.mn/promo" + post.post_id]}
        />

        <meta property="og:title" content={[post.post_title]} />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content={["https://ddishtv.mn:3000/" + post.picture]}
        />
      </MetaTags>
      <Breadcrumbs
        title="Урамшуулал"
        content={
          <Row className="section-content">
            <h5>{[post.post_title]}</h5>
            <Row>
              <h8>
                <i>
                  {end < time ? (
                    <Alert variant="danger">
                      Энэхүү урамшуулал нь{" "}
                      {dateFormat(post.end_date, "yyyy-mm-dd")} өдөр дууссан
                      болно
                    </Alert>
                  ) : (
                    <Alert variant="success">
                      Урамшуулал дуусах:{" "}
                      {dateFormat(post.end_date, "yyyy-mm-dd")}
                    </Alert>
                  )}
                </i>
              </h8>
            </Row>
            <Row>
              <Col
                md={{ span: 3, offset: 9 }}
                sm={{ span: 5, offset: 7 }}
                float-end
              >
                <Twitter link={mainUrl + `press/${id}`} label="Tweet" />
                <Facebook link={mainUrl + `press/${id}`} label="Share" />
              </Col>
            </Row>
            <div
              dangerouslySetInnerHTML={{ __html: post.post_content }}
              style={{ overflowY: "hidden", overflowX: "auto" }}
            />
            <Row>
              <Col
                md={{ span: 3, offset: 9 }}
                sm={{ span: 4, offset: 8 }}
                float-end
              >
                <Twitter link={mainUrl + `press/${id}`} label="Tweet" />
                <Facebook link={mainUrl + `press/${id}`} label="Share" />
              </Col>
            </Row>
          </Row>
        }
        route={[{ url: "/promo", text: "Урамшуулал" }]}
      />
    </>
  );
}
