import {
  ListGroup,
  Row,
  Tab,
  Container,
  Carousel,
  Col,
  Card,
} from "react-bootstrap";
import coverage from "../assets/img/hamrah_huree.jpg";
import Slider from "react-slick";
import Breadcrumbs from "./Layout/Breadcrumb";
import { useState } from "react";
import h08 from "../assets/img/history/2008.jpg";
import h09 from "../assets/img/history/2009.jpg";
import h10 from "../assets/img/history/2010.jpg";
import h11 from "../assets/img/history/2011.jpg";
import h1114 from "../assets/img/history/2011-2014.jpg";
import h13 from "../assets/img/history/2013.jpg";
import h15 from "../assets/img/history/2015.jpg";
import h16 from "../assets/img/history/2016.jpg";
import h17 from "../assets/img/history/2017.jpg";
import h18 from "../assets/img/history/2018.jpg";
import h19 from "../assets/img/history/2019.jpg";
import h20 from "../assets/img/history/2020.jpg";
import h21 from "../assets/img/history/2021.jpg";
import i1 from "../assets/img/goals/1.jpg";
import i2 from "../assets/img/goals/2.jpg";
import i3 from "../assets/img/goals/3.jpg";
import i4 from "../assets/img/goals/4.jpg";
import f1 from "../assets/img/goals/1-1.jpg";
import f2 from "../assets/img/goals/2-1.jpg";
import f3 from "../assets/img/goals/3-1.jpg";
import f4 from "../assets/img/goals/4-1.jpg";
import fair from "../assets/img/values/fair.jpg";
import accountability from "../assets/img/values/accountability.jpg";
import creativity from "../assets/img/values/creativity.jpg";
import leadership from "../assets/img/values/leadership.jpg";
import professionalism from "../assets/img/values/professionalism.jpg";
import sustainability from "../assets/img/values/sustainability.jpg";
import nv1 from "../assets/img/values/new-vision-1.png";
import nv2 from "../assets/img/values/new-vision-2.png";
import nv3 from "../assets/img/values/new-vision-3.png";
import nv4 from "../assets/img/values/new-vision-4.jpg";
import nv5 from "../assets/img/values/new-vision-5.png";
import m1 from "../assets/img/management/1.png";
import m2 from "../assets/img/management/2.png";
import m3 from "../assets/img/management/3.png";
import m4 from "../assets/img/management/4.png";
import m5 from "../assets/img/management/5.png";
import m6 from "../assets/img/management/6.png";
import mCEO from "../assets/new_ceo/Badamaa.png";
import CEO from "../assets/new_ceo/Bold_zahiral.png";
import SCEO from "../assets/new_ceo/Nymaa-zurag.jpg";
export default function About() {
  const [title, setTitle] = useState("БИДНИЙ ТУХАЙ");
  return (
    <Breadcrumbs
      title={title}
      content={
        <Container className="section-content" fluid>
          <Tab.Container defaultActiveKey="#aboutus">
            <Row className="md-center">
              <ListGroup className="list-group-horizontal-md">
                <ListGroup.Item
                  action
                  href="#aboutus"
                  className="tabs-title tabs"
                  onClick={() => {
                    setTitle("БИДНИЙ ТУХАЙ");
                  }}
                >
                  БИДНИЙ ТУХАЙ
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href="#goal"
                  className="tabs-title tabs"
                  onClick={() => {
                    setTitle("ЭРХЭМ ЗОРИЛГО, ҮНЭТ ЗҮЙЛС");
                  }}
                >
                  ЭРХЭМ ЗОРИЛГО, ҮНЭТ ЗҮЙЛС
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href="#management"
                  className="tabs-title tabs"
                  onClick={() => {
                    setTitle("УДИРДЛАГЫН БАГ");
                  }}
                >
                  УДИРДЛАГЫН БАГ
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href="#stakeholders"
                  className="tabs-title tabs"
                  onClick={() => {
                    setTitle("ХУВЬ НИЙЛҮҮЛЭГЧИД");
                  }}
                >
                  ХУВЬ НИЙЛҮҮЛЭГЧИД
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href="#history"
                  className="tabs-title tabs"
                  onClick={() => {
                    setTitle("БИДНИЙ ТҮҮХ");
                  }}
                >
                  БИДНИЙ ТҮҮХ
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href="#ethics"
                  className="tabs-title tabs"
                  onClick={() => {
                    setTitle("ЁС ЗҮЙН ДҮРЭМ");
                  }}
                >
                  ЁС ЗҮЙН ДҮРЭМ
                </ListGroup.Item>
              </ListGroup>
            </Row>

            <Tab.Content>
              <Tab.Pane eventKey="#aboutus">
                <Row style={{ paddingTop: "20px" }}>
                  <div className="small-12 columns text-justify">
                    <p style={{ textIndent: "50px" }}>
                      ДДэш ТВ ХХК нь DTH /Direct to home/ буюу сансрын хиймэл дагуулаар телевизийн олон сувгийг Монгол орон даяар дахин дамжуулдаг төдийгүй кино, контент үйлдвэрлэлийн салбарт томоохон байр суурийг эзлэгч үндэсний компани юм. ДДэш 2008 онд үйл ажиллагаагаа явуулж эхэлснээр монголчууд дэд бүтцийн хөгжлөөс хараат бусаар Монгол орныхоо өнцөг булан бүрээс телевиз, радиогийн сувгуудыг хүлээн авах боломжтой болсон нь Монголын харилцаа холбоо, мэдээллийн технологийн салбарт гарсан томоохон дэвшил байв.
                    </p>
                    <Carousel>
                      <Carousel.Item>
                        <img alt="img" src={f4} />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img alt="img" src={f1} />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img alt="img" src={f2} />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img alt="img" src={f3} />
                      </Carousel.Item>
                    </Carousel>
                    <p style={{ textIndent: "50px", marginTop: "1.5rem" }}>
                      2017 онд анх удаа Монгол улс руу чиглэсэн цацраг бүхий “KOREA SAT 5A” хиймэл дагуулыг хөөргөснөөр илүү олон суваг, кино контентыг өндөр чанартайгаар хэрэглэгчдэдээ хүргэдэг болсон юм. Өнөөдөр ДДэш нь өргөн нэвтрүүлэг, арилжаа, орон нутгийн телевизийн сувгууд, гадаадын шилдэг төрөлжсөн сувгуудыг дахин дамжуулахаас гадна хиймэл дагуулын технологийн онцлогт тохирсон кино, контент түрээсэлж үзэх, хадгалж, нөхөж үзэх боломжтой VOD, интернэттэй хэрэглэгчдэд давуу боломж олгох NOTT зэрэг үйлчилгээнүүдийг нэвтрүүлээд байна. 
                    </p>
                    <img src={coverage} alt="areas served" />
                  </div>
                </Row>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="#goal">
                <Row>
                  <div className="small-12 columns">
                    {/* <Carousel>
                      <Carousel.Item>
                        <img alt="img" src={f4} />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img alt="img" src={f1} />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img alt="img" src={f2} />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img alt="img" src={f3} />
                      </Carousel.Item>
                    </Carousel> */}
                    <p style={{ marginTop: "20px" }}>
                      <h4>Алсын хараа:</h4>
                      Өв уламжлалаа тээсэн шинэ үеийн нүүдэлчдийн соёлыг дэлхийд таниулж, ертөнцийг тольдох гүүр нь байна. 
                    </p>
                    <hr />
                    <p>
                      <h4>Эрхэм зорилго:</h4>
                      Бид хамтдаа бүтээлч сэтгэлгээ, шинэлэг технологиор дамжуулан шилдэг бүтээгдэхүүн үйлчилгээг уудам орныхоо хоймор бүрд хүргэж, орчлонг чимээлүүлж, оюуныг гэрэлтүүлнэ.
                    </p>
                    <hr />
                    <p>
                      <h4>Уриа үг:</h4>
                      ОЮУНЫГ ГЭРЭЛТҮҮЛНЭ
                    </p>
                    <hr />
                    <Row>
                      <h4>Үнэт зүйлс:</h4>
                    </Row>

                    <Row>
                      <Col>
                        <img src={nv1} alt="professionalism" />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignContent: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "justify",
                        }}
                      >
                        <h4>Хэрэглэгч төв</h4>
                        Бид хэрэглэгч нэг бүрд хүрч ажилладаг. Хэрэглэгчийн брэндтэй харилцаж байгаа туршлагыг үргэлж сайжруулж, тэдний оролцоог нэмэгдүүлж, сэтгэл ханамжийг өсгөснөөр үнэнч байдлыг бий болгодог. 
                      </Col>
                    </Row>
                    <hr />

                    <Row style={{ marginTop: "10px" }}>
                      <Col
                        style={{
                          display: "flex",
                          alignContent: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "justify",
                        }}
                      >
                        <h4>Бүтээлч сэтгэлгээ</h4>
                        Бид аливаа зүйлд үргэлж бүтээлч сэтгэлгээгээр ханддаг. Бид хийж байгаа ажил, бүтээж байгаа контент бүрээ үргэлж дараагийн түвшинд гаргахын төлөө ажилладаг. 
                      </Col>
                      <Col>
                        <img src={nv2} alt="teamwork" />
                      </Col>
                    </Row>
                    <hr />

                    <Row style={{ marginTop: "10px" }}>
                      <Col>
                        <img src={nv3} alt="leadership" />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignContent: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "justify",
                        }}
                      >
                        <h4>Өв уламжлал, түүх</h4>
                        Бид Монголын шинэ үеийн нүүдэлчдийн амьдралын хэв маягийг тодорхойлогч, чиглүүлэгч нь билээ. Тиймээс бид үеэс үед дамжин хадгалагдаж ирсэн соёлын өвийг түгээн дэлгэрүүлж, сурталчлах соёлын гүүр нь байдаг. 
                      </Col>
                    </Row>
                    <hr />

                    <Row style={{ marginTop: "10px" }}>
                      <Col
                        style={{
                          display: "flex",
                          alignContent: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "justify",
                        }}
                      >
                        <h4>Тогтвортой хөгжил</h4>
                        Бид тогтвортой хөгжилд манлайлагч. Байгаль орчинд ээлтэй үйл ажиллагаа явуулахыг үргэлж зорьдог. Хэрэглэгч, ажилчдадаа тогтвортой хөгжлийн үзэл баримтлалыг сурталчилж, хэрэгжүүлэх ажлуудыг тасралтгүй хийдэг. 
                      </Col>
                      <Col>
                        <img src={nv4} alt="accountability" />
                      </Col>
                    </Row>
                    <hr />

                    <Row style={{ marginTop: "10px" }}>
                      <Col>
                        <img src={nv5} alt="creativity" />
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignContent: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "justify",
                        }}
                      >
                        <h4>Өөдрөг үзэл</h4>
                        Бид аливаа зүйлд үргэлж өөдрөг ханддаг. Бүх зүйлийн гэрэлтэй талыг олж хардаг, ярьдаг, уриалдаг, хэрэгжүүлдэг. 
                      </Col>
                    </Row>
                    <hr />

                    <Row style={{ marginTop: "20px" }}>
                      <h4>Баримтлах зарчим:</h4>
                      <ol>
                        <li>
                          Өөрийгөө болон бусдыг хөгжүүлэх нь ажилтан бүрийн
                          эрхэм үүрэг байна.
                        </li>
                        <li>
                          Бид ажилтан бүрд хөгжиж, ахиж дэвших орчныг бүрдүүлж,
                          ижил тэгш бололцоо олгоно. Ажилтны өсөж хөгжих, дэвжиж
                          дээшлэх нь тухайн хүний ур чадвар, гүйцэтгэл, оруулж
                          буй хувь нэмэртэй шууд холбоотой байна.
                        </li>
                        <li>
                          Бид сайн ажилчин, ажилтан, менежер төдийгүй сайн
                          манлайлагч байхыг эрмэлзэж, бусдадаа үлгэр дуурайлал
                          үзүүлнэ.
                        </li>
                        <li>
                          Бид шинийг сэдэж, аливааг сайжруулах боломжийг цаг
                          ямагт эрэлхийлнэ. Бид шинэ санаа, бүтээлч хандлагыг
                          хөхиүлэн дэмжинэ.
                        </li>
                        <li>
                          Бид хамгийн шилдэг шийдэл бүхий бүтээгдэхүүн,
                          үйлчилгээг хэрэглэгчдэд хүргэнэ.
                        </li>
                        <li>
                          Өндөр чадавх, олон ургалч санаа бодол бүхий гишүүдээс
                          бүрдсэн, харилцан итгэлцэл, хүндлэлд суурилсан хамтын
                          зүтгэл нь бидний тогтвортой амжилтын үндэс юм.
                        </li>
                        <li>
                          Бид зах зээлийн шударга зарчимд тулгуурлан харилцан
                          биенээ дэмжиж, нөхөж ажилласнаар улам их үр дүн гаргах
                          боломж бололцоогоо нэмэгдүүлнэ.
                        </li>
                        <li>
                          Бид ажилдаа эзний сэтгэлээр хандаж, компанийн нэр
                          хүнд, өмч хөрөнгийг хайрлан хамгаална.
                        </li>
                        <li>
                          Бид зөв зүйлийг зөв арга замаар хийж, Монгол Улсын
                          хууль тогтоомж, М-Си-Эс Группийн Ёс зүйн дүрмийг өдөр
                          тутам мөрдөж ажиллана.
                        </li>
                        <li>
                          Бид ажилтан, түнш, хэрэглэгчтэйгээ харилцахдаа үнэнч
                          шударга зарчмыг баримтална.
                        </li>
                        <li>
                          Бид нийгэмдээ болон байгаль орчиндоо ээлтэй байхыг
                          эрхэмлэнэ.
                        </li>
                        <li>
                          Бид хөдөлмөрийн аюулгүй байдлыг эн тэргүүнд хангаж
                          ажиллана.
                        </li>
                      </ol>
                    </Row>
                  </div>
                </Row>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="#stakeholders">
                <Row style={{ paddingTop: "20px" }}>
                  <div className="small-12 columns">
                    <p>
                      <ol>
                        <li> Монгол улсын иргэн Ц.Цэрэнпунцаг /51%/</li>
                        <li>
                          Монгол улсын хуулийн этгээд “Орбитнэт” ХХК /49%/
                        </li>
                      </ol>
                    </p>
                  </div>
                </Row>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="#history">
                <Row style={{ paddingTop: "20px" }}>
                  <div className="package">
                    <Slider
                      autoplay="false"
                      dots
                      autoplay-speed="15000"
                      infinite="true"
                      className="ajil history"
                    >
                      <div>
                        <div className="medium-5 columns">
                          <img alt="img" src={h08} />
                        </div>
                        <div className="medium-7 columns">
                          <div className="timeline">
                            <div className="timeline-content">
                              <div className="year">2008</div>
                              <ul className="check">
                                <li>
                                  Хиймэл дагуулын телевизийн DVB-S стандартаар
                                  4.5 метрийн голчтой антенаар телевизийн 18
                                  сувгийг монгол орон даяар дамжуулж эхлэв.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="medium-5 columns ">
                          <img alt="img" src={h09} />
                        </div>
                        <div className="medium-7 columns">
                          <div className="timeline">
                            <div className="timeline-content">
                              <div className="year">2009</div>
                              <ul className="check">
                                <li>
                                  9.3 метрийн голчтой антенны системийг
                                  ашиглалтанд оруулав.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="medium-5 columns">
                          <img alt="img" src={h10} />
                        </div>
                        <div className="medium-7 columns">
                          <div className="timeline">
                            <div className="timeline-content">
                              <div className="year">2010</div>
                              <ul className="check">
                                <li>
                                  MҮОНРТ-ийн харилцан холболтын төвийг өргөтгөж,
                                  Радиогийн 3 сувгийг дамжуулж эхлэв.
                                </li>
                                <li>
                                  “Хөрс” төвд хэрэглэгчдэд үйлчлэх үйлчилгээний
                                  салбараа нээв
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="medium-5 columns">
                          <img alt="img" src={h11} />
                        </div>
                        <div className="medium-7 columns">
                          <div className="timeline">
                            <div className="timeline-content">
                              <div className="year">2011</div>
                              <ul className="check">
                                <li>
                                  Хиймэл дагуулын телевизийн шинэ стандарт болох
                                  DVB-S2 стандартын тоон телевизийн системээр
                                  өргөтгөл хийж телевизийн 60+ суваг радиогийн 5
                                  сувгийг нэвтрүүлж эхлэв.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="medium-5 columns">
                          <img alt="img" src={h1114} />
                        </div>
                        <div className="medium-7 columns">
                          <div className="timeline">
                            <div className="timeline-content">
                              <div className="year">2011 - 2014</div>
                              <ul className="check">
                                <li>
                                  Засгийн газраас зарласан тоон телевизийн
                                  төслийн хүрээнд 220 сум сууринд тоон
                                  телевизийн дамжуулах станц суурилуулав.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="medium-5 columns">
                          <img alt="img" src={h13} />
                        </div>
                        <div className="medium-7 columns">
                          <div className="timeline">
                            <div className="timeline-content">
                              <div className="year">2013</div>
                              <ul className="check">
                                <li>
                                  11 метрийн голчтой антены системийг ашиглалтад
                                  оруулав.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="medium-5 columns">
                          <img alt="img" src={h15} />
                        </div>
                        <div className="medium-7 columns">
                          <div className="timeline">
                            <div className="timeline-content">
                              <div className="year">2015</div>
                              <ul className="check">
                                <li>
                                  Тоон телевизийн 90 суваг, радиогийн 5 сувгийг
                                  дамжуулдаг болов
                                </li>
                                <li>
                                  Хэрэглэгчдэдээ илүү ойртож 4 дэх салбараа
                                  нээв.
                                </li>
                                <li>
                                  Цоо шинэ “Кино театрын суваг”-ийн үйлчилгээг
                                  нэвтрүүлж Шинэ кино, Адал явдалт кино, Гэр
                                  бүлийн кино, Хүүхдийн кино сувгуудыг нээв
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="medium-5 columns">
                          <img alt="img" src={h16} />
                        </div>
                        <div className="medium-7 columns">
                          <div className="timeline">
                            <div className="timeline-content">
                              <div className="year">2016</div>
                              <ul className="check">
                                <li>
                                  Үндсэн багцын сонголтыг нэмэгдүүлж ЭНГИЙН,
                                  ИЛҮҮ, ҮЛЭМЖ багцуудтай болов.
                                </li>
                                <li>
                                  Шууд дамжуулалтын DDISHLIVE сувагтай болов
                                </li>
                                <li>
                                  Гар утасны дугаараа бүртгүүлээд
                                  үйлчилгээнүүдийг илүү хялбараар хүссэн үедээ
                                  захиалах боломжтой болов.
                                </li>
                                <li>
                                  Монгол орон даяарх Юнителийн бүх үйлчилгээний
                                  салбарууд ДДЭШ-ийн үйлчилгээг үзүүлж эхлэв
                                </li>
                                <li>
                                  Юнителийн нэгжийн картаар дансаа цэнэглэж
                                  болдог болов
                                </li>
                                <li>
                                  Кино театрын “Монгол кино” сувагтай болов
                                </li>
                                <li>DDISH аппликейшнтэй болов</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="medium-5 columns">
                          <img alt="img" src={h17} />
                        </div>
                        <div className="medium-7 columns">
                          <div className="timeline">
                            <div className="timeline-content">
                              <div className="year">2017</div>
                              <ul className="check">
                                <li>
                                  Шинэ хиймэл дагуул руу шилжих бэлтгэл ажлуудыг
                                  амжилттай хийж KoreaSat компанитай гэрээ
                                  байгуулав
                                </li>
                                <li>
                                  Шинэ хиймэл дагуул руу амжилттай шилжиж Илүү
                                  өндөр дохио чанар, Илүү олон HD сувгууд, Илүү
                                  сонголтот кино сувгуудтай болов
                                </li>
                                <li>
                                  Орон нутгийн ТВ сувгуудыг дахин дамжуулж орон
                                  нутгийн хэрэглэгчид аймгийнхаа телевизийн
                                  сувгуудыг аль ч сум суурингаасаа хүлээн авч
                                  үзэх боломжтой болов
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="medium-5 columns">
                          <img alt="img" src={h18} />
                        </div>
                        <div className="medium-7 columns">
                          <div className="timeline">
                            <div className="timeline-content">
                              <div className="year">2018</div>
                              <ul className="check">
                                <li>
                                  Шинэ хиймэл дагуул руу бүх хэрэглэгчдээ
                                  амжилттай шилжүүлэв
                                </li>
                                <li>
                                  Ази цуврал, Холливуд цуврал, Монгол цуврал
                                  киноны сувгуудтай болов
                                </li>
                                <li>Аяллын автомат антеныг борлуулж эхлэв</li>
                                <li>
                                  Юнителийн нэгжээр кино захиалж үзэх боломжтой
                                  болов
                                </li>
                                <li>
                                  Шинэ хүлээн авагч төхөөрөмжийг нэвтрүүлж
                                  хүссэн үедээ хүссэн кино, контентоо захиалж
                                  үзэх боломжтой КИНО САН-н үйлчилгээтэй болов
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="medium-5 columns">
                          <img alt="img" src={h19} />
                        </div>
                        <div className="medium-7 columns">
                          <div className="timeline">
                            <div className="timeline-content">
                              <div className="year">2019</div>
                              <ul className="check">
                                <li>
                                  Конка/998,881/ хүлээн авагч КИНО САН
                                  үйлчилгээг авах боломжтой болов
                                </li>
                                <li>
                                  Кино сувгуудыг багцалсан 10000 багцтай болов
                                </li>
                                <li>
                                  Гэр бүлийн гишүүдийн дугаараа админ дугаар
                                  дээрээ нэмж бүртгүүлээд Олуулаа дансаа удирдах
                                  боломжтой болов
                                </li>
                                <li>
                                  Үндсэн багцынхаа үлдэгдэл хоногтоо багцаа
                                  ахиулах боломжтой болов
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="medium-5 columns">
                          <img alt="img" src={h20} />
                        </div>
                        <div className="medium-7 columns">
                          <div className="timeline">
                            <div className="timeline-content">
                              <div className="year">2020</div>
                              <ul className="check">
                                <li>
                                  Нэгдсэн лояалти хөтөлбөр UPoint-д нэгдэв
                                </li>
                                <li>
                                  Юнителийн Гэр интернэт үйлчилгээтэй хамтарч
                                  телевиз, интернэт хосолсон “ГЭР ДҮҮРЭН”
                                  үйлчилгээг нэвтрүүлэв
                                </li>
                                <li>
                                  Нийгмийн хариуцлагын хүрээнд хөл хорионы
                                  өдрүүдэд “Хүүхдийн кино” сувгийг бүх
                                  хэрэглэгчдэдээ үнэгүй гаргав
                                </li>
                                <li>
                                  Юнивишн, Моод төслийн багтай хамтарч “Музей
                                  үзэх моод” видео контентыг бүтээж, түгээх
                                  ажлыг санаачлан хэрэгжүүлэв
                                </li>
                                <li>
                                  Аяллын төгс шийдэл плат антеныг борлуулж эхлэв
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="medium-5 columns">
                          <img alt="img" src={h21} />
                        </div>
                        <div className="medium-7 columns">
                          <div className="timeline">
                            <div className="timeline-content">
                              <div className="year">2021</div>
                              <ul className="check">
                                <li>
                                  Үндсэн багцуудаа шинэчилж багцад хамаарах
                                  сувгийн тоог нэмэв
                                </li>
                                <li>
                                  Нэмэлт сувгийн 5к, 10к багцуудтай болж
                                  хэрэглэгч нэг төлөөд сарын турш хүссэн суваг,
                                  контентоо үзэх боломжтой болов
                                </li>
                                <li>
                                  Хөл хорионы үеүдэд үйлчилгээний салбар болон
                                  систем хяналтын алба нэгжүүд халдвар
                                  хамгааллын дэглэм, дүрэм журмыг сайтар
                                  баримталж хэрэглэгчдэдээ үйлчилгээгээ
                                  тасралтгүй хүргэж ажиллав
                                </li>
                                <li>
                                  Аниме, Орос кино болон сошиал орчин дахь
                                  контентуудыг хүргэх DDISHSOCIAL сувгуудтай
                                  болов
                                </li>
                                <li>
                                  Кино сангийн үйлчилгээ шинэчлэгдэж КИНО
                                  БАГЦ-уудтай болов
                                </li>
                                <li>
                                  Нийгмийн хариуцлагын хүрээнд Ирээдүйгээ
                                  гэрэлтүүлье уриан дор “Зүрх мартахгүй”, “Ид
                                  шидийн орон” төслүүдэд хөрөнгө оруулав
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </Row>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="#ethics">
                <Row style={{ paddingTop: "20px" }}>
                  <div className="small-12 columns">
                    <h4>ЁС ЗҮЙН ДҮРЭМ</h4>
                    <p style={{ textIndent: "50px" }}>
                      ДДЭШ компанийн нийт ажилтнууд компанийн эрхэм зорилго,
                      зорилтдоо хүрэхийн төлөө ажиллахдаа толгой компани М-Си-Эс
                      группийн үнэт зүйлс, баримтлах зарчмуудаас урган гарсан
                      энэхүү ЁС ЗҮЙН ДҮРЭМ-ийг өдөр тутамд мөрдлөг болгодог.
                    </p>
                    <p>
                      <a
                        onClick={() => {
                          window.open(
                            "https://ddishtv.mn:3000/u/%D0%81%D1%81-%D0%B7%D2%AF%D0%B9%D0%BD-%D0%B4%D2%AF%D1%80%D1%8D%D0%BC.pdf",
                            "_blank",
                            "strWindowFeatures"
                          );
                        }}
                        href
                        className="button"
                      >
                        ДЭЛГЭРЭНГҮЙ
                      </a>
                    </p>
                  </div>
                </Row>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="#management">
                <Row style={{ paddingTop: "20px" }}>
                  <div className="small-12 columns">
                    <Row xs={1} md={3}>
                      <Col>
                        <Card style={{ border: "none" }}>
                          <Card.Img src={CEO} />
                          <Card.Body>
                            <b>Г.Болд </b>
                            <br />
                            Гүйцэтгэх захирал
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card style={{ border: "none" }}>
                          <Card.Img src={mCEO} />
                          <Card.Body>
                            <b>Э.Бадамгарав </b>
                            <br />
                            Бизнес хөгжил, төлөвлөлтийн албаны захирал
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card style={{ border: "none" }}>
                          <Card.Img src={m3} />
                          <Card.Body>
                            <b>Н.Ариунболд</b>
                            <br />
                            Техник, технологийн албаны захирал
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card style={{ border: "none" }}>
                          <Card.Img src={m4} />
                          <Card.Body>
                            <b>Г.Батмөнх</b>
                            <br />
                            Борлуулалт үйлчилгээний албаны захирал
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card style={{ border: "none" }}>
                          <Card.Img src={SCEO} style={{}} />
                          <Card.Body>
                            <b>Г.Нямаа</b>
                            <br /> Ерөнхий нягтлан бодогч
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card style={{ border: "none" }}>
                          <Card.Img src={m6} />
                          <Card.Body>
                            <b>Ц. Азжаргал</b>
                            <br />
                            Захиргаа, Хүний нөөцийн албаны захирал
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
      }
      route={[{ url: "/about", text: "Бидний тухай" }]}
    />
  );
}
