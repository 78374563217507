import authReducer from "./auth";
import { combineReducers } from "redux";
import userReducer from "./user";
import productReducer from "./product";
import messageReducer from "./message";
import { merchantReducer } from "./merchant";
import orderReducer from "./order";
import searchReducer from "./search";
import { nvodReducer } from "./nvod";
import { liveReducer } from "./live";
import { rvodReducer } from "./rvod";
import routeReducer from "./route";

const reducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  message: messageReducer,
  product: productReducer,
  merchant: merchantReducer,
  nvod: nvodReducer,
  live: liveReducer,
  order: orderReducer,
  rvod: rvodReducer,
  search: searchReducer,
  route: routeReducer
});
export default reducer;
