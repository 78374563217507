import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Form, Row, Accordion } from "react-bootstrap";
import { webApiUrl, webUrl } from "../../helper/globals";
import s from "../../assets/u/newlogo/logo_S.jpg";
import sActive from "../../assets/img/icons/new_s_icon_active.svg";
import sInactive from "../../assets/img/icons/new_s_icon_inactive.svg";
import m from "../../assets/u/newlogo/logo_M.jpg";
import mActive from "../../assets/img/icons/new_m_icon_active.svg";
import mInactive from "../../assets/img/icons/new_m_icon_inactive.svg";
import l from "../../assets/u/newlogo/logo_L.jpg";
import lActive from "../../assets/img/icons/new_l_icon_active.svg";
import lInactive from "../../assets/img/icons/new_l_icon_inactive.svg";
import xl from "../../assets/u/newlogo/logo_XL.jpg";
import xlActive from "../../assets/img/icons/new_xl_icon_active.svg";
import xlInactive from "../../assets/img/icons/new_xl_icon_inactive.svg";
export default function Rural() {
  const [activeMap, setActiveMap] = useState("all");
  const [channels] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const categories = [
    {
      value: "all",
      name: "Бүх суваг",
    },
    {
      value: "umnugovi",
      name: "Өмнөговь",
    },
    {
      value: "erdenet",
      name: "Эрдэнэт",
    },
    {
      value: "zavkhan",
      name: "Завхан",
    },
    {
      value: "uvs",
      name: "Увс",
    },
    {
      value: "tuv",
      name: "Төв",
    },
    {
      value: "bayankhongor",
      name: "Баянхонгор",
    },
    {
      value: "khuvsgul",
      name: "Хөвсгөл",
    },
    {
      value: "dornogovi",
      name: "Дорноговь",
    },
    {
      value: "darkhan-uul",
      name: "Дархан-Уул",
    },
    {
      value: "dornod",
      name: "Дорнод",
    },
    {
      value: "uvurkhangai",
      name: "Өвөрхангай",
    },
    {
      value: "dundgovi",
      name: "Дундговь",
    },
    {
      value: "selenge",
      name: "Сэлэнгэ",
    },
    {
      value: "arkhangai",
      name: "Архангай",
    },
    {
      value: "bayan-ulgii",
      name: "Баян-Өлгий",
    },
    {
      value: "khentii",
      name: "Хэнтий",
    },
    {
      value: "bulgan",
      name: "Булган",
    },
  ];

  const [umnugovi, setUmnugovi] = useState([]);
  const [erdenet, setErdenet] = useState([]);
  const [zavkhan, setZavkhan] = useState([]);
  const [uvs, setUvs] = useState([]);
  const [tuv, setTuv] = useState([]);
  const [bayankhongor, setBayankhongor] = useState([]);
  const [khuvsgul, setKhuvsgul] = useState([]);
  const [dornogovi, setDornogovi] = useState([]);
  const [darkhan, setDarkhan] = useState([]);
  const [dornod, setDornod] = useState([]);
  const [uvurkhangai, setUvurkhangai] = useState([]);
  const [dundgovi, setDundgovi] = useState([]);
  const [selenge, setSelenge] = useState([]);
  const [arkhangai, setArkhangai] = useState([]);
  const [ulgii, setUlgii] = useState([]);
  const [khentii, setKhentii] = useState([]);
  const [bulgan, setBulgan] = useState([]);
  useEffect(() => {
    if (!isLoaded) {
      axios
        .get(webApiUrl + `rural/umnugovi`)
        .then((res) => {
          setUmnugovi({ channelText: "ӨМНӨГОВЬ", channels: res.data.result });
        })
        .catch(setUmnugovi([]));
      axios
        .get(webApiUrl + `rural/erdenet`)
        .then((res) => {
          setErdenet({ channelText: "ЭРДЭНЭТ", channels: res.data.result });
        })
        .catch(setErdenet([]));
      axios
        .get(webApiUrl + `rural/zavkhan`)
        .then((res) => {
          setZavkhan({ channelText: "ЗАВХАН", channels: res.data.result });
        })
        .catch(setZavkhan([]));
      axios
        .get(webApiUrl + `rural/uvs`)
        .then((res) => {
          setUvs({ channelText: "УВС", channels: res.data.result });
        })
        .catch(setUvs([]));
      axios
        .get(webApiUrl + `rural/tuv`)
        .then((res) => {
          setTuv({ channelText: "ЗАВХАН", channels: res.data.result });
        })
        .catch(setTuv([]));
      axios
        .get(webApiUrl + `rural/bayankhongor`)
        .then((res) => {
          setBayankhongor({
            channelText: "БАЯНХОНГОР",
            channels: res.data.result,
          });
        })
        .catch(setBayankhongor([]));
      axios
        .get(webApiUrl + `rural/khuvsgul`)
        .then((res) => {
          setKhuvsgul({ channelText: "ХӨВСГӨЛ", channels: res.data.result });
        })
        .catch(setKhuvsgul([]));
      axios
        .get(webApiUrl + `rural/dornogovi`)
        .then((res) => {
          setDornogovi({ channelText: "ДОРНОГОВЬ", channels: res.data.result });
        })
        .catch(setDornogovi([]));
      axios
        .get(webApiUrl + `rural/darkhan-uul`)
        .then((res) => {
          setDarkhan({ channelText: "ДАРХАН-УУЛ", channels: res.data.result });
        })
        .catch(setDarkhan([]));
      axios
        .get(webApiUrl + `rural/dornod`)
        .then((res) => {
          setDornod({ channelText: "ДОРНОД", channels: res.data.result });
        })
        .catch(setDornod([]));
      axios
        .get(webApiUrl + `rural/uvurkhangai`)
        .then((res) => {
          setUvurkhangai({
            channelText: "ӨВӨРХАНГАЙ",
            channels: res.data.result,
          });
        })
        .catch(setUvurkhangai([]));
      axios
        .get(webApiUrl + `rural/dundgovi`)
        .then((res) => {
          setDundgovi({ channelText: "ДУНДГОВЬ", channels: res.data.result });
        })
        .catch(setDundgovi([]));
      axios
        .get(webApiUrl + `rural/selenge`)
        .then((res) => {
          setSelenge({ channelText: "СЭЛЭНГЭ", channels: res.data.result });
        })
        .catch(setSelenge([]));
      axios
        .get(webApiUrl + `rural/arkhangai`)
        .then((res) => {
          setArkhangai({ channelText: "АРХАНГАЙ", channels: res.data.result });
        })
        .catch(setArkhangai([]));
      axios
        .get(webApiUrl + `rural/bayan-ulgii`)
        .then((res) => {
          setUlgii({ channelText: "БАЯН-ӨЛГИЙ", channels: res.data.result });
        })
        .catch(setUlgii([]));
      axios
        .get(webApiUrl + `rural/khentii`)
        .then((res) => {
          setKhentii({ channelText: "ХЭНТИЙ", channels: res.data.result });
        })
        .catch(setKhentii([]));
      axios
        .get(webApiUrl + `rural/bulgan`)
        .then((res) => {
          setBulgan({ channelText: "БУЛГАН", channels: res.data.result });
        })
        .catch(setBulgan([]));
      setIsLoaded(true);
    }
  }, [isLoaded, channels]);

  useEffect(() => {}, [activeMap]);

  function channel(channels) {
    return (
      <>
        <ul className="medium-up-2 large-up-2 block m-channel">
          {channels?.channels?.length > 0 ? (
            channels.channels?.map((channel) => {
              return (
                <li className="column">
                  {
                    <div
                      className={
                        (channel.format === "HD" ? "hd" : "sd") + " item "
                      }
                    >
                      <img
                        src={webUrl + channel.logo}
                        alt={channel.name}
                        width="60"
                        height="42"
                      />

                      <h6>
                        <span class="dd-product-name">{channel.number}</span>
                      </h6>
                      <span class="dd-product-package-group">
                        <span class="dd-product-package">
                          <img
                            src={
                              channel.package.indexOf("S") > -1
                                ? "/Standard_icon.jpg"
                                : "/silver.jfif"
                            }
                            alt="S"
                            style={{
                              width: "18px",
                              height: "18px",
                              borderRadius: "3px",
                            }}
                          />
                        </span>
                        <span class="dd-product-package">
                          <img
                            src={
                              channel.package.indexOf("M") > -1
                                ? "/Happy_icon.jpg"
                                : "/silver.jfif"
                            }
                            alt="M"
                            style={{
                              width: "18px",
                              height: "18px",
                              borderRadius: "3px",
                            }}
                          />
                        </span>
                        <span class="dd-product-package">
                          <img
                            src={
                              channel.package.indexOf(`L,`) > -1
                                ? "/Super_icon.jpg"
                                : "/silver.jfif"
                            }
                            style={{
                              width: "18px",
                              height: "18px",
                              borderRadius: "3px",
                            }}
                            alt="L"
                          />
                        </span>
                      </span>
                    </div>
                  }
                </li>
              );
            })
          ) : (
            <span>Илэрц хоосон байна. </span>
          )}
        </ul>
      </>
    );
  }
  var map = (
    <svg
      version="1.1"
      id="svg1469"
      inkscapeOutput_extension="org.inkscape.output.svg.inkscape"
      sodipodiVersion="0.32"
      inkscapeVersion="0.46"
      xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlnsDc="http://purl.org/dc/elements/1.1/"
      xmlnsInkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlnsCc="http://creativecommons.org/ns#"
      xmlnsSodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlnsSvg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="360px"
      viewBox="16.268 14.257 2297.92 1216.714"
      enable-background="new 16.268 14.257 2297.92 1216.714"
      xmlSpace="preserve"
    >
      <g>
        <defs>
          <rect
            id="SVGID_1_"
            x="16.268"
            y="14.257"
            width="2297.92"
            height="1216.714"
          />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" overflow="visible" />
        </clipPath>
        <path
          onClick={() => setActiveMap("khuvsgul")}
          className={
            activeMap === "khuvsgul" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="khuvsgul"
          stroke="#ffffff"
          stroke-width="3"
          d="M799.861,461.098
           l1.375,1.169l5.656,0.609l4.25,2.388l7.75,2.999l1.438,1.805h5.656l4.25-3h4.938l4.25-2.388l4.938-3.609l0.719-3.61l0.688-5.414
           v-3.584l3.531-4.218l7.094-1.195l3.531,2.999l6.375,6.609l2.813,3.609l3.531,2.388l3.531-2.999v-5.387l2.844-4.804l5.656-1.806
           l8.469-0.61l0.719-4.804l-0.719-3.61l-2.125-3.583l-2.094-4.831l-0.719-4.193l5.656-7.193l3.531-6.026l2.844-6.608l3.531-3.583
           l4.25-0.609l4.938-2.416l-1.406-4.194l-4.25-1.194l-4.25-3l-2.125-2.415l7.781-9.608l6.375-2.389l4.938-3.609h4.969l7.063,5.415
           l5.656,2.999l7.063,0.584l9.188,6.024l0.719,4.194l3.531,4.193l4.25,4.22l6.375,2.388l4.938-1.804l4.938-3l4.25-2.999l2.844-1.804
           l-1.438-3l-2.813-2.999l-0.719-3l2.125-9.023l8.5-8.387l4.938-1.804l5.656-7.803l2.844-4.804l5.656-1.221l6.344-4.193h9.188
           l7.781-1.195l3.531-5.414l2.125-10.218l-0.688-5.998l-1.438-2.999l0.719-3l4.25,1.804l5.656,2.389l3.531,2.415l3.531,2.39
           l4.25-0.584l1.406-5.414l-1.406-4.194l-4.969-5.415l-7.063-5.998l-4.25-3.61l-8.469-3.609l-7.781-0.61l-7.063-5.388l-2.844-4.805
           v-7.219l7.063-11.413l8.5-5.998l1.406-4.805l1.406-7.802l2.125-4.193l7.094-3.026l6.344-3.583l4.969-8.414l0.125-0.159
           l-1.563-1.036l-2.813-3.025l-2.125-3l-2.813-4.193l-2.844-1.805l-3.531-2.999l-2.125-5.999v-7.829v-8.388l-1.406-6.024
           l-2.125-4.804l-0.719-9.609l-1.406-5.387v-4.805l-4.25-7.219v-4.803l2.844-7.803l0.688-9.608v-5.999l-2.813-2.415l-3.531-7.803
           l-7.781-3.611l-6.375-1.193l-6.344-2.999h-5.688l-3.531-3h-4.219h-4.969l-4.25,3h-4.219l-0.719-3.609l-2.813-1.806l-2.844-3
           l-4.938-0.609h-4.25l-5.656-3l-2.844-2.389l-4.938-4.221l-4.938-4.193l-4.969-4.193l-4.25-2.416l-5.656-2.389l-4.938-3.609h-4.938
           l-7.781,0.585l-6.375,0.609l-5.656-1.194l-5.656-2.416l-4.25-1.194l-6.344-1.195l-4.969-5.413l-2.813-1.806l-4.25-4.804
           l-4.938-2.999l-4.969-2.999l-4.938-1.806h-7.781l-4.25-2.388l-6.375-5.415l-2.094-2.999l-4.25-3l-3.531-2.999l-2.125-3.025
           l-1.406-1.195l-0.719-1.805l-3.531-1.804l-2.844,0.611l-2.125,1.804l-2.813,3l-0.719,3v4.804l-1.406,3.609l-3.531,1.804
           l-4.969,4.804v4.804v2.999l-3.531,1.806l-3.531,2.388l-7.063,1.805l-1.406,2.999l-2.844,5.415l-2.813,4.804L775.08,71.9l-4.25,0.61
           l-1.406,2.39v4.221l2.094,2.388v2.999l-1.406,4.222l-4.938,3.608h-4.969l-5.656,1.195l-4.938,2.389l-4.25,3.025l-0.688,2.999
           l-0.719,4.804v5.999v5.414v4.804l-1.406,7.804l-1.438,5.998l-4.938,3.61v4.193l0.719,3.61h4.938l2.125,2.415l2.813,3.584v2.999
           v1.805l-0.688,3.61l-1.406,4.804l-0.719,5.414l-0.719,1.804v4.804l0.719,3.584l2.813,2.415l0.719,3.609l3.531,2.389l5.656,3.609
           l2.125,1.806l4.969,0.584l0.688,2.415l2.844,2.999l0.688,5.999v4.219v3.611l-3.531,5.387l-2.813,3.609l-2.125,4.193l-2.125,6.025
           l-3.531,5.388l-1.406,4.22l-4.25,7.192l-4.969,3.61l-4.219,0.61l-5.656,6.582v2.416v3.609l-2.125,0.584l-1.438-1.194l-2.094-2.389
           l-3.563-0.61h-4.219l-6.375,1.194l-2.125,3.61l-4.938,3.609l-3.531,4.193l-7.094,4.804l-7.781,1.194h-5.656l-4.938-3.582
           l-0.469-1.407l-1.656,9.793v6.025l-4.25,4.194l-7.781,8.413l-6.344,8.999l-0.719,8.414v4.193l4.969,6.024l4.219,6.608l6.375,6.609
           l7.063,2.388l9.188,0.61l7.094,1.195l8.469,2.389l7.781,6.025l4.25,7.192l4.25,7.219l7.063,3h6.375l7.063-1.805h5.656l3.531,0.61
           l7.063,1.805h5.656l2.844,2.389l2.813,2.999h4.969l4.219-5.388l7.781-4.804l6.375-1.806l6.375-1.221l4.938-2.388l2.125-0.611
           l2.813,2.999l2.125,1.805v2.999l-3.531,1.221v3.583l-1.406,3.025l-2.125,2.999l0.719,1.195l3.531,5.997v6.025l2.125,4.803
           l-2.125,4.195l-1.406,4.193l-2.125,3.609v3.61l-1.438,3.61l-2.813,6.609v6.583l3.531,3.025l1.406,5.387l-0.688,7.803l-0.719,4.222
           L799.861,461.098z"
        />
        <path
          onClick={() => setActiveMap("bayan-ulgii")}
          className={
            activeMap === "bayan-ulgii" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="bayan-ulgii"
          stroke="#ffffff"
          stroke-width="3"
          d="M266.83,348.245
            l-0.188-7.298l-2.094-3.583l-1.438-6.025v-4.804v-4.804l-2.125-2.388l-1.406-1.804l-5.656-0.61l-2.125-1.806l-4.219-5.388
           l-1.438-5.998l-2.125-8.414l-2.094-4.221l-1.438-2.998v-5.388l-3.531-7.22l-1.406-5.414l-3.531-4.804l-1.438-4.803l-0.688-3.583
           l-4.25-3.026l-2.125-2.388l-2.813-3l-6.375-5.998l-0.719-3.026l-5.656-3.583l-0.688-4.22l-0.719-4.804v-4.804l-1.406-5.387v-7.22
           l0.719-7.219v-5.388l-2.844-2.999l-0.719-2.416v-3.609l-3.531-4.194l-0.875-0.583l-6.906,1.193l-5.656,3.584l-8.469,1.22
           l-5.656,3.583l-4.25,5.414v10.219v4.193l-3.531,6.608l-3.531,4.194l-2.844-0.584l-2.125-1.193l-4.219-1.806l-1.438,1.194
           l-4.938,2.999l-4.938,2.416l-2.125,3.583h-7.094l-6.344,0.611l-4.25,1.193l-2.844,2.416v2.999v3l-2.094,1.804l-4.25-3l-5.656-4.803
           l-3.531-3.609l-7.781-4.195l-4.25-0.61h-8.5l-8.469,1.194l-8.5,2.999l-3.531,5.414l-4.938,4.805l-2.125,5.414v5.388l-2.125,1.804
           H45.33l-7.063,2.416l-3.531,4.193v5.414v3.583l-2.125,4.22l-3.531,2.389l-7.375,1.406l-2.719,3.849l-2.719,3.849v2.309l3.625,2.282
           v2.309l2.719,4.618l2.719,1.54l5.406,0.769l2.719,1.54l3.625,3.849l0.906,1.539v3.052l-2.719,2.308l-2.719,2.31l-0.906,3.079
           l-0.906,1.539v3.08l0.906,3.822l4.531,3.848l2.719,3.849l3.625,3.079l2.719,3.052l3.625,1.54l4.5,1.539l0.906,2.309l3.625,1.54
           l1.813,1.539l1.813,1.539l2.719,2.309l1.813,3.079l1.813,7.67v4.618l-0.906,5.362v1.54v1.539l1.813,1.539l3.594,3.079l0.906,2.309
           l1.813,1.539v2.31l0.906,3.052l1.813,1.54l0.906,0.769l1.813,1.539h2.719l3.625,2.311l0.906,2.308l2.688,3.079l2.719,3.849
           l0.906,2.282l3.625,1.539h3.625l1.813,1.539l5.406-2.309l1.813-4.591l3.625-3.08l0.906,0.77l3.625,0.77l0.906,2.309l5.438,2.283
           l0.906,1.539l2.688,3.849l4.531,3.078l2.719,5.389l2.719,4.591l3.625,3.079l2.719,3.848v1.54l3.594,0.771l1.813-2.31l1.813-1.539
           l2.719-3.849h1.813l3.625,0.77l0.906,3.849v4.617c0,0,0.262,1.396,0.969,5.601c0.707,4.203,0.688,3.609,0.688,3.609l5.656,4.804
           l4.969,0.609l2.813,2.389l2.125,1.806l3.531,8.414v6.608l0.719,8.413l2.125,6.582v3.611l-0.719,6.607l-2.125,7.804v4.22
           l2.844,4.805l1.406,5.998l2.813,5.413l5.656,7.804l4.25,3l4.25,4.803l4.25,4.805l1.406,5.414l1.406,10.801l3.531,5.415l-0.25,0.637
           l3.813,4.168l8.469,8.387l4.938,4.22l3.563,3l4.219-0.609l5.656-0.588l8.5-0.608h5.656l4.25,0.608l5.656,1.197l3.531,0.609
           l2.125-1.807v-2.411l-2.125-4.194v-1.807l-3.531-4.803l-3.531-4.218l-4.969-6.584l-5.656-4.806l-5.656-6.607l-2.125-4.221
           l-1.406-3.607l-2.813-3.585l-0.719-1.804l1.406-4.221l2.844-5.387l2.125-3.611v-5.998l-0.719-4.803l-2.125-3.61l-2.813-4.806
           l-3.563-3.608l-3.531-2.998v-2.39l2.125-5.413l4.25-4.804l2.125-4.804l-0.719-3l-2.125-3.609l-4.219-6.609l-0.719-2.999
           l-2.844-5.415l-2.125-4.804l-1.406-14.411l0.719-7.193v-4.22l-2.125-2.389l-2.844-4.804l-2.813-6.025l-2.844-4.803l-1.406-7.193
           l-0.688-8.414v-4.804l2.094-4.193l3.563-6.025l0.688-3.583l1.406-2.415l1.438-4.194l-0.719-1.219l-0.719-1.779l-3.531-4.804
           l-1.406-2.415l-2.125-1.194l0.719-1.194l3.531-0.611l2.813-2.999l7.781-0.611c0,0,12.746,0,16.281,0
           C252.291,349.36,265.037,348.382,266.83,348.245z"
        />
        <path
          onClick={() => setActiveMap("selenge")}
          className={
            activeMap === "selenge" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="selenge"
          stroke="#ffffff"
          stroke-width="3"
          d="M1237.517,219.654
           l-4.25,1.194l-2.125,3.61v4.192l-4.938,1.806c0,0-2.141,1.193-4.969,1.193s-6.344,0-6.344,0l-4.25,1.805l-3.531,5.414l-1.406,1.806
           l-2.844,1.194l-4.25-1.804l-2.813-1.805l-5.656,0.61l-7.063,3l-6.375,2.999l-3.938,3.822c0.24,0.575,0.746,1.723,1.469,3.689
           c0.885,2.402,1.75,5.089,1.75,6.289c0,1.202,0.533,2.728,1.063,3.929c0.531,1.201,1.063,2.096,1.063,2.096v7.804v15.022
           l4.25,11.996l0.719,4.22l4.938,2.388l0.719,2.416l4.219,5.387l1.438,2.416l-2.125,4.194l-3.531,3.609v10.802l-2.844,10.802
           c0,0-2.824,6.012-3.531,8.414c-0.354,1.201-0.338,3.745-0.25,5.998c0.088,2.253,0.25,4.22,0.25,4.22v9.61l0.719,8.997l-1.438,2.999
           l-5.656,4.22l-4.219,3.609v4.194l4.219,4.192l2.125,3.025l1.406,4.804h1.438l2.125,1.194v2.389l-2.844,2.416l-3.531,6.608v3.583
           l0.719,1.805l2.094,2.416l2.844,1.193l2.813-3.609l4.25-2.388l2.125-1.195l3.531,0.584l4.25,1.804l3.531,3l4.25,2.415l4.25,1.805
           l6.344,0.583l4.969-2.388l5.656-1.804h9.906l4.219-4.221v-13.801l1.438-5.998l4.219-3.609l5.656-1.805l6.375-2.389l9.188-4.22
           h3.531l7.781,1.195l2.125,4.22l2.844,2.389l-0.719,3.025l-3.531,8.388v5.414l4.25,2.389l4.938-0.584l3.531-2.999l2.844-4.22
           l2.125,1.221l4.219,4.193l7.094,3l6.344,5.414l3.531,4.805l7.094,1.194h6.344l4.25-3.609l3.531-6.609l1.406-3.583l4.25-1.221h4.969
           l7.75,2.415c0,0,15.578,1.805,18.406,1.805c1.414,0,3.879-0.62,6-1.221c2.121-0.6,3.906-1.194,3.906-1.194l7.063-3.61l4.938-5.388
           l2.125-5.999l1.406-2.414v-5.415l-2.125-3l-3.531-4.803l-1.406-3l-3.531-2.999l-4.25-4.804l0.719-4.803l3.531-3.611l4.938-4.194
           h5.656h7.094l5.656,1.806l5.656,5.387h9.188l3.531-2.999h7.063l9.906-7.193l3.531-6.609l1.406-4.804l2.844-6.025
           c0,0,1.049-1.197,2.375-2.999c0.664-0.9,1.398-1.952,2.094-3.079c0.115-0.186,0.201-0.394,0.313-0.583l-3.375-1.142l-3.531-3.609
           l-2.125-4.193l-0.688-3l-2.125-4.193l-4.969-3.025l-5.656-1.778l-5.656-3.026l-4.25-0.583l-2.813-4.22l-1.406-3.584
           c0,0-2.824-2.4-3.531-4.803c-0.707-2.402-1.438-8.414-1.438-8.414l0.719-5.998v-4.804l2.125-2.416v-3.61l-4.25-3.582l-2.813-3.611
           h-3.563l-5.656-0.61l-4.938,0.61l-4.25,2.39l-4.938,1.221h-4.25l-2.813,3.582l-2.844,1.805l-2.125-2.388l-2.125-3l-1.406-4.831
           l-5.656-2.389h-3.531l-3.531-3.609l-3.531-4.193l-2.125-1.805l-4.969-4.804l-2.813-4.804l-2.844-3l-5.656-6.024l-4.938-2.999
           l-6.375-1.804h-4.938h-5.656l-4.25,3.025l-4.25,1.778l-5.656,0.611l-5.656-1.195l-4.938-4.804l-6.375-1.804l-5.656-2.417
           l-7.781,0.61l-6.344-1.804l-4.25-1.194l-7.781-1.194l-7.063-1.221h-4.25l-4.938-0.584l-4.25-2.999H1237.517z M1294.08,266.499
           h1.406l0.719,2.999l4.25,3.611l0.688,7.803l1.438,8.386l0.688,5.415v7.803l4.969,3.609l9.188,2.999h7.781l6.344,2.416
           c0,0,3.543,4.207,4.25,6.609c0.707,2.401,3.531,13.19,3.531,13.19v12.023v7.192l-3.531,5.415l-3.531,5.415l-6.375,2.998
           l-7.063,1.805h-17.688l-9.188,0.584l-3.531,1.805h-5.656l-7.063-1.805l-2.125-2.389l-4.25-3.609l-2.813-6.608l-2.125-5.999
           l0.688-5.415l2.844-7.803l4.25-6.609l0.688-4.193l0.719-4.22l0.719-10.192v-3.61l2.094-7.219v-3.583l4.25-3l4.25-4.803
           c0,0,4.25-1.819,4.25-4.221c0-2.403,1.406-7.803,1.406-7.803v-5.999l2.813-2.415L1294.08,266.499z"
        />
        <path
          onClick={() => setActiveMap("tuv")}
          className={
            activeMap === "tuv" || activeMap === "all" ? "active" : "inactive"
          }
          id="tuv"
          stroke="#ffffff"
          stroke-width="3"
          d="M1464.33,339.089
           c-0.111,0.189-0.197,0.397-0.313,0.583c-0.695,1.127-1.43,2.178-2.094,3.079c-1.326,1.802-2.375,2.999-2.375,2.999l-2.844,6.025
           l-1.406,4.804l-3.531,6.609l-9.906,7.193h-7.063l-3.531,2.999h-9.188l-5.656-5.387l-5.656-1.806h-7.094h-5.656l-4.938,4.194
           l-3.531,3.611l-0.719,4.803l4.25,4.804l3.531,2.999l1.406,3l3.531,4.803l2.125,3v5.415l-1.406,2.414l-2.125,5.999l-4.938,5.388
           l-7.063,3.61c0,0-1.785,0.594-3.906,1.194c-2.121,0.601-4.586,1.221-6,1.221c-2.828,0-18.406-1.805-18.406-1.805l-7.75-2.415
           h-4.969l-4.25,1.221l-1.406,3.583l-3.531,6.609l-4.25,3.609h-6.344l-7.094-1.194l-3.531-4.805l-6.344-5.414l-7.094-3l-4.219-4.193
           l-2.125-1.221l-2.844,4.22l-3.531,2.999l-4.938,0.584l-4.25-2.389v-5.414l3.531-8.388l0.719-3.025l-2.844-2.389l-2.125-4.22
           l-7.781-1.195h-3.531l-9.188,4.22l-6.375,2.389l-5.656,1.805l-4.219,3.609l-1.438,5.998v13.801l-4.219,4.221h-9.906l-5.656,1.804
           l-4.969,2.388l-6.344-0.583l-4.25-1.805l-4.25-2.415l-3.531-3l-4.25-1.804l-3.531-0.584l-2.125,1.195l-4.25,2.388l-2.813,3.609
           v3.61v4.193v4.805l-0.719,6.024l-4.219,7.803l-4.25,4.803l-4.25,4.193l-2.125,5.999l1.406,6.609l2.844,4.218l1.406,4.194
           l2.125,3.611l8.5,1.804l4.938,2.388l4.938,5.413l2.125,7.805l2.844,5.998l-0.719,9.607l-3.531,5.414l-6.375,4.194l-5.656,0.611
           l-2.813,3.61l-2.844,2.998h-2.125l-2.094-2.414l-1.438-2.391h-3.531l-2.125,2.391l-7.781,7.828l-3.531,6.607l1.438,4.806
           l2.094,2.998v1.805l-0.688,3l-2.125,2.387l-2.844,2.416l1.438,5.389l0.688,4.803l0.594,3.53l1.438,2.019l2.5,3.82l3,3.824l3,3.395
           l2,2.125l3,3.396l0.5,3.397v3.398l-3,4.671l-3.5,7.644l-1.5,2.973l-1,2.549l0.5,3.822l2.5,5.945l2.5,3.819l1.5,2.127l2.5,1.696
           l2,3.398v4.671l2,5.096v2.548l2.5,4.67l5,3.399l4,2.545l4,3.399l4.5,2.973l0.75,0.558l2.563-6.104l3.531-7.22l5.656-4.194
           l4.25-5.999l2.844-3.023l2.094,2.415l4.25,1.805l3.531,0.584l7.094,1.221l5.656,2.998l2.125,1.806l3.531,1.804l2.813,2.391
           l2.125-1.806l2.844-1.194l2.813-4.194l1.406-6.024l4.25-4.193l2.125-4.804l3.531-4.221l4.25-3.583l5.656-0.61l7.063,0.61
           l3.531,1.804l3.531,2.391l4.969,3.609l3.531,2.388l5.656,5.416l0.719,1.805l2.125,1.805l2.813-1.196l2.125-2.413l2.125-1.805
           l3.531-2.391l3.531-1.803l4.25-0.612l4.25,0.612l2.094,2.389l4.969-0.586l13.438,1.195l2.125,2.39l4.219,2.415l2.125,3l0.719,2.998
           l4.25,4.804h3.531l1.406,1.806l7.063,1.804l9.219-1.194l8.469-0.609l4.25-3.61l4.25-3.584l4.938-7.218l2.844-2.388l7.063-1.222
           h4.25l1.406-2.389v-2.417l1.406-1.804l2.469-0.611l-1.063-2.388l1.438-5.999l4.219-4.804l10.625-1.193l6.344,1.193l8.5-0.61
           l7.063,2.999l4.25,1.223h5.656l4.25-3.001l4.25-1.221l2.094-2.999l8.5-8.411l1.406-2.393l1.406-3.608h2.844l1.406,2.999
           l1.406,2.416l-0.688,4.805l0.688,2.997l2.125,1.808l3.531-1.195l0.719-1.222l2.125-2.388l2.813-3l2.844-1.805v-2.416l-2.125-1.195
           l-2.125-3.608l-5.656-2.388l-7.781-6.608l-4.938-6.61l-4.25-5.997l-0.688-4.219l-1.438-6.61l-3.531-4.194l-2.813-3.61l-2.844-6.608
           l-3.531-3.581l-4.25-3.026l-4.938-3.584v-6.607l2.813-6.607l2.125-9.025l0.719-5.387v-7.806l0.688-6.606l4.25-7.22l4.25-4.805
           l7.063-0.583l5.656-2.415l6.375-5.415l4.25-5.389l-2.844-4.803l-2.125-5.415v-2.389l0.719-8.413l4.25-3.611v-6.607l-2.125-12.607
           l-3.531-7.803l-3.531-7.219l-0.719-9.607l-2.844-11.413l-3.531-12.607l-5.656-8.997l-4.25-5.414l-4.219-2.388l-4.25-2.416
           l-1.406-6.609l-0.719-5.415l0.719-7.803l1.188-13.057L1464.33,339.089z M1313.892,454.462l2.813,0.611l6.375,3h4.25l7.063,0.582
           l7.063,2.417h4.25l5.656-2.999l4.25,2.999l2.813-1.806l3.531,4.194l2.125,1.222l2.844,1.194l4.25,5.414l5.656,7.804l2.813,1.194
           l2.125-1.194h2.813l2.125,0.584l2.125,0.609l2.844,0.611l2.125,2.388v2.417v2.998l-3.563,5.389l0.719,6.025l0.719,4.193
           l-3.531,2.414l0.688,4.193h-16.25l-11.313-10.218h-6.375l-7.063-1.194l-4.969,4.193l-12.719-1.194l-5.656-3.608l-2.813,4.803
           l-5.656-7.192l2.813-7.22l-5.656-9.607l-2.813-10.194l2.094-6.607l-4.219-7.803L1313.892,454.462z M1460.955,497.114l6.375,2.389
           v13.219l-1.406,7.19l-2.938,6.849l-2.719,5.785l-7.781-2.416l-4.25-14.41l4.25-10.803L1460.955,497.114z M1383.173,527.131
           l6.375,1.194l4.938,7.219l-11.313,4.192l-3.531-8.412L1383.173,527.131z"
        />
        <path
          onClick={() => setActiveMap("dundgovi")}
          className={
            activeMap === "dundgovi" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="dundgovi"
          stroke="#ffffff"
          stroke-width="3"
          d="M1423.83,641.814
            l-2.469,0.611l-1.406,1.804v2.417l-1.406,2.389h-4.25l-7.063,1.222l-2.844,2.388l-4.938,7.218l-4.25,3.584l-4.25,3.61l-8.469,0.609
           l-9.219,1.194l-7.063-1.804l-1.406-1.806h-3.531l-4.25-4.804l-0.719-2.998l-2.125-3l-4.219-2.415l-2.125-2.39l-13.438-1.195
           l-4.969,0.586l-2.094-2.389l-4.25-0.612l-4.25,0.612l-3.531,1.803l-3.531,2.391l-2.125,1.805l-2.125,2.413l-2.813,1.196
           l-2.125-1.805l-0.719-1.805l-5.656-5.416l-3.531-2.388l-4.969-3.609l-3.531-2.391l-3.531-1.804l-7.063-0.61l-5.656,0.61
           l-4.25,3.583l-3.531,4.221l-2.125,4.804l-4.25,4.193l-1.406,6.024l-2.813,4.194l-2.844,1.194l-2.125,1.806l-2.813-2.391
           l-3.531-1.804l-2.125-1.806l-5.656-2.998l-7.094-1.221l-3.531-0.584l-4.25-1.805l-2.094-2.415l-2.844,3.023l-4.25,5.999
           l-5.656,4.194l-3.531,7.22l-3.531,8.413v4.193l-0.719,3.607l-2.094,2.391l-3.563,3.609l-2.125,4.193v3.61l-2.094,1.805
           l-6.375,0.609l-7.063,1.777c0,0-0.184,0.471-0.625,1.223c-0.441,0.75-1.158,1.798-2.219,3c-1.061,1.2-2.277,3.009-3.25,4.511
           c-0.973,1.503-1.688,2.683-1.688,2.683l-4.25,9.607v7.829l-0.719,5.998l-1.406,4.804l-2.125,3.608l-3.531,1.806h-7.781
           l-9.906,1.193l-4.938,3.61l-2.813,4.803l-2.844,5.39l-3.531,5.413l-1.406,4.194v4.804l2.125,5.413l6.344,5.416l5.656,5.386l1.406,3
           v1.807l-2.094,3.606v4.806l0.688,4.804l3.531,2.415l5.656,2.39h8.5l4.938,1.806l2.125,4.805l-1.406,7.801v5.998l2.125,4.804v6.026
           l0.688,3.583l2.844,3.023l4.25,3.001l4.938,2.39l10.594,5.413l3.563,1.193l7.781,1.805l8.469,1.196l16.25,1.22l17,0.582h9.188
           l6.344,0.612l4.969,1.805l1.406,2.998l2.813,3.61l2.844,3.584l2.125,4.805l5.656,3.608l2.813,7.218l1.438,3.583v6.027l2.125,4.193
           l5.656,4.802l2.813,1.806l7.063,4.804l8.5,2.415h4.938l6.375-0.609l7.063-5.999l9.906-6.024l10.594-6.61l7.781-3.581l10.625-4.806
           l9.188-4.803l8.469-3.001l7.781-3.606l8.5-1.808l2.125-1.805h2.125h4.219l2.125,3l7.781,0.612l4.969-0.612l4.219-3.608l5.656-2.178
           v-0.822l2.125-3.001l0.719-5.996v-5.997l1.406-12.023l3.531-5.413l3.531-2.392h7.781l12.75-1.195l11.313-1.803l4.219-2.415
           l2.844-1.195v-3.608l-0.719-4.805l2.125-8.997l0.719-2.999l7.063-2.415l1.406-1.805v-2.388l-1.406-3.612v-1.804l-2.125-1.805
           l-2.125-10.801l-0.688-8.414l-1.438-18.022l0.719-19.799v-15.023v-5.997l-1.813-1.141l-3.125,1.141l-7.094-1.221l-6.344-3.582
           l-7.094-6.027l-0.688-7.19l-0.719-7.22l3.531-4.193l2.844-4.22v-5.388l0.688-10.803v-7.831l-2.813-2.996l-4.25-1.196l-3.531-2.416
           l-0.719-2.998l1.438-2.39l0.688-4.219v-2.389l-4.219-3.609l-7.781-0.583l-6.375-1.808l-4.25-4.22L1423.83,641.814z"
        />
        <path
          onClick={() => setActiveMap("bulgan")}
          className={
            activeMap === "bulgan" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="bulgan"
          stroke="#ffffff"
          stroke-width="3"
          d="M1055.923,227.298
           l-0.125,0.159l-4.969,8.414l-6.344,3.583l-7.094,3.026l-2.125,4.193l-1.406,7.802l-1.406,4.805l-8.5,5.998l-7.063,11.413v7.219
           l2.844,4.805l7.063,5.388l7.781,0.61l8.469,3.609l4.25,3.61l7.063,5.998l4.969,5.415l1.406,4.194l-1.406,5.414l-4.25,0.584
           l-3.531-2.39l-3.531-2.415l-5.656-2.389l-4.25-1.804l-0.719,3l1.438,2.999l0.688,5.998l-2.125,10.218l-3.531,5.414l-7.781,1.195
           h-9.188l-6.344,4.193l-5.656,1.221l-2.844,4.804l-5.656,7.803l-4.938,1.804l-8.5,8.387l-2.125,9.023l0.719,3l2.813,2.999l1.438,3
           l-0.719,0.45v3.77l2.813,4.194l3.563,2.999h5.656l5.656,1.805v4.193l4.219,6.025l2.844,2.389l5.656-0.584l1.406-3.61l2.125,0.583
           v4.805l-0.719,6.609v11.412l2.844,9.609l5.656,13.216l6.375,13.218l4.219,4.804l9.906,3.61l4.969,3l6.344,0.583l2.844,3.611
           l2.125,8.413l0.688,7.218v5.39l-1.406,1.804l-0.719,1.805l1.438,5.999l11.313,5.415l8.469,8.412l8.5,4.804l4.938,2.389l2.844,5.998
           l-1.438,6.026v0.583l2.844,4.804l14.125,13.216l4.969,9.024l5.656,6l11.313,1.807l10.594,0.581l6.375-0.581l-0.719-4.224
           l-0.688-4.803l-1.438-5.389l2.844-2.416l2.125-2.387l0.688-3v-1.805l-2.094-2.998l-1.438-4.806l3.531-6.607l7.781-7.828
           l2.125-2.391h3.531l1.438,2.391l2.094,2.414h2.125l2.844-2.998l2.813-3.61l5.656-0.611l6.375-4.194l3.531-5.414l0.719-9.607
           l-2.844-5.998l-2.125-7.805l-4.938-5.413l-4.938-2.388l-8.5-1.804l-2.125-3.611l-1.406-4.194l-2.844-4.218l-1.406-6.609
           l2.125-5.999l4.25-4.193l4.25-4.803l4.219-7.803l0.719-6.024v-4.805v-4.193v-3.61l-2.844-1.193l-2.094-2.416l-0.719-1.805v-3.583
           l3.531-6.608l2.844-2.416v-2.389l-2.125-1.194h-1.438l-1.406-4.804l-1.563-2.255l-3.375,1.646l-8.5-1.194l-5.656-1.805
           l-6.344-1.806l-5.656-2.999l-4.25-4.22l-5.656-2.999l-1.406-3.61l-2.125-2.999l-0.719-5.388l4.938-8.413l3.563-5.414l7.063-5.999
           l9.906-8.997l7.781-6.609l10.594-1.805c0,0,1.938-0.309,4.5-0.61c1.988-0.232,4.262-0.358,6.375-0.45l1.156-4.354v-10.802
           l3.531-3.609l2.125-4.194l-1.438-2.416l-4.219-5.387l-0.719-2.416l-4.938-2.388l-0.719-4.22l-4.25-11.996v-15.022v-7.804
           c0,0-0.531-0.895-1.063-2.096c-0.529-1.201-1.063-2.727-1.063-3.929c0-1.2-0.865-3.887-1.75-6.289
           c-0.723-1.966-1.229-3.114-1.469-3.689l-1,0.981l-4.25,3.61l-5.656,3l-5.656,0.61h-4.25h-2.813l-4.25-5.414l-3.531-0.611
           l-2.844,0.611l-5.656,2.998l-2.125,3.001h-4.938h-2.844h-4.219l-4.969-3.001l-4.938-4.192l-2.844-3.609l-2.125-3.61l-3.531-3.609
           l-4.938-1.194l-3.531,1.194l-5.656,2.415l-2.844,0.611l-3.531-1.805l-2.813-3.61l-2.125-2.415h-4.969l-2.813-2.999l-2.125-1.193
           h-4.25L1055.923,227.298z"
        />
        <path
          className="inactive"
          id="khovd"
          stroke="#ffffff"
          stroke-width="3"
          d="M351.58,790.18
           l0.625-6.61v-7.803v-6.608l0.719-14.412l1.406-6.025l0.719-6.582l0.719-6.024l-2.125-8.998l-2.125-5.416v-7.189v-7.22l2.125-4.804
           l2.813-1.194l2.125-0.612l2.844-0.583l4.219-1.221l2.844-1.804l4.25-4.194l3.531-2.388l2.125-3.026l1.406-2.999v-2.999l0.688-7.193
           l2.844-4.218l2.844-2.391l0.688-3l4.969-3.609l0.688-3.607l1.406-4.805l3.563-4.195l1.406-4.22v-3.609v-2.998l-1.406-5.999
           l-2.125-3.609v-5.388v-4.804v-4.804l1.406-1.219l2.813-0.587l2.844,2.998h8.469l4.969-1.805l1.406-1.193l4.969-2.999l1.406-1.22
           l3.531-2.999l3.531-0.584l2.125,0.584h4.25l2.125,1.803l0.688,1.806v1.194l2.125-1.804l2.844-2.999l4.219-2.998l1.438-2.391
           c0,0,0-0.623,0-3.025s0-4.804,0-4.804l-2.844-7.802l-2.125-4.805l-3.531-4.804l-2.125-3v-4.193l0.719-7.22v-4.804l-1.406-7.803
           l-1.438-6.61l-4.938-1.804l-2.844-2.998l-4.219-2.39l-2.844-4.22l-7.063-5.388l-2.125-3.026l-1.406-4.804l0.688-5.998l-0.688-2.999
           l-3.531-3.609l-3.563-3.608l-2.094-4.805l-3.563-5.389l-2.094-4.805l-9.219-14.412l-0.688-4.802l-3.531-7.22l-2.125-7.191
           l-2.125-7.831l0.688-2.388l3.563-3.609l2.125-1.806l2.813-0.583l0.719-2.999l4.219-3.026l2.125-3.583l4.25-4.22l5.656-5.998
           l2.125-3v-2.999l-1.406-1.805l-3.531-1.804l-2.844-2.416l-3.531-3.583l-4.25-2.415l-2.125-2.389v-4.804l-2.125-4.804l-0.688-4.803
           l-3.531-2.416l-5.656-2.388l-2.844-3.026l-3.531,0.611l-0.719,1.806l-2.125,4.193l-2.813,4.219l-0.719,2.999l-3.531-1.194
           l-1.406-1.805l-2.844-2.999l-4.219-3.026l-1.438-2.387l-2.813-0.612l-5.656-1.805h-4.25l-5.656-2.388h-6.375l-4.219-1.194
           l-1.438-1.222l-0.688-3.583l0.688-4.219l0.719-2.39l-0.719-2.998h-3.531l-3.531-2.416l-6.344-0.61l-1.438-1.194v-4.193v-6.609
           v-4.219v-5.388l-2.125-3h-2.813l-2.844,1.194l-4.219,1.194l-4.25,2.999l-7.063,3.61l-3.563,4.22l-4.219,5.998l-3.531,1.194
           l-4.25,1.805c0,0-15.558,1.194-19.094,1.194s-16.281,0-16.281,0l-7.781,0.611l-2.813,2.999l-3.531,0.611l-0.719,1.194l2.125,1.194
           l1.406,2.415l3.531,4.804l0.719,1.779l0.719,1.219l-1.438,4.194l-1.406,2.415l-0.688,3.583l-3.563,6.025l-2.094,4.193v4.804
           l0.688,8.414l1.406,7.193l2.844,4.803l2.813,6.025l2.844,4.804l2.125,2.389v4.22l-0.719,7.193l1.406,14.411l2.125,4.804
           l2.844,5.415l0.719,2.999l4.219,6.609l2.125,3.609l0.719,3l-2.125,4.804l-4.25,4.804l-2.125,5.413v2.39l3.531,2.998l3.563,3.608
           l2.813,4.806l2.125,3.61l0.719,4.803v5.998l-2.125,3.611l-2.844,5.387l-1.406,4.221l0.719,1.804l2.813,3.585l1.406,3.607
           l2.125,4.221l5.656,6.607l5.656,4.806l4.969,6.584l3.531,4.218l3.531,4.803v1.807l2.125,4.194v2.411l-2.125,1.807l-3.531-0.609
           l-5.656-1.197l-4.25-0.608h-5.656l-8.5,0.608l-5.656,0.588l-4.219,0.609l-3.563-3l-4.938-4.22l-8.469-8.387l-3.813-4.168
           l-1.156,2.947l-0.688,6.023v8.998l-4.969,6.61l-2.125,4.192l-4.219,7.22l-3.563,8.412c0,0,1.422,1.791-1.406,4.192
           c-2.829,2.403-2.813,5.416-2.813,5.416l-1.438,4.194v1.193l2.844,1.805l2.813,0.612l2.844,4.192v7.218l-1.406,7.804l-4.969,7.193
           l-2.813,4.22l-5.656,5.999l-7.094,9.607l-6.344,5.413l-3.531,5.388l-1.438,6.611l-4.938,7.215v2.391v1.221l0.719,7.193v1.194
           l1.406,3.608l2.125,1.806l1.406,4.805v5.413l-1.406,4.193v4.806l0.688,2.415v0.583l2.844,1.195l4.25,0.609l2.813-1.195l4.25,0.586
           h4.25l2.813,3.609l6.375,0.61l0.688,1.804l3.563,5.388l4.938,1.221l4.938,1.196l7.094,2.387l4.938,1.222l0.719,2.388l0.688,1.195
           l1.438,4.22l4.219,1.804h4.25l5.656,0.586h7.781h4.25l7.063,0.609l6.375,1.195l3.531,1.193l4.25,3.024l2.813,1.808l4.25,1.191
           l4.938,2.391l4.25,0.609h4.938l2.125-1.804l2.125-1.196l2.844-1.191h2.125l2.094,1.191l2.844,2.391l5.656,1.221l2.844,2.392
           l5.656,0.607l2.813,1.807l6.375,2.999l4.938,0.583l6.375,1.22l2.125,1.195l6.344,1.806H351.58z"
        />
        <path
          className="inactive"
          id="govialtai"
          stroke="#ffffff"
          stroke-width="3"
          d="M681.018,1087.436
           v-16.798v-10.22l-2.125-9.608l-1.406-16.827l-1.406-12.604v-13.804l1.406-2.997l4.25-4.805l2.813-4.803v-4.222v-6.608v-5.388
           l4.969-6.026l1.406-4.803l1.406-8.387l0.719-12.635v-11.385l1.406-1.806l2.125-4.803l-1.406-3l-2.125-3.608c0,0,0-1.818,0-4.221
           s0-11.997,0-11.997l1.406-12.606l1.406-5.416v-4.192l3.531-3.026l2.125-1.193l3.531-1.193h4.25l2.125-1.195l0.719-2.417
           l2.813-4.803v-5.388v-5.414l2.125-1.195v-7.218v-8.997l2.844-6.025l1.406-4.804v-3.584l1.406-4.22l5.656-5.388l5.656-9.023
           l2.844-6.607l-0.719-8.413l0.719-5.389l2.125-4.805l2.813-3.606l2.125-4.807l2.125-5.998v-6.026v-3.581l-3.531-9.025v-2.999
           c0,0-0.193-1.504-0.281-3.529c-0.088-2.027-0.072-4.586,0.281-6.688c0.354-2.104,0.338-2.234,0.25-1.859
           c-0.088,0.377-0.25,1.273-0.25,1.273l-4.25-9.607l-8.5-12.605l-7.75-13.83l-7.781-14.409l-6.375-7.804l-7.063-8.997l-7.094-9.024
           l-3.531-5.998l-0.688-7.803l-0.719-4.221l-2.813-7.191l-2.844-2.416l-3.531-1.195v-2.999l-3.531-4.192l-1.406-0.612l-2.844,1.195
           l-2.844,4.805l-3.531,5.415l-4.219,2.998l-2.125,2.388l-3.531-1.779l-3.563-2.414l-5.656-5.414l-4.219-5.998l-0.719-3.607v-4.804
           l-1.406-1.197l-5.656-5.413l-2.844-5.389l-6.344-3.607l-2.844-2.391l-3.531-2.414l-3.531,1.193h-5.656l-3.531,2.999h-2.844h-2.813
           l-2.125-3.583l-1.438-2.997L594.049,589l-2.125-4.806l1.406-7.829l3.531-5.998v-6.608l-0.688-5.388l-1.406-4.221l-7.781-4.804
           l-7.094-4.193l-7.75-4.221l-6.375-2.999l-7.063-5.997l-5.656-4.805l-7.094-5.999l-6.344-3.608l-4.25-0.61l-7.781-2.999
           l-7.781-1.807l-7.063-2.387l-2.125-1.807l-2.125-2.416l-3.531-4.193l-3.531-1.191l-7.781-3l-7.781-0.61l-9.906-1.805l-8.469-0.61
           l-6.375-2.388l-2.813-3.001l-4.25-3.609l-4.25-1.805l-4.938-0.61h-4.25l-5.656,2.416h-6.375l-10.594,2.999l-7.781,2.999
           l-6.375,2.416l-0.594,0.769l2,4.62l3.563,3.608l3.531,3.609l0.688,2.999l-0.688,5.998l1.406,4.804l2.125,3.026l7.063,5.388
           l2.844,4.22l4.219,2.39l2.844,2.998l4.938,1.804l1.438,6.61l1.406,7.803v4.804l-0.719,7.22v4.193l2.125,3l3.531,4.804l2.125,4.805
           l2.844,7.802c0,0,0,2.401,0,4.804s0,3.025,0,3.025l-1.438,2.391l-4.219,2.998l-2.844,2.999l-2.125,1.804v-1.194l-0.688-1.806
           l-2.125-1.803h-4.25l-2.125-0.584l-3.531,0.584l-3.531,2.999l-1.406,1.22l-4.969,2.999l-1.406,1.193l-4.969,1.805h-8.469
           l-2.844-2.998l-2.813,0.587l-1.406,1.219v4.804v4.804v5.388l2.125,3.609l1.406,5.999v2.998v3.609l-1.406,4.22l-3.563,4.195
           l-1.406,4.805l-0.688,3.607l-4.969,3.609l-0.688,3l-2.844,2.391l-2.844,4.218l-0.688,7.193v2.999l-1.406,2.999l-2.125,3.026
           l-3.531,2.388l-4.25,4.194l-2.844,1.804l-4.219,1.221l-2.844,0.583l-2.125,0.612l-2.813,1.194l-2.125,4.804v7.22v7.189l2.125,5.416
           l2.125,8.998l-0.719,6.024l-0.719,6.582l-1.406,6.025l-0.719,14.412v6.608v7.803l-0.625,6.61h2.75l6.375,1.804l2.813,3.584
           l4.969,2.415l5.656,1.804l4.25,4.805l3.531,2.999l4.938,2.388l5.656,6.026l5.656,4.801l2.844,3.002l4.938,4.191l2.844,3.612
           l2.094,2.997l4.25,4.219l4.25,4.194l2.844,3.61l4.938,4.193l2.813,3.608l1.438,2.39l4.938,6.024l3.531,1.805l3.531,3.001
           l2.844,2.387l2.813,3.61l2.844,4.805l3.531,0.61l3.531,0.583l5.656,2.416l2.844,1.804l4.938,3.584l4.25,1.221l4.938,1.192h5.656
           l4.969,1.195l3.531-0.609l2.125,1.222l0.688,1.194l2.125,1.193v3.001l-0.688,3.023v5.388l-2.125,3.611l-0.719,4.805v4.191
           l3.531,2.998l2.844,2.416l7.063,3.609l2.813,4.192l2.125,7.22l2.125,4.804l0.719,3.583l1.406,4.806v4.83v5.997v5.389l1.406,4.803
           v4.805l1.438,4.221l2.094,3.609l0.719,4.805l3.531,0.583l0.719,4.805l1.406,2.416l2.844,4.193l1.406,0.607v3.001v3.608
           l-2.844,2.391v1.804l3.563,2.999l3.531,4.806l4.219,4.219l3.563,4.193l3.531,3.609l2.813,2.389l2.844,3.609l2.125,1.806v4.805
           l0.688,2.388l2.125,3.027l3.531,5.385l3.531,3.61l5.656,0.611l11.313,4.193h8.5l11.313,1.193l10.594,1.805h5.656l9.219,2.999
           l9.875,2.416l3.563,1.807c0,0,2.828,4.19,5.656,4.19s5.656,1.195,5.656,1.195l4.938,1.222l5.656,2.388h4.938l7.094,1.806
           L681.018,1087.436z"
        />
        <path
          onClick={() => setActiveMap("zavkhan")}
          className={
            activeMap === "zavkhan" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="zavkhan"
          stroke="#ffffff"
          stroke-width="3"
          d="M685.986,635.234
           l2.813-2.416l2.844-4.805l2.813-4.804l4.25-5.388l2.813-7.829v-11.387v-6.024l2.844-7.803l7.063-8.414l4.25-5.389l7.063-3.61
           l6.375-3.607l5.656-3l4.25-4.192l0.688-4.221v-2.999v-7.191v-3.609l4.25-3.001l3.531-1.804l0.719-5.999v-3.608l4.25-3.001
           l2.094-3.024l2.844-3v-3l-1.406-7.801l-1.438-8.415v-5.388l3.563-9.023l6.344-3.61l8.5-2.389h4.938l5.656-1.805l2.125-3.609
           l4.938-3.584l7.094-3.025l4.938-5.386l0.719-4.222l0.688-7.803l-1.406-5.387l-3.531-3.025v-6.583l2.813-6.609l1.438-3.61v-3.61
           l2.125-3.609l1.406-4.193l2.125-4.195l-2.125-4.803v-6.025l-3.531-5.997l-0.719-1.195l2.125-2.999l1.406-3.025V377.6l3.531-1.221
           v-2.999l-2.125-1.805l-2.813-2.999l-2.125,0.611l-4.938,2.388l-6.375,1.221l-6.375,1.806l-7.781,4.804l-4.219,5.388h-4.969
           l-2.813-2.999l-2.844-2.389h-5.656l-7.063-1.805l-3.531-0.61h-5.656l-7.063,1.805h-6.375l-7.063-3l-4.25-7.219l-4.25-7.192
           l-7.781-6.025l-8.469-2.389l-7.094-1.195l-9.188-0.61l-7.063-2.388l-6.375-6.609l-4.219-6.608l-4.969-6.024v-4.193l0.719-8.414
           l6.344-8.999l7.781-8.413l4.25-4.194v-6.025l1.656-9.793l-0.938-2.814l-1.406-4.193l-1.438-5.999l-4.938-1.221l-6.375-1.805
           c0,0-1.422-0.583-4.25-0.583s-8.469-0.61-8.469-0.61h-3.531l-4.969-2.389l-0.688-2.999l-2.125-1.805h-3.531l-0.719,1.805
           l-1.406,3.583l-2.844,1.22l-2.125-1.805l-2.813-0.61l-5.656-4.193l-1.406-2.416l-1.438-2.388l-4.219-1.805l-4.969-2.415h-3.531
           l-3.531-2.39l-2.844-0.61l-2.094,0.61l-4.969,0.584l-3.531,3.025l-2.125,1.195l-3.531,1.193l-0.719,3h-2.813l-3.531,3h-3.375
           l-0.875,1.805l-2.125,7.218l-1.406,6v7.218l-4.25,3.583v5.415l-2.813,4.803v2.389l1.406,3.609l2.813,1.194l0.719,4.22l2.844,6.608
           l1.406,3.61l2.813,5.388l1.438,2.999v2.999l2.813,1.222l1.406,3.584v3.024l-3.531,3.583l-0.688,1.806l-3.563,2.415l-2.813,4.804
           l-0.719,1.805l-2.813,5.999v4.804v5.999l-2.125,4.22l-0.719,3.583v3.61v3l-3.531,0.61l-7.063,0.611l-3.531,1.193l-2.844,1.195
           l-3.531,1.805l-3.531,2.388c0,0-0.188,0.62-0.719,1.221c-0.266,0.301-0.599,0.598-1.063,0.823c-0.464,0.227-1.043,0.373-1.75,0.373
           c-2.828,0-3.531,0-3.531,0l-4.25,2.998c0,0-0.355,0.311-1.063,0.61c-0.707,0.3-1.773,0.584-3.188,0.584
           c-0.707,0-1.286,0.123-1.75,0.292s-0.797,0.385-1.063,0.611c-0.53,0.45-0.719,0.901-0.719,0.901l-2.844,1.195l-3.531-1.195
           l-2.813-2.389l-3.531-2.414l-4.25-4.195l-2.844-2.414l-3.531-1.194l-7.063-0.611l-3.531-1.194l-7.063-1.805l-2.125-3l-3.531-0.583
           l-7.781,1.777l-4.25,1.806l-4.25,2.414l-3.531,3.61l-2.125,1.194h-2.125l-1.406-1.194l-0.719-1.22l-4.219-1.194h-3.563h-3.531
           l-2.813,4.193v4.22l-2.125,5.388l-4.25,3.609l-4.25,1.221l-4.938,4.803l-5.656,3.371v2.628l-2.125,3l-5.656,5.998l-4.25,4.22
           l-2.125,3.583l-4.219,3.026l-0.719,2.999l-2.813,0.583l-2.125,1.806l-3.563,3.609l-0.688,2.388l2.125,7.831l2.125,7.191l3.531,7.22
           l0.688,4.802l9.219,14.412l2.094,4.805l3.563,5.389l0.094,0.185l0.594-0.769l6.375-2.416l7.781-2.999l10.594-2.999h6.375
           l5.656-2.416h4.25l4.938,0.61l4.25,1.805l4.25,3.609l2.813,3.001l6.375,2.388l8.469,0.61l9.906,1.805l7.781,0.61l7.781,3
           l3.531,1.191l3.531,4.193l2.125,2.416l2.125,1.807l7.063,2.387l7.781,1.807l7.781,2.999l4.25,0.61l6.344,3.608l7.094,5.999
           l5.656,4.805l7.063,5.997l6.375,2.999l7.75,4.221l7.094,4.193l7.781,4.804l1.406,4.221l0.688,5.388v6.608l-3.531,5.998
           l-1.406,7.829l2.125,4.806l2.813,4.805l1.438,2.997l2.125,3.583h2.813h2.844l3.531-2.999h5.656l3.531-1.193l3.531,2.414
           l2.844,2.391l6.344,3.607l2.844,5.389l5.656,5.413l1.406,1.197v4.804l0.719,3.607l4.219,5.998l5.656,5.414l3.563,2.414l3.531,1.779
           l2.125-2.388l4.219-2.998l3.531-5.415l2.844-4.805l2.844-1.195l1.406,0.612l3.531,4.192v2.999l3.531,1.195l2.813,2.416H685.986z"
        />
        <path
          onClick={() => setActiveMap("erdenet")}
          className={
            activeMap === "erdenet" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="erdenet"
          stroke="#ffffff"
          stroke-width="3"
          d="M1187.58,339.302
           c-2.113,0.092-4.387,0.218-6.375,0.45c-2.563,0.301-4.5,0.61-4.5,0.61l-10.594,1.805l-7.781,6.609l-9.906,8.997l-7.063,5.999
           l-3.563,5.414l-4.938,8.413l0.719,5.388l2.125,2.999l1.406,3.61l5.656,2.999l4.25,4.22l5.656,2.999l6.344,1.806l5.656,1.805
           l8.5,1.194l3.375-1.646l-0.563-0.77l-4.219-4.192v-4.194l4.219-3.609l5.656-4.22l1.438-2.999l-0.719-8.997v-9.61
           c0,0-0.162-1.967-0.25-4.22c-0.088-2.252-0.104-4.797,0.25-5.998c0.707-2.402,3.531-8.414,3.531-8.414L1187.58,339.302z"
        />
        <path
          onClick={() => setActiveMap("arkhangai")}
          className={
            activeMap === "arkhangai" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="arkhangai"
          stroke="#ffffff"
          stroke-width="3"
          d="M757.393,513.303h8.469
           l7.781,1.805c0,0,0.554,0.453,1.438,0.903c0.441,0.224,0.966,0.466,1.563,0.636s1.262,0.267,1.969,0.267s1.141,0.219,1.406,0.558
           c0.266,0.338,0.344,0.795,0.344,1.246c0,0.9-0.344,1.805-0.344,1.805l-2.125,3.611l2.125,4.803l1.406,5.999l6.375,2.416
           l5.656,4.192l9.906,1.193h11.313l7.781-4.191l6.344-3.001l9.188-4.22l3.563-1.805l4.938,1.805l2.125,5.414v7.192v4.805l0.688,7.219
           l2.844,5.414l2.813,5.389v14.41v9.024v4.193l4.25,1.195l4.969-3.584l4.219-1.805l7.094-2.998l2.125-0.612l4.938,0.612l3.531,1.195
           c0,0-0.338,0.154-0.25,0.529c0.044,0.188,0.198,0.434,0.563,0.743s0.929,0.664,1.813,1.115c0.884,0.45,1.593,1.101,2.156,1.805
           s0.972,1.464,1.281,2.176c0.619,1.427,0.813,2.628,0.813,2.628l-2.125,4.805l3.531,5.413l4.25,1.194l2.813,1.805l2.844,3.608
           l1.406,4.193l8.5,1.807l4.937,2.415l3.531-3.61l3.531,1.807h4.969c0,0,3.516,0,6.344,0c0.707,0,1.631,0.145,2.625,0.371
           c0.994,0.226,2.059,0.521,3.031,0.823c1.945,0.599,3.531,1.193,3.531,1.193l3.563,4.804l2.094,4.222l5.656,1.192h7.094h6.344
           l4.25-2.415h2.844l4.219,1.806h3.563l4.938-1.195v-3.609l2.125-7.191l4.25-1.195l2.094-1.805l4.25-1.805l0.719,0.586l2.813,3.023
           h5.656l4.969-2.414h4.25h4.938l3.531,2.414l2.125,2.999l0.719,4.195h1.406l2.125-3.002l4.25-3.608v-7.192l2.813-6.024l5.656-5.997
           l4.25-7.806v-7.219l-0.719-3.585l-2.813-4.803l-2.125-3.609l-2.125-4.803l2.813-2.999l3.563-0.611h2.813l3.531,4.194l5.656,4.219
           l4.969,1.807l4.938,1.803l3.531,1.779l4.25,2.415l0.719,1.806l-0.719,5.415l2.125,4.191l9.188,3.61h12.719l12.031-2.416
           c0,0,1.775-1.804,3.719-3.98c0.973-1.087,1.982-2.272,2.844-3.372c0.861-1.098,1.553-2.086,1.906-2.839
           c0.014-0.027,0.172-0.442,0.188-0.477l-0.875-0.133l-5.656-6l-4.969-9.024l-14.125-13.216l-2.844-4.804v-0.583l1.438-6.026
           l-2.844-5.998l-4.938-2.389l-8.5-4.804l-8.469-8.412l-11.313-5.415l-1.438-5.999l0.719-1.805l1.406-1.804v-5.39l-0.688-7.218
           l-2.125-8.413l-2.844-3.611l-6.344-0.583l-4.969-3l-9.906-3.61l-4.219-4.804l-6.375-13.218l-5.656-13.216l-2.844-9.609v-11.412
           l0.719-6.609v-4.805l-2.125-0.583l-1.406,3.61l-5.656,0.584l-2.844-2.389l-4.219-6.025v-4.193l-5.656-1.805h-5.656l-3.563-2.999
           l-2.813-4.194v-3.77l-2.125,1.354l-4.25,2.999l-4.938,3l-4.938,1.804l-6.375-2.388l-4.25-4.22l-3.531-4.193l-0.719-4.194
           l-9.188-6.024l-7.063-0.584l-5.656-2.999l-7.063-5.415h-4.969l-4.938,3.609l-6.375,2.389l-7.781,9.608l2.125,2.415l4.25,3
           l4.25,1.194l1.406,4.194l-4.938,2.416l-4.25,0.609l-3.531,3.583l-2.844,6.608l-3.531,6.026l-5.656,7.193l0.719,4.193l2.094,4.831
           l2.125,3.583l0.719,3.61l-0.719,4.804l-8.469,0.61l-5.656,1.806l-2.844,4.804v5.387l-3.531,2.999l-3.531-2.388l-2.813-3.609
           l-6.375-6.609l-3.531-2.999l-7.094,1.195l-3.531,4.218v3.584l-0.688,5.414l-0.719,3.61l-4.938,3.609l-4.25,2.388h-4.938l-4.25,3
           h-5.656l-1.438-1.805l-7.75-2.999l-4.25-2.388l-5.656-0.609l-1.375-1.169l-2.156,2.361l-7.094,3.025l-4.938,3.584l-2.125,3.609
           l-5.656,1.805h-4.938l-8.5,2.389l-6.344,3.61l-3.563,9.023v5.388l1.438,8.415l1.406,7.801V513.303z"
        />
        <path
          className="inactive"
          id="sukhbaatar"
          stroke="#ffffff"
          stroke-width="3"
          d="M1673.861,679.477
           l5.594,1.99l7.781,1.193l9.188,3.026l3.531,4.192l3.531,3l1.438,3l0.688,4.802l0.719,4.221l2.813,1.777l-0.688,1.807l2.125,4.803
           l0.688,4.221l4.25,7.804v8.413l-0.719,11.997v4.803l2.125,1.222l2.125,2.391l4.969,5.413l3.531,4.802l0.688,3.584l3.531,4.22
           l3.563,6.608l4.219,8.997l1.438,7.806l0.688,6.608l1.594,7.67l1.938-1.646l4.969-4.223l7.063-2.997l7.063-2.998l9.906-0.613
           l6.375,0.613l6.344,2.998l4.25,2.997l6.375,5.416l3.531,3.61l4.25,5.997l4.25,1.804l4.938,3.585l4.938,2.417l12.75,0.607
           l9.188,1.195l7.063,0.583l8.5-0.583l4.219-0.611l6.375-1.191l6.375-1.807l7.063-0.61l4.938-4.804l2.844-2.999l4.938-1.804
           l5.656-4.806l2.844-4.805l4.219-1.775l2.125-3.028l3.531-4.193l2.125-1.191l1.438-4.223l1.406-6.607l2.813-5.39l1.438-2.998
           l4.219-3.61l2.844-0.61l6.375-4.803l2.813-0.585l1.406-3.026v-1.193v-6.609l-0.688-3.582l-0.719-4.221l-2.813-2.998v-3.609
           l0.688-2.389l4.25-1.221l4.938-3.584l0.719-1.221l2.813,0.611l0.719,1.193l4.25,4.221c0,0,2.828,0,5.656,0s9.188,0,9.188,0h9.906
           l8.469-2.416l4.25-0.583l9.906-1.222l4.938-4.193l5.656-1.193c0,0,3.547-1.816,6.375-2.418c2.828-0.599,6.375-2.388,6.375-2.388
           l3.531-4.802l5.656-1.807l2.813-7.802l3.531-1.222l2.125-1.194l1.438-3.61l3.531-4.804l4.938-3.583l4.25-2.415l4.25-3.607l3.531-3
           l1.406-1.806v-3.583l-0.719-1.805l-3.531-2.414v-3.612v-2.388v-3.608l2.125-6l2.844-4.803l0.688-5.997l1.438-6.611v-6.023
           l2.813-4.804l4.938-4.194l1.406-3.211l-3.5-0.981l-7.781-4.221l-6.375-6l-5.656-6.607l-4.938-8.997l-2.844-5.415l-2.125-5.414
           l-5.656-2.998h-6.344l-12.75,1.803l-11.313,1.195l-5.656,2.415l-4.938,5.389l-1.406,5.416l-10.625,2.414h-21.219l-12,2.999
           l-3.563,4.803l-4.219,3.61l-7.094-1.222l-1.406-2.999l-3.531-3.608c0,0-0.537-0.896-1.156-2.098
           c-0.619-1.201-1.303-2.702-1.656-3.899c-0.176-0.603-0.51-1.19-0.875-1.753c-0.363-0.563-0.789-1.089-1.188-1.539
           c-0.795-0.901-1.5-1.513-1.5-1.513v-7.803l-6.344-2.417l-6.375-2.997l-6.375-2.999l-4.938-7.195l-0.719-6.607l0.719-7.22v-8.996
           l-2.125-9.025l-2.813-2.999l-4.25-0.582h-6.375l-7.063-3.61l-3.531-3l-2.844-4.219l-6.344-0.586l-4.969-4.804l-7.781-3.024
           l-4.219-5.387l-7.781-2.417l-8.5-0.583l-12.719-1.221l-9.188-1.779l-7.094-3.025h-7.063l-6.344,1.803l-4.969,4.806l-7.063,1.222
           l-4.938-1.222l-1.438-4.193v-5.414l-0.688-10.802v-2.23c-2.236,0.783-5.682,1.994-6.375,2.23c-1.768,0.6-2.84,1.044-3.813,1.646
           c-0.973,0.6-1.836,1.346-3.25,2.548c-0.707,0.601-1.457,0.994-2.188,1.221c-0.729,0.225-1.443,0.292-2.063,0.292
           c-1.236,0-2.125-0.292-2.125-0.292l-7.781,4.193l-7.781,5.996c0,0-0.684,0.162-1.656,0.612c-0.486,0.225-1.061,0.534-1.625,0.929
           c-0.563,0.395-1.125,0.859-1.656,1.461c-0.529,0.599-0.834,1.338-1,2.068c-0.166,0.733-0.199,1.476-0.156,2.152
           c0.088,1.35,0.438,2.387,0.438,2.387v9.025l2.844,4.803l1.406,5.999v5.414l-3.531,2.388l-4.25-0.582l-4.25-1.806l-4.938-0.612
           l-7.063,1.807l-4.969,2.388l-7.063,9.634l-4.25,5.389l-3.531,10.803l-0.719,6.608c0,0,0,6.614,0,10.217
           c0,0.903-0.113,1.689-0.313,2.363c-0.199,0.677-0.453,1.247-0.719,1.698c-0.529,0.901-1.063,1.354-1.063,1.354v9.608l-4.969,14.412
           l-4.25,10.191v4.22v4.194l2.844,6.608l0.719,3.609l4.219,4.194l2.844,7.219l-3.531,7.192l-3.531,7.219l-5.656,5.388
           L1673.861,679.477z"
        />
        <path
          onClick={() => setActiveMap("dornogovi")}
          className={
            activeMap === "dornogovi" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="dornogovi"
          stroke="#ffffff"
          stroke-width="3"
          d="M1512.861,667.426
           l-1.688,1.434l-3.531,2.389l-2.844,6.607l-2.813,5.414l-0.719,7.805l-1.406,6.61l-1.438,7.802l-1.406,1.805l-5.656,2.999
           l-5.656,3.609l-5.656,2.999l-3.531,2.998l-0.719,1.804l-1.406,3.612l-1.406,2.39l0.688,1.221l2.844,1.804v5.997v15.023
           l-0.719,19.799l1.438,18.022l0.688,8.414l2.125,10.801l2.125,1.805v1.804l1.406,3.612v2.388l-1.406,1.805l-7.063,2.415
           l-0.719,2.999l-2.125,8.997l0.719,4.805v3.608l-2.844,1.195l-4.219,2.415l-11.313,1.803l-12.75,1.195h-7.781l-3.531,2.392
           l-3.531,5.413l-1.406,12.023v5.997l-0.719,5.996l-2.125,3.001v5.999l-2.813,8.415v6.606l1.406,8.416l3.531,12.604l4.25,6.609
           l3.531,6.609l1.406,6.605v9l0.719,3.607l2.125,3.61l3.531,10.191l2.125,4.223l0.719,7.191l1.406,18.022v7.802l1.406,6.024
           l2.844,6.607l2.094,6.582l3.563,4.222l2.813,2.389l1.406,2.414l-0.688,5.391l-2.125,19.823v14.413v9.606l-2.125,4.222v2.39
           l0.719,4.804l1.406,7.217l1.406,3.584l0.719,3.608v2.416l-1.438,3.61l-0.688,7.192v6.608h2.813l5.656-0.61l5.656-3.581l2.844-1.806
           l9.188-2.416h5.656l8.5,0.609l4.219,1.807l6.375,1.192l5.656,0.613l7.063,1.192l6.375,1.805h4.25l6.344,1.804l4.25-1.22
           l2.125-3.581l4.25-3.612h4.938h4.969l4.219,0.586l4.25,1.221h4.25l4.25-2.999l4.219-4.805h4.969l4.219-4.218l3.563-3l6.344-4.805
           l1.438-3.61l3.531-2.999l4.219-1.804c0,0,0.734-1.806,3.563-1.806s5.656-1.194,5.656-1.194l4.219-1.195h4.969l3.531-1.194
           l5.656-1.804l2.125-4.222l4.25-4.804h2.813l4.25-0.582l4.25-1.222l1.406-2.999l2.125-4.805l2.125-2.999
           c3.535-1.2,5.656-2.388,5.656-2.388l2.125-3.025l3.531-0.585v-4.22l2.125-3.584l0.688-5.414v-3.61v-2.999l2.844-3.608l2.813-2.39
           l1.406-1.193l1.438-3.609v-2.414l2.813-3.585l2.844-4.219l2.125-0.586l1.406-3.607l3.531-6.023l4.938-4.195l3.563-4.805
           l4.219-1.803l3.531-0.585l2.125-1.805l5.656-1.804h4.969l3.531-1.196l4.25-2.414l2.813-2.39l6.375-2.414l4.25-1.807l2.813-2.998
           l2.125-1.194l1.406-3.609v-1.194l3.531-3.611l2.844-1.805l4.938-1.803l3.531-5.388v-4.22v-4.194l-1.406-7.219l-1.406-7.804
           l-0.719-4.194l-5.656-6.024l-2.125-2.999l-5.656-4.193h-0.688l-6.375-2.417l-3.531-2.997l-3.531-5.996l-4.969-7.223l-4.219-5.386
           l-2.844-4.219l-4.25-1.196l-1.406-1.805l-0.719-2.388l-0.688-3.612v-2.998l0.688-5.414v-4.806l2.844-4.192l1.406-3.609l2.125-3.607
           l0.719-5.999l0.688-3.61l2.125-5.39l2.125-6.022v-2.999l2.844-4.193l3.531-6.026l0.688-2.998v-3.583l3.563-5.415l4.219-5.998
           l1.594-1.353l-1.594-7.67l-0.688-6.608l-1.438-7.806l-4.219-8.997l-3.563-6.608l-3.531-4.22l-0.688-3.584l-3.531-4.802
           l-4.969-5.413l-2.125-2.391l-2.125-1.222v-4.803l0.719-11.997v-8.413l-4.25-7.804l-0.688-4.221l-2.125-4.803l0.688-1.807
           l-2.813-1.777l-0.719-4.221l-0.688-4.802l-1.438-3l-3.531-3l-3.531-4.192l-9.188-3.026l-7.781-1.193l-5.594-1.99l-3.594,4.405
           l-7.781,10.802c0,0-1.951,0.595-4.25,1.195c-2.297,0.6-4.93,1.194-6.344,1.194s-4.609-0.427-7.438-0.877
           c-2.828-0.448-5.313-0.902-5.313-0.902l-2.125-1.805l-8.469-1.804l-4.25-6.609l-4.25-9.024l-2.813-5.388l-2.844-5.414l-4.938-4.804
           l-4.25-8.413l-6.344-7.805l-2.844-2.998l-6.375-1.194l-5.656,0.583l-3.531,2.415c0,0-0.334,0.612-0.688,1.514
           s-0.719,2.09-0.719,3.292c0,1.198,0.365,2.389,0.719,3.291c0.354,0.901,0.688,1.513,0.688,1.513l1.406,5.998l2.125,5.414v4.804
           l-2.813,6.608l-2.844,6.61l-4.219,5.997l-8.5,7.804h-9.906c0-0.003-9.174,0-14.125,0c-1.236,0-2.438-0.097-3.531-0.267
           c-1.094-0.169-2.098-0.41-2.938-0.638c-1.68-0.448-2.719-0.901-2.719-0.901s-1.607-0.28-3.375-0.955
           c-0.883-0.337-1.797-0.792-2.594-1.326c-0.795-0.537-1.459-1.162-1.813-1.913c-1.414-3.003-1.438-3-1.438-3l-5.656-7.801
           l-0.688-4.805l-2.125-3.025l-1.406-3.583L1512.861,667.426z"
        />
        <path
          className="inactive"
          id="govisumber"
          stroke="#ffffff"
          stroke-width="3"
          d="M1512.58,616.017
           l-2.844,1.805l-2.813,3l-2.125,2.388l-0.719,1.222l-3.531,1.195l-2.125-1.808l-0.688-2.997l0.688-4.805l-1.406-2.416l-1.406-2.999
           h-2.844l-1.406,3.608l-1.406,2.393l-8.5,8.411l-2.094,2.999l-4.25,1.221l-4.25,3.001h-5.656l-4.25-1.223l-7.063-2.999l-8.5,0.61
           l-6.344-1.193l-10.625,1.193l-4.219,4.804l-1.438,5.999l2.125,4.803l4.25,4.22l6.375,1.808l7.781,0.583l4.219,3.609v2.389
           l-0.688,4.219l-1.438,2.39l0.719,2.998l3.531,2.416l4.25,1.196l2.813,2.996v7.831l-0.688,10.803v5.388l-2.844,4.22l-3.531,4.193
           l0.719,7.22l0.688,7.19l7.094,6.027l6.344,3.582l7.094,1.221l3.125-1.141l-1.031-0.663l-0.688-1.221l1.406-2.39l1.406-3.612
           l0.719-1.804l3.531-2.998l5.656-2.999l5.656-3.609l5.656-2.999l1.406-1.805l1.438-7.802l1.406-6.61l0.719-7.805l2.813-5.414
           l2.844-6.607l3.531-2.389l1.688-1.434l-3.813-2.176l-3.531-2.998l-0.719-4.194v-13.829l0.719-7.192l2.813-4.802l2.844-7.804v-4.221
           L1512.58,616.017z"
        />
        <path
          onClick={() => setActiveMap("umnugovi")}
          className={
            activeMap === "umnugovi" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="umnugovi"
          stroke="#ffffff"
          stroke-width="3"
          d="M1137.173,819.029
           l-5.719,6.582l-3.531,4.194l-3.531,5.414l-5.656,3.607l-5.656,4.194l-4.969,2.389l-5.656,4.22l-5.656,3.001l-4.25,4.804
           l-3.531,9.606l-2.813,5.416l-4.969,4.803l-4.219,7.192l-2.125,7.805v6.023l-1.438,6l-2.125,1.803l-4.938,4.192l-4.25,4.221v3.002
           l1.438,3.58l2.813,3.611l1.406,1.804l2.125,1.805v2.998l-0.688,3.611l-3.563,1.804l-4.938-1.193l-4.25-0.61l-2.813-1.195h-4.25
           l-13.438,0.586h-10.594l-21.938,2.413l-10.594,3.001l-9.188,2.386l-7.781,4.22l-9.188,2.393l-5.656,4.219l-5.656,1.807
           l-4.969,4.802l-2.125,1.194l-3.531,3.61l-38.875,1.192l-36.063-0.608l-4.25-2.391h-3.531l-3.531-2.415v-2.388l-0.719-4.804
           l-4.938-4.803l-5.656-9.024l-2.844-3l-3.531-4.806l-3.531-1.804h-4.969l-12-0.583h-7.094l-4.219,2.998l-5.656,0.584l-3.531,1.221
           v2.39l2.094,1.804l0.719,5.414l2.125,6v36.042l0.719,41.432l0.688,21.019l2.125,19.218v11.997l1.406,15.63v8.998v6.609h0.719
           l7.781-1.805l5.656-1.805l4.938-1.807l4.969-1.776h4.938l4.25,2.388l4.25,1.195l2.813,2.414l4.25,1.195l4.25,1.806l3.531-1.195
           l1.406-3.609l2.844-4.193l2.094-1.806h4.25h5.656l5.656,0.584l4.969,2.998l3.531,0.611l2.813,1.806l3.531,1.194l4.969,1.805h4.25
           l4.938,0.61l1.406,2.999l2.843,1.196l3.531,0.607l0.688-1.804l2.125-1.804l2.125-1.806l2.844-0.611l2.125,1.222l3.531,1.804
           l5.656,1.807l4.25,1.777l7.75,1.221l5.656,1.805l4.25,3.582l3.531,0.611l4.969,1.804l4.25,2.391l6.344,6.022l3.531,4.807
           l2.844,2.997l4.938,4.194l2.125,3.609l3.531,4.804l3.531,1.195l5.656,3.61l4.25,1.191l1.406,3.002l0.719,4.218h5.656l7.063,1.805
           c0,0,3.547,0.583,6.375,0.583s8.5,0,8.5,0h7.063h5.656l7.781,2.416l7.063,3.61l4.25,1.778l3.531,3.611l5.656,2.414l2.125,4.194
           h4.938l6.375,2.412h4.938l4.969,1.78l3.531,3.609l2.125,1.806l3.531,3.609l2.813,1.803l7.781,2.39l8.5,0.611l10.594,1.193
           c0,0,2.133,0,6.375,0s11.313-2.999,11.313-2.999l4.938-4.193l5.656-1.222l4.969-1.192h5.656h4.938l3.531,2.999v5.414v7.193v5.413
           l3.531,2.999l4.969,0.609l6.344,0.587h4.969l4.938,3.024l6.375,0.583l2.813,2.416l2.125,1.806l6.375,0.583l2.813-5.996l1.438-2.416
           l4.219-4.805l3.531-3l6.375-1.196l2.844-2.998l5.656-1.804l4.938-1.194l4.25-2.416l5.656-1.805l7.063-2.998l4.25-4.805l5.656-3.609
           l2.125-4.804l2.813-4.803l4.969-3.585l3.531-1.804l6.344-0.61l2.125-4.195l7.094-0.608l0.688,1.804l0.719,1.804v2.39h3.531
           l2.813-2.39l0.719-2.999l4.25-2.999l2.813-4.801l2.125-6.61l3.531-2.415l4.25-5.39l4.25-0.609h5.656l6.375,1.806l4.938-0.611
           l7.063-1.806l9.906-1.803l8.5-1.195l7.75-1.805l4.969-1.805l1.406-5.388l3.531-4.221l2.125-4.803l6.375-0.584l4.25-2.416h4.219
           l2.125,1.195l3.531,1.805l1.438,1.222l2.813,3.581h3.531l5.656,0.61h3.531h4.969v-6.608l0.688-7.192l1.438-3.61v-2.416
           l-0.719-3.608l-1.406-3.584l-1.406-7.217l-0.719-4.804v-2.39l2.125-4.222v-9.606v-14.413l2.125-19.823l0.688-5.391l-1.406-2.414
           l-2.813-2.389l-3.563-4.222l-2.094-6.582l-2.844-6.607l-1.406-6.024v-7.802l-1.406-18.022l-0.719-7.191l-2.125-4.223l-3.531-10.191
           l-2.125-3.61l-0.719-3.607v-9l-1.406-6.605l-3.531-6.609l-4.25-6.609l-3.531-12.604l-1.406-8.416v-6.606l2.813-8.415v-5.177
           l-5.656,2.178l-4.219,3.608l-4.969,0.612l-7.781-0.612l-2.125-3h-4.219h-2.125l-2.125,1.805l-8.5,1.808l-7.781,3.606l-8.469,3.001
           l-9.188,4.803l-10.625,4.806l-7.781,3.581l-10.594,6.61l-9.906,6.024l-7.063,5.999l-6.375,0.609h-4.938l-8.5-2.415l-7.063-4.804
           l-2.813-1.806l-5.656-4.802l-2.125-4.193v-6.027l-1.438-3.583l-2.813-7.218l-5.656-3.608l-2.125-4.805l-2.844-3.584l-2.813-3.61
           l-1.406-2.998l-4.969-1.805l-6.344-0.612h-9.188l-17-0.582l-16.25-1.22l-8.469-1.196l-7.781-1.805l-3.563-1.193l-10.594-5.413
           l-4.938-2.39l-4.25-3.001l-2.844-3.023l-0.688-3.583v-6.026l-2.125-4.804v-5.998l1.406-7.801l-2.125-4.805l-4.938-1.806h-8.5
           L1137.173,819.029z"
        />
        <path
          onClick={() => setActiveMap("uvurkhangai")}
          className={
            activeMap === "uvurkhangai" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="uvurkhangai"
          stroke="#ffffff"
          stroke-width="3"
          d="M958.298,633.427
           l0.625,1.807l2.125,4.192l4.219,2.415l2.844,2.388l2.125,2.999l-0.719,3.028l-2.125,4.192v1.805l1.438,3.608l2.094,1.194l0.719,3
           v3.609l-1.406,3.583l-4.25,3.025l-0.719,5.388l0.719,7.804l-1.406,6.608l-4.25,3l-5.656,6.607l-2.844,6.609l-2.094,8.414v7.22
           l1.406,10.19l0.688,2.416l1.438,4.193v5.413l-3.531,7.803l-0.719,4.194l-3.531,6.024l-2.844,5.998l-3.531,3.61l-3.531,5.388
           l-2.125,1.805l1.406,3.608l2.844,3.612l0.719,2.387l1.406,4.803v1.807l-2.125,4.802l-1.406,6.61v8.413l1.406,8.413l3.531,3.584
           l2.844,2.416l2.813,1.805l5.656,4.193l4.25,2.999l2.813,1.221l4.25,3.583l0.719,1.804v5.414v6.61l1.406,5.388l1.406,5.413
           l1.438,2.998l2.094,5.415v3.61l1.438,4.192l2.125,3.612l1.406,4.192l-1.406,13.219v7.189l-0.719,7.831l0.719,16.802v5.784
           l1.406-0.373l7.781-4.22l9.188-2.386l10.594-3.001l21.938-2.413h10.594l13.438-0.586h4.25l2.813,1.195l4.25,0.61l4.938,1.193
           l3.563-1.804l0.688-3.611v-2.998l-2.125-1.805l-1.406-1.804l-2.813-3.611l-1.438-3.58v-3.002l4.25-4.221l4.938-4.192l2.125-1.803
           l1.438-6v-6.023l2.125-7.805l4.219-7.192l4.969-4.803l2.813-5.416l3.531-9.606l4.25-4.804l5.656-3.001l5.656-4.22l4.969-2.389
           l5.656-4.194l5.656-3.607l3.531-5.414l3.531-4.194l5.719-6.582l-2.906-1.222l-3.531-2.415l-0.688-4.804v-4.806l2.094-3.606v-1.807
           l-1.406-3l-5.656-5.386l-6.344-5.416l-2.125-5.413v-4.804l1.406-4.194l3.531-5.413l2.844-5.39l2.813-4.803l4.938-3.61l9.906-1.193
           h7.781l3.531-1.806l2.125-3.608l1.406-4.804l0.719-5.998v-7.829l4.25-9.607c0,0,0.715-1.181,1.688-2.683s2.189-3.311,3.25-4.511
           c1.061-1.202,1.777-2.25,2.219-3c0.441-0.752,0.625-1.223,0.625-1.223l7.063-1.777l6.375-0.609l2.094-1.805v-3.61l2.125-4.193
           l3.563-3.609l2.094-2.391l0.719-3.607v-4.193l0.969-2.309l-0.75-0.558l-4.5-2.973l-4-3.399l-4-2.545l-5-3.399l-2.5-4.67v-2.548
           l-2-5.096v-4.671l-2-3.398l-2.5-1.696l-1.5-2.127l-2.5-3.819l-2.5-5.945l-0.5-3.822l1-2.549l1.5-2.973l3.5-7.644l3-4.671v-3.398
           l-0.5-3.397l-3-3.396l-2-2.125l-3-3.395l-3-3.824l-2.5-3.82l-1.438-2.019l0.125,0.693l-6.375,0.581l-10.594-0.581l-10.438-1.674
           c-0.016,0.034-0.174,0.449-0.188,0.477c-0.354,0.753-1.045,1.741-1.906,2.839c-0.861,1.1-1.871,2.285-2.844,3.372
           c-1.943,2.177-3.719,3.98-3.719,3.98l-12.031,2.416h-12.719l-9.188-3.61l-2.125-4.191l0.719-5.415l-0.719-1.806l-4.25-2.415
           l-3.531-1.779l-4.938-1.803l-4.969-1.807l-5.656-4.219l-3.531-4.194h-2.813l-3.563,0.611l-2.813,2.999l2.125,4.803l2.125,3.609
           l2.813,4.803l0.719,3.585v7.219l-4.25,7.806l-5.656,5.997l-2.813,6.024v7.192l-4.25,3.608l-2.125,3.002h-1.406l-0.719-4.195
           l-2.125-2.999l-3.531-2.414h-4.938h-4.25l-4.969,2.414h-5.656l-2.813-3.023l-0.719-0.586l-4.25,1.805l-2.094,1.805l-4.25,1.195
           l-2.125,7.191v3.609l-4.938,1.195h-3.563l-4.219-1.806h-2.844l-4.25,2.415h-6.344H958.298z"
        />
        <path
          onClick={() => setActiveMap("bayankhongor")}
          className={
            activeMap === "bayankhongor" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="bayankhongor"
          stroke="#ffffff"
          stroke-width="3"
          d="M757.393,513.303v1.805
           l-2.844,3l-2.094,3.024l-4.25,3.001v3.608l-0.719,5.999l-3.531,1.804l-4.25,3.001v3.609v7.191v2.999l-0.688,4.221l-4.25,4.192
           l-5.656,3l-6.375,3.607l-7.063,3.61l-4.25,5.389l-7.063,8.414l-2.844,7.803v6.024v11.387l-2.813,7.829l-4.25,5.388l-2.813,4.804
           l-2.844,4.805l-2.813,2.416l2.813,7.191l0.719,4.221l0.688,7.803l3.531,5.998l7.094,9.024l7.063,8.997l6.375,7.804l7.781,14.409
           l7.75,13.83l8.5,12.605l4.25,9.607c-0.002,0,0.161-0.896,0.25-1.273c0.087-0.375,0.104-0.244-0.25,1.859
           c-0.355,2.102-0.369,4.66-0.281,6.688c0.088,2.025,0.281,3.529,0.281,3.529v2.999l3.531,9.025v3.581v6.026l-2.125,5.998
           l-2.125,4.807l-2.813,3.606l-2.125,4.805l-0.719,5.389l0.719,8.413l-2.844,6.607l-5.656,9.023l-5.656,5.388l-1.406,4.22v3.584
           l-1.406,4.804l-2.844,6.025v8.997v7.218l-2.125,1.195v5.414v5.388l-2.813,4.803l-0.719,2.417l-2.125,1.195h-4.25l-3.531,1.193
           l-2.125,1.193l-3.531,3.026v4.192l-1.406,5.416l-1.406,12.606c0,0,0,9.595,0,11.997s0,4.221,0,4.221l2.125,3.608l1.406,3
           l-2.125,4.803l-1.406,1.806v11.385l-0.719,12.635l-1.406,8.387l-1.406,4.803l-4.969,6.026v5.388v6.608v4.222l-2.813,4.803
           l-4.25,4.805l-1.406,2.997v13.804l1.406,12.604l1.406,16.827l2.125,9.608v10.22v16.798l1.406,0.611l7.094,1.805l4.219,2.388
           l7.094,4.22h5.656l4.25,3.001l4.938,0.584l7.781,1.221l9.188,1.806l6.375,0.583l4.938,1.805l3.531,2.416l4.25,2.389l7.781,0.61
           l4.938,1.195l3.531,0.608l6.375,1.195h6.375h8.469l7.063-1.195l5.656-2.414h7.781v-6.609v-8.998l-1.406-15.63v-11.997
           l-2.125-19.218l-0.688-21.019l-0.719-41.432v-36.042l-2.125-6l-0.719-5.414l-2.094-1.804v-2.39l3.531-1.221l5.656-0.584
           l4.219-2.998h7.094l12,0.583h4.969l3.531,1.804l3.531,4.806l2.844,3l5.656,9.024l4.938,4.803l0.719,4.804v2.388l3.531,2.415h3.531
           l4.25,2.391l36.063,0.608l38.875-1.192l3.531-3.61l2.125-1.194l4.969-4.802l5.656-1.807l5.656-4.219l7.781-2.02v-5.784
           l-0.719-16.802l0.719-7.831v-7.189l1.406-13.219l-1.406-4.192l-2.125-3.612l-1.438-4.192v-3.61l-2.094-5.415l-1.438-2.998
           l-1.406-5.413l-1.406-5.388v-6.61v-5.414l-0.719-1.804l-4.25-3.583l-2.813-1.221l-4.25-2.999l-5.656-4.193l-2.813-1.805
           l-2.844-2.416l-3.531-3.584l-1.406-8.413v-8.413l1.406-6.61l2.125-4.802v-1.807l-1.406-4.803l-0.719-2.387l-2.844-3.612
           l-1.406-3.608l2.125-1.805l3.531-5.388l3.531-3.61l2.844-5.998l3.531-6.024l0.719-4.194l3.531-7.803v-5.413l-1.438-4.193
           l-0.688-2.416l-1.406-10.19v-7.22l2.094-8.414l2.844-6.609l5.656-6.607l4.25-3l1.406-6.608l-0.719-7.804l0.719-5.388l4.25-3.025
           l1.406-3.583v-3.609l-0.719-3l-2.094-1.194l-1.438-3.608v-1.805l2.125-4.192l0.719-3.028l-2.125-2.999l-2.844-2.388l-4.219-2.415
           l-2.125-4.192l-0.625-1.807h-0.813l-5.656-1.192l-2.094-4.222l-3.563-4.804c0,0-1.586-0.595-3.531-1.193
           c-0.973-0.302-2.037-0.598-3.031-0.823c-0.994-0.227-1.918-0.371-2.625-0.371c-2.828,0-6.344,0-6.344,0h-4.969l-3.531-1.807
           l-3.531,3.61l-4.937-2.415l-8.5-1.807l-1.406-4.193l-2.844-3.608l-2.813-1.805l-4.25-1.194l-3.531-5.413l2.125-4.805
           c0,0-0.193-1.201-0.813-2.628c-0.31-0.712-0.718-1.472-1.281-2.176s-1.272-1.354-2.156-1.805c-0.884-0.451-1.448-0.806-1.813-1.115
           s-0.519-0.556-0.563-0.743c-0.088-0.375,0.25-0.529,0.25-0.529l-3.531-1.195l-4.938-0.612l-2.125,0.612l-7.094,2.998l-4.219,1.805
           l-4.969,3.584l-4.25-1.195v-4.193v-9.024v-14.41l-2.813-5.389l-2.844-5.414l-0.688-7.219v-4.805v-7.192l-2.125-5.414l-4.938-1.805
           l-3.563,1.805l-9.188,4.22l-6.344,3.001l-7.781,4.191h-11.313l-9.906-1.193l-5.656-4.192l-6.375-2.416l-1.406-5.999l-2.125-4.803
           l2.125-3.611c0,0,0.344-0.904,0.344-1.805c0-0.451-0.078-0.908-0.344-1.246c-0.266-0.339-0.699-0.558-1.406-0.558
           s-1.372-0.097-1.969-0.267s-1.121-0.412-1.563-0.636c-0.884-0.45-1.438-0.903-1.438-0.903l-7.781-1.805H757.393z"
        />
        <path
          onClick={() => setActiveMap("khentii")}
          className={
            activeMap === "khentii" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="khentii"
          stroke="#ffffff"
          stroke-width="3"
          d="
           M1464.986,339.302l-1.188,13.057l-0.719,7.803l0.719,5.415l1.406,6.609l4.25,2.416l4.219,2.388l4.25,5.414l5.656,8.997
           l3.531,12.607l2.844,11.413l0.719,9.607l3.531,7.219l3.531,7.803l2.125,12.607v6.607l-4.25,3.611l-0.719,8.413v2.389l2.125,5.415
           l2.844,4.803l-4.25,5.389l-6.375,5.415l-5.656,2.415l-7.063,0.583l-9.131,1.483l-0.076,10.615l-0.068,2.855l-1.592,7.352
           l-5.137,12.596l2.471,6.021l-1.311,6.533v6.607l4.938,3.584l4.25,3.026l3.531,3.581l2.844,6.608l2.813,3.61l3.531,4.194l1.438,6.61
           l0.688,4.219l4.25,5.997l4.938,6.61l7.781,6.608l5.656,2.388l2.125,3.608l2.125,1.195v2.416l-1.406,4.193v4.221l-2.844,7.804
           l-2.813,4.802l-0.719,7.192v13.829l0.719,4.194l3.531,2.998l4.25,2.415l1.406,3.583l2.125,3.025l0.688,4.805l5.656,7.801
           c0,0,0.023-0.003,1.438,3c0.354,0.751,1.018,1.376,1.813,1.913c0.797,0.534,1.711,0.989,2.594,1.326
           c1.768,0.676,3.375,0.955,3.375,0.955s1.039,0.453,2.719,0.901c0.84,0.228,1.844,0.469,2.938,0.638
           c1.094,0.17,2.295,0.267,3.531,0.267c4.951,0,14.125,0,14.125,0h9.906l8.5-7.804l4.219-5.997l2.844-6.61l2.813-6.608v-4.804
           l-2.125-5.414l-1.406-5.998c0,0-0.334-0.611-0.688-1.513c-0.354-0.902-0.719-2.093-0.719-3.291c0-1.202,0.365-2.391,0.719-3.292
           s0.688-1.514,0.688-1.514l3.531-2.415l5.656-0.583l6.375,1.194l2.844,2.998l6.344,7.805l4.25,8.413l4.938,4.804l2.844,5.414
           l2.813,5.388l4.25,9.024l4.25,6.609l8.469,1.804l2.125,1.805c0,0,2.484,0.454,5.313,0.902c2.828,0.45,6.023,0.877,7.438,0.877
           s4.047-0.595,6.344-1.194c2.299-0.601,4.25-1.195,4.25-1.195l7.781-10.802l6.375-7.83l5.656-5.388l3.531-7.219l3.531-7.192
           l-2.844-7.219l-4.219-4.194l-0.719-3.609l-2.844-6.608v-4.194v-4.22l4.25-10.191l4.969-14.412v-9.608c0,0,0.533-0.452,1.063-1.354
           c0.266-0.451,0.52-1.021,0.719-1.698c0.199-0.674,0.313-1.46,0.313-2.363c0-3.603,0-10.217,0-10.217l0.719-6.608l3.531-10.803
           l4.25-5.389l7.063-9.634l4.969-2.388l7.063-1.807l4.938,0.612l4.25,1.806l4.25,0.582l3.531-2.388v-5.414l-1.406-5.999l-2.844-4.803
           v-9.025c0,0-0.35-1.037-0.438-2.387c-0.043-0.677-0.01-1.419,0.156-2.152c0.166-0.73,0.471-1.47,1-2.068
           c0.531-0.602,1.094-1.066,1.656-1.461c0.564-0.395,1.139-0.704,1.625-0.929c0.973-0.45,1.656-0.612,1.656-0.612l7.781-5.996
           l7.781-4.193c0,0,0.889,0.292,2.125,0.292c0.619,0,1.334-0.067,2.063-0.292c0.73-0.227,1.48-0.62,2.188-1.221
           c1.414-1.202,2.277-1.948,3.25-2.548c0.973-0.602,2.045-1.046,3.813-1.646c0.693-0.236,4.139-1.447,6.375-2.23v-2.574l-7.781-5.414
           l-4.25-5.389l-4.938-6.024l-2.844-7.192l-3.531-11.412l-2.125-4.194l-0.719-3.611l2.125-3.609l2.125-4.193v-6.609v-2.999v-4.804
           l-2.125-4.22l-3.531-3l-2.813-2.998l-5.656-3.61l-9.219-11.997l-4.938-9.608l-3.531-6.608l0.719-4.804l1.406-1.805l0.688-2.415
           l-1.406-5.388l-1.406-4.219l-2.125-5.388l-1.406-6.025l-2.125-6.608l-0.719-7.803v-3.609l-2.125-5.999l-3.969-6.343l-1.688,0.955
           h-8.469h-4.25l-5.656,3.582l-3.531,3.611l-7.781,2.414l-4.25-1.805h-4.219l-4.969,1.805l-4.25,2.389l-4.219,1.804l-3.563,2.39
           l-2.813,6.024l-3.531,4.194l-5.656,1.194l-9.188,1.805l-2.844,1.805l-4.25,2.415l-2.813-2.415l-2.844-3.61h-5.656l-1.406-2.999
           l-2.125-2.389h-4.938l-3.531,1.195l-0.719,2.415l-1.406,2.999l-3.531,1.194l-4.25,1.805l-7.063,2.999l-2.844,0.611l-5.656-1.806
           l-4.25-3.609l-4.219-1.194l-2.844-0.61l-5.656-2.999l-4.25,1.194l-3.531,0.61l-4.938,1.805l-3.531-1.805l-4.969-2.416l-3.531-2.999
           l-9.906-1.804l-11.313-1.806h-12.719l-6.375,0.611l-9.875,2.416l-5.688,1.194h-5.656L1464.986,339.302z"
        />
        <path
          onClick={() => setActiveMap("dornod")}
          className={
            activeMap === "dornod" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="dornod"
          stroke="#ffffff"
          stroke-width="3"
          d="M1705.173,317.193
           l3.969,6.343l2.125,5.999v3.609l0.719,7.803l2.125,6.608l1.406,6.025l2.125,5.388l1.406,4.219l1.406,5.388l-0.688,2.415
           l-1.406,1.805l-0.719,4.804l3.531,6.608l4.938,9.608l9.219,11.997l5.656,3.61l2.813,2.998l3.531,3l2.125,4.22v4.804v2.999v6.609
           l-2.125,4.193l-2.125,3.609l0.719,3.611l2.125,4.194l3.531,11.412l2.844,7.192l4.938,6.024l4.25,5.389l7.781,5.414v4.805
           l0.688,10.802v5.414l1.438,4.193l4.938,1.222l7.063-1.222l4.969-4.806l6.344-1.803h7.063l7.094,3.025l9.188,1.779l12.719,1.221
           l8.5,0.583l7.781,2.417l4.219,5.387l7.781,3.024l4.969,4.804l6.344,0.586l2.844,4.219l3.531,3l7.063,3.61h6.375l4.25,0.582
           l2.813,2.999l2.125,9.025v8.996l-0.719,7.22l0.719,6.607l4.938,7.195l6.375,2.999l6.375,2.997l6.344,2.417v7.803
           c0,0,0.705,0.611,1.5,1.513c0.398,0.45,0.824,0.976,1.188,1.539c0.365,0.563,0.699,1.15,0.875,1.753
           c0.354,1.197,1.037,2.698,1.656,3.899c0.619,1.202,1.156,2.098,1.156,2.098l3.531,3.608l1.406,2.999l7.094,1.222l4.219-3.61
           l3.563-4.803l12-2.999h21.219l10.625-2.414l1.406-5.416l4.938-5.389l5.656-2.415l11.313-1.195l12.75-1.803h6.344l5.656,2.998
           l2.125,5.414l2.844,5.415l4.938,8.997l5.656,6.607l6.375,6l7.781,4.221l3.5,0.981l0.719-1.594l1.438-2.997l3.531-4.805l2.813-3.611
           l3.531-2.413l5.656-4.195l4.25-0.607l7.063,2.413l4.969,0.61l10.594,2.999l2.844-1.804l3.531-4.219l0.688-4.193l2.125-8.415
           l1.438-4.192l2.094-5.414l0.719-4.194l1.406-4.22l1.438-4.194l0.688-2.389h2.844h4.25l1.406,0.582l2.125,1.807l4.219,2.414v3.584
           l2.125,0.611l4.25-1.804l3.531-2.392l5.656-4.221l4.969-7.191l7.781-4.803l2.094-6.025l4.969-2.39l6.344-1.193h8.5l4.25-4.221
           l5.656-4.191l7.781-0.612l4.938-1.194l2.844-1.804l1.406-3.61l0.688-2.999v-2.39l1.438-4.219l3.531,0.611v2.998l1.406,2.415
           l2.844,2.388l2.094,3.611l3.563,2.388l2.125,2.417v2.387l5.656,2.416l5.656,1.194c0,0,9.172,0,12,0s10.625-1.806,10.625-1.806
           h7.781l6.344-2.388l4.969-5.414l5.656-0.611l1.406-2.388l1.406-6v-4.804l-4.938-6.608v-4.803l-0.719-7.805l-4.938-7.218
           l-2.844-2.998l-4.25-5.415l-4.219-6l-4.969-5.414l-5.656-3.584l-4.938-4.22l-4.25-2.388l-7.063-4.221l-3.531-4.193l-4.25-2.416
           l-5.656-5.998l-4.25-2.388l-4.938-4.221l-4.969-2.999l-5.656-4.804l-1.406-3.609l-4.938-4.193l-4.969-1.806l-9.188-4.193
           l-6.375-0.611l-4.938-2.415l-4.25-5.388l-2.813-4.804l-1.406-4.22l-6.375-3.583l-2.125-1.22h-4.938l-5.656,1.804l-4.25,1.221
           h-4.969l-0.688-0.611l-2.125-2.415l-3.531-0.584h-5.656l-3.531,0.584l-6.375,5.415c0,0-1.422,0.594-4.25,1.194
           c-2.828,0.601-4.938,1.22-4.938,1.22l-4.25,4.804l-1.406,5.999l-2.125,7.192l-1.406,4.804l-3.531,6.608l-0.719,4.221l-3.531,5.999
           l-4.25,2.388l-2.125,0.61l-4.25-1.805l-2.125-4.194l-3.531-2.414l-2.125,0.61l-3.531-1.194l-2.125-1.221l-3.531-2.389
           c0,0-0.703-2.416-3.531-2.416s-12-1.195-12-1.195h-8.5l-7.063,2.999l-5.656,5.415l-7.781,1.804l-2.844,1.805h-4.938l-1.406-1.221
           l-4.969-1.777l-4.938,1.193l-6.375,1.805l-5.656,6.61l-0.688,4.192l-1.438,5.414h-3.531h-2.125l-3.531-1.803l-1.406-0.612
           c0,0-0.727-1.79-4.969-4.192c-4.242-2.403-5.656-5.415-5.656-5.415l-3.531-2.999l-2.813-4.194l-2.844-4.804l-3.531-4.804
           l-2.125-4.831v-5.388l2.125-6.608l6.375-4.804l4.938-3.61l3.531-2.389l2.125-3l1.438-7.219l-1.438-3.609l-2.125-1.805l-3.531-5.388
           v-6.609l-0.688-9.608l5.656-4.802l3.531-3l2.125-2.415l2.125-7.192v-3.61v-5.415l-3.563-4.193v-4.805l2.844-5.413v-6l3.531-6.608
           l2.844-7.22l2.094-7.803l1.438-5.389l4.219-12.633l3.563-10.802v-6.608l2.813-10.192l2.125-6.024v-4.194l2.125-9.023l1.406-4.194
           l1.406-3.609v-3.582v-3.026l-3.531-4.194l-2.125-2.999l-3.531-1.805v-4.194l-4.938-2.415l-3.531-0.61l-9.906,0.61h-6.375l-8.469,3
           l-7.781,3.609l-4.25,4.193l-6.375,1.806l-2.094,3.608l-5.656,4.194l-4.969-1.194l-4.938-1.805l-5.656-1.195l-7.094-0.61
           l-5.656-2.999l-5.656-3.583l-6.344-4.22l-5.656-2.999l-4.25-3l-4.25-2.998l-7.063-2.415h-15.563h-12.719l-12.719,1.804
           l-7.781,4.805l-2.844,4.192l-4.25,4.832l-8.469,7.192c0,0-0.703,0.598-3.531,3s-8.5,6.608-8.5,6.608l-2.813,3.61l-4.969,5.999
           l-4.25,1.805l-4.219,4.193l-1.438,3.61l-6.344,2.415l-5.656,4.804l-2.125,4.804l-2.125,5.999l-0.719,4.805v7.802l-2.094,6.608
           l-3.563,2.416l-4.938,2.388l-7.781,4.194l-7.781,3.609h-3.531h-6.375l-8.469,1.194l-7.781,1.22l-7.063,3l-6.375,4.193l-5.656,7.22
           L1705.173,317.193z"
        />
        <path
          onClick={() => setActiveMap("darkhan-uul")}
          className={
            activeMap === "darkhan-uul" || activeMap === "all"
              ? "active"
              : "inactive"
          }
          id="darkhan-uul"
          stroke="#ffffff"
          stroke-width="3"
          d="M1273.578,319.938
           l0.707-10.209v-3.604l2.121-7.207v-3.603l4.242-3.002l4.244-4.805c0,0,4.242-1.801,4.242-4.204c0-2.402,1.414-7.808,1.414-7.808
           v-6.004l2.828-2.402l0.707-0.601h1.414l0.707,3.003l4.242,3.602l0.709,7.808l1.414,8.407l0.707,5.405v7.807l4.949,3.603
           l9.191,3.003h7.779l6.363,2.402c0,0,3.535,4.203,4.242,6.606c0.707,2.402,3.537,13.212,3.537,13.212v12.013v7.206l-3.537,5.405
           l-3.535,5.404l-6.363,3.003l-7.07,1.802h-17.678l-9.193,0.601l-3.535,1.802h-5.656l-7.072-1.802l-2.121-2.403l-4.242-3.603
           l-2.828-6.606l-2.121-6.005l0.707-5.405l2.828-7.807l4.242-6.606l0.707-4.204L1273.578,319.938z"
        />
        <path
          onClick={() => setActiveMap("uvs")}
          className={
            activeMap === "uvs" || activeMap === "all" ? "active" : "inactive"
          }
          id="uvs"
          stroke="#ffffff"
          stroke-width="3"
          d="M408.08,408.599
           l5.656-3.371l4.938-4.803l4.25-1.221l4.25-3.609l2.125-5.388v-4.22l2.813-4.193h3.531h3.563l4.219,1.194l0.719,1.22l1.406,1.194
           h2.125l2.125-1.194l3.531-3.61l4.25-2.414l4.25-1.806l7.781-1.777l3.531,0.583l2.125,3l7.063,1.805l3.531,1.194l7.063,0.611
           l3.531,1.194l2.844,2.414l4.25,4.195l3.531,2.414l2.813,2.389l3.531,1.195l2.844-1.195c0,0,0.188-0.451,0.719-0.901
           c0.266-0.226,0.599-0.442,1.063-0.611s1.043-0.292,1.75-0.292c1.414,0,2.48-0.284,3.188-0.584c0.707-0.299,1.063-0.61,1.063-0.61
           l4.25-2.998c0,0,0.703,0,3.531,0c0.707,0,1.286-0.146,1.75-0.373c0.464-0.225,0.797-0.522,1.063-0.823
           c0.53-0.601,0.719-1.221,0.719-1.221l3.531-2.388l3.531-1.805l2.844-1.195l3.531-1.193l7.063-0.611l3.531-0.61v-3v-3.61
           l0.719-3.583l2.125-4.22v-5.999v-4.804l2.813-5.999l0.719-1.805l2.813-4.804l3.563-2.415l0.688-1.806l3.531-3.583v-3.024
           l-1.406-3.584l-2.813-1.222v-2.999l-1.438-2.999l-2.813-5.388l-1.406-3.61l-2.844-6.608l-0.719-4.22l-2.813-1.194l-1.406-3.609
           v-2.389l2.813-4.803v-5.415l4.25-3.583v-7.218l1.406-6l2.125-7.218l0.875-1.805h-0.875l-2.125-2.389l-3.531-2.999l-0.719-0.611
           l-4.219-2.389l-2.844-2.415l-2.813-0.61l-4.969-1.778h-4.938l-3.531,0.583l-3.531-2.388l-0.719-2.416l-3.531-3.61l-6.375-4.804
           l-6.375,0.611h-4.219l-4.25-1.806l-2.844-2.999l-2.813-6.609l-1.406-5.998l-3.531-3.609l-2.125-3v-2.999l0.688-3.609l2.125-4.805
           l1.406-5.415l2.125-5.998v-3.609l1.438-2.389l-1.438-3l-2.125-1.804l-6.344-1.221l-4.25-1.778l-4.25-1.806l-5.656-2.416
           l-6.344-2.388h-12.031c0,0-4.953,0-7.781,0s-4.246-0.604-7.781-1.805c-3.535-1.201-4.938-1.195-4.938-1.195l-2.844-3.025
           l-5.656-3.583l-3.531,1.194l0.719,2.389v3.025v3l-4.969,1.193l-5.656,0.611l-3.531-1.221l-6.375-3.583l-4.219-1.22l-0.719-4.194
           l0.719-6.608l1.406-3l2.813-5.415l0.719-3.609l-4.25-1.779l-2.125-3.025l-3.531-0.583l-2.125-2.416h-3.531l-1.406,6.024l-0.719,3
           l-2.813,3.582l-2.125-1.194l-1.406-3.609l-3.531-4.194l-2.125-0.61l-2.844-2.999l-3.531-2.389v-3.609l-3.531-3l-0.719-2.415
           l-4.219,0.61l-3.563,2.415l-2.813,3.584v2.999v3.61l0.719,4.22l-4.969,3l-2.125-1.805l-2.813-1.195l-4.969-1.804l-8.469-1.221
           l-7.063,0.61l-5.656,2.415l-7.094,7.193l-2.125,1.804l-4.219,3h-2.844l-7.063,1.804l-2.844,2.999h-6.344l-5.656,3l-7.781,1.221
           l-9.188,0.583l-7.094-1.194l-4.938,1.805l-2.844,4.194l-1.406,4.219l-0.688,3.583l-3.563,1.222l-2.094-3.026l-4.25-2.39
           l-4.25-1.804l-3.531-0.61l-3.531-3l-3.531,1.22l-3.563,5.388l-2.094,3.61l-4.25,5.414l-2.844,3.584l-2.813,1.22l-3.531-3.609
           l-3.531-1.195l-3.563-2.416l-3.531,1.196v4.22v2.999l-0.688,2.39l-0.188,0.026l0.875,0.583l3.531,4.194v3.609l0.719,2.416
           l2.844,2.999v5.388l-0.719,7.219v7.22l1.406,5.387v4.804l0.719,4.804l0.688,4.22l5.656,3.583l0.719,3.026l6.375,5.998l2.813,3
           l2.125,2.388l4.25,3.026l0.688,3.583l1.438,4.803l3.531,4.804l1.406,5.414l3.531,7.22v5.388l1.438,2.998l2.094,4.221l2.125,8.414
           l1.438,5.998l4.219,5.388l2.125,1.806l5.656,0.61l1.406,1.804l2.125,2.388v4.804v4.804l1.438,6.025l2.094,3.583l0.188,7.298
           c0.125-0.01,1.25-0.08,1.25-0.08l4.25-1.805l3.531-1.194l4.219-5.998l3.563-4.22l7.063-3.61l4.25-2.999l4.219-1.194l2.844-1.194
           h2.813l2.125,3v5.388v4.219v6.609v4.193l1.438,1.194l6.344,0.61l3.531,2.416h3.531l0.719,2.998l-0.719,2.39l-0.688,4.219
           l0.688,3.583l1.438,1.222l4.219,1.194h6.375l5.656,2.388h4.25l5.656,1.805l2.813,0.612l1.438,2.387l4.219,3.026l2.844,2.999
           l1.406,1.805l3.531,1.194l0.719-2.999l2.813-4.219l2.125-4.193l0.719-1.806l3.531-0.611l2.844,3.026l5.656,2.388l3.531,2.416
           l0.688,4.803l2.125,4.804v4.804l2.125,2.389l4.25,2.415l3.531,3.583l2.844,2.416l3.531,1.804l1.406,1.805V408.599z"
        />
        <path
          id="mainborder"
          fill="none"
          stroke="#ffffff"
          stroke-width="3"
          d="M820.33,20.252
           l-2.844,0.611l-2.125,1.804l-2.813,3l-0.719,3v4.804l-1.406,3.609l-3.531,1.804l-4.969,4.804v4.804v2.999l-3.531,1.806
           l-3.531,2.388l-7.063,1.805l-1.406,2.999l-2.844,5.415l-2.813,4.804L775.08,71.9l-4.25,0.61l-1.406,2.39v4.221l2.094,2.388v2.999
           l-1.406,4.222l-4.938,3.608h-4.969l-5.656,1.195l-4.938,2.389l-4.25,3.025l-0.688,2.999l-0.719,4.804v5.999v5.414v4.804
           l-1.406,7.804l-1.438,5.998l-4.938,3.61v4.193l0.719,3.61h4.938l2.125,2.415l2.813,3.584v2.999v1.805l-0.688,3.61l-1.406,4.804
           l-0.719,5.414l-0.719,1.804v4.804l0.719,3.584l2.813,2.415l0.719,3.609l3.531,2.389l5.656,3.609l2.125,1.806l4.969,0.584
           l0.688,2.415l2.844,2.999l0.688,5.999v4.219v3.611l-3.531,5.387l-2.813,3.609l-2.125,4.193l-2.125,6.025l-3.531,5.388l-1.406,4.22
           l-4.25,7.192l-4.969,3.61l-4.219,0.61l-5.656,6.582v2.416v3.609l-2.125,0.584l-1.438-1.194l-2.094-2.389l-3.563-0.61h-4.219
           l-6.375,1.194l-2.125,3.61l-4.938,3.609l-3.531,4.193l-7.094,4.804l-7.781,1.194h-5.656l-4.938-3.582l-0.469-1.407l-0.938-2.814
           l-1.406-4.193l-1.438-5.999l-4.938-1.221l-6.375-1.805c0,0-1.422-0.583-4.25-0.583s-8.469-0.61-8.469-0.61h-3.531l-4.969-2.389
           l-0.688-2.999l-2.125-1.805h-3.531l-0.719,1.805l-1.406,3.583l-2.844,1.22l-2.125-1.805l-2.813-0.61l-5.656-4.193l-1.406-2.416
           l-1.438-2.388l-4.219-1.805l-4.969-2.415h-3.531l-3.531-2.39l-2.844-0.61l-2.094,0.61l-4.969,0.584l-3.531,3.025l-2.125,1.195
           l-3.531,1.193l-0.719,3h-2.813l-3.531,3h-3.375h-0.875l-2.125-2.389l-3.531-2.999l-0.719-0.611l-4.219-2.389l-2.844-2.415
           l-2.813-0.61l-4.969-1.778h-4.938l-3.531,0.583l-3.531-2.388l-0.719-2.416l-3.531-3.61l-6.375-4.804l-6.375,0.611h-4.219
           l-4.25-1.806l-2.844-2.999l-2.813-6.609l-1.406-5.998l-3.531-3.609l-2.125-3v-2.999l0.688-3.609l2.125-4.805l1.406-5.415
           l2.125-5.998v-3.609l1.438-2.389l-1.438-3l-2.125-1.804l-6.344-1.221l-4.25-1.778l-4.25-1.806l-5.656-2.416l-6.344-2.388h-12.031
           c0,0-4.953,0-7.781,0s-4.246-0.604-7.781-1.805c-3.535-1.201-4.938-1.195-4.938-1.195l-2.844-3.025l-5.656-3.583l-3.531,1.194
           l0.719,2.389v3.025v3l-4.969,1.193l-5.656,0.611l-3.531-1.221l-6.375-3.583l-4.219-1.22l-0.719-4.194l0.719-6.608l1.406-3
           l2.813-5.415l0.719-3.609l-4.25-1.779l-2.125-3.025l-3.531-0.583l-2.125-2.416h-3.531l-1.406,6.024l-0.719,3l-2.813,3.582
           l-2.125-1.194l-1.406-3.609l-3.531-4.194l-2.125-0.61l-2.844-2.999l-3.531-2.389v-3.609l-3.531-3l-0.719-2.415l-4.219,0.61
           l-3.563,2.415l-2.813,3.584v2.999v3.61l0.719,4.22l-4.969,3l-2.125-1.805l-2.813-1.195l-4.969-1.804l-8.469-1.221l-7.063,0.61
           l-5.656,2.415l-7.094,7.193l-2.125,1.804l-4.219,3h-2.844l-7.063,1.804l-2.844,2.999h-6.344l-5.656,3l-7.781,1.221l-9.188,0.583
           l-7.094-1.194l-4.938,1.805l-2.844,4.194l-1.406,4.219l-0.688,3.583l-3.563,1.222l-2.094-3.026l-4.25-2.39l-4.25-1.804l-3.531-0.61
           l-3.531-3l-3.531,1.22l-3.563,5.388l-2.094,3.61l-4.25,5.414l-2.844,3.584l-2.813,1.22l-3.531-3.609l-3.531-1.195l-3.563-2.416
           l-3.531,1.196v4.22v2.999l-0.688,2.39l-0.188,0.026l-6.906,1.193l-5.656,3.584l-8.469,1.22l-5.656,3.583l-4.25,5.414v10.219v4.193
           l-3.531,6.608l-3.531,4.194l-2.844-0.584l-2.125-1.193l-4.219-1.806l-1.438,1.194l-4.938,2.999l-4.938,2.416l-2.125,3.583h-7.094
           l-6.344,0.611l-4.25,1.193l-2.844,2.416v2.999v3l-2.094,1.804l-4.25-3l-5.656-4.803l-3.531-3.609l-7.781-4.195l-4.25-0.61h-8.5
           l-8.469,1.194l-8.5,2.999l-3.531,5.414l-4.938,4.805l-2.125,5.414v5.388l-2.125,1.804H45.33l-7.063,2.416l-3.531,4.193v5.414v3.583
           l-2.125,4.22l-3.531,2.389l-7.375,1.406l-2.719,3.849l-2.719,3.849v2.309l3.625,2.282v2.309l2.719,4.618l2.719,1.54l5.406,0.769
           l2.719,1.54l3.625,3.849l0.906,1.539v3.052l-2.719,2.308l-2.719,2.31l-0.906,3.079l-0.906,1.539v3.08l0.906,3.822l4.531,3.848
           l2.719,3.849l3.625,3.079l2.719,3.052l3.625,1.54l4.5,1.539l0.906,2.309l3.625,1.54l1.813,1.539l1.813,1.539l2.719,2.309
           l1.813,3.079l1.813,7.67v4.618l-0.906,5.362v1.54v1.539l1.813,1.539l3.594,3.079l0.906,2.309l1.813,1.539v2.31l0.906,3.052
           l1.813,1.54l0.906,0.769l1.813,1.539h2.719l3.625,2.311l0.906,2.308l2.688,3.079l2.719,3.849l0.906,2.282l3.625,1.539h3.625
           l1.813,1.539l5.406-2.309l1.813-4.591l3.625-3.08l0.906,0.77l3.625,0.77l0.906,2.309l5.438,2.283l0.906,1.539l2.688,3.849
           l4.531,3.078l2.719,5.389l2.719,4.591l3.625,3.079l2.719,3.848v1.54l3.594,0.771l1.813-2.31l1.813-1.539l2.719-3.849h1.813
           l3.625,0.77l0.906,3.849v4.617c0,0,0.262,1.396,0.969,5.601c0.464,2.758,0.607,3.459,0.656,3.609
           c0.003,0.002,0.027,0.023,0.031,0.026l5.656,4.778l4.969,0.609l2.813,2.389l2.125,1.806l3.531,8.414v6.608l0.719,8.413l2.125,6.582
           v3.611l-0.719,6.607l-2.125,7.804v4.22l2.844,4.805l1.406,5.998l2.813,5.413l5.656,7.804l4.25,3l4.25,4.803l4.25,4.805l1.406,5.414
           l1.406,10.801l3.531,5.415l-0.25,0.637l-1.156,2.947l-0.688,6.023v8.998l-4.969,6.61l-2.125,4.192l-4.219,7.22l-3.563,8.412
           c0,0,1.422,1.791-1.406,4.192c-2.829,2.403-2.813,5.416-2.813,5.416l-1.438,4.194v1.193l2.844,1.805l2.813,0.612l2.844,4.192v7.218
           l-1.406,7.804l-4.969,7.193l-2.813,4.22l-5.656,5.999l-7.094,9.607l-6.344,5.413l-3.531,5.388l-1.438,6.611l-4.938,7.215v2.391
           v1.221l0.719,7.193v1.194l1.406,3.608l2.125,1.806l1.406,4.805v5.413l-1.406,4.193v4.806l0.688,2.415v0.583l2.844,1.195l4.25,0.609
           l2.813-1.195l4.25,0.586h4.25l2.813,3.609l6.375,0.61l0.688,1.804l3.563,5.388l4.938,1.221l4.938,1.196l7.094,2.387l4.938,1.222
           l0.719,2.388l0.688,1.195l1.438,4.22l4.219,1.804h4.25l5.656,0.586h7.781h4.25l7.063,0.609l6.375,1.195l3.531,1.193l4.25,3.024
           l2.813,1.808l4.25,1.191l4.938,2.391l4.25,0.609h4.938l2.125-1.804l2.125-1.196l2.844-1.191h2.125l2.094,1.191l2.844,2.391
           l5.656,1.221l2.844,2.392l5.656,0.607l2.813,1.807l6.375,2.999l4.938,0.583l6.375,1.22l2.125,1.195l6.344,1.806h2.906h2.75
           l6.375,1.804l2.813,3.584l4.969,2.415l5.656,1.804l4.25,4.805l3.531,2.999l4.938,2.388l5.656,6.026l5.656,4.801l2.844,3.002
           l4.938,4.191l2.844,3.612l2.094,2.997l4.25,4.219l4.25,4.194l2.844,3.61l4.938,4.193l2.813,3.608l1.438,2.39l4.938,6.024
           l3.531,1.805l3.531,3.001l2.844,2.387l2.813,3.61l2.844,4.805l3.531,0.61l3.531,0.583l5.656,2.416l2.844,1.804l4.938,3.584
           l4.25,1.221l4.938,1.192h5.656l4.969,1.195l3.531-0.609l2.125,1.222l0.688,1.194l2.125,1.193v3.001l-0.688,3.023v5.388
           l-2.125,3.611l-0.719,4.805v4.191l3.531,2.998l2.844,2.416l7.063,3.609l2.813,4.192l2.125,7.22l2.125,4.804l0.719,3.583
           l1.406,4.806v4.83v5.997v5.389l1.406,4.803v4.805l1.438,4.221l2.094,3.609l0.719,4.805l3.531,0.583l0.719,4.805l1.406,2.416
           l2.844,4.193l1.406,0.607v3.001v3.608l-2.844,2.391v1.804l3.563,2.999l3.531,4.806l4.219,4.219l3.563,4.193l3.531,3.609
           l2.813,2.389l2.844,3.609l2.125,1.806v4.805l0.688,2.388l2.125,3.027l3.531,5.385l3.531,3.61l5.656,0.611l11.313,4.193h8.5
           l11.313,1.193l10.594,1.805h5.656l9.219,2.999l9.875,2.416l3.563,1.807c0,0,2.828,4.19,5.656,4.19s5.656,1.195,5.656,1.195
           l4.938,1.222l5.656,2.388h4.938l7.094,1.806l2.813,1.192l1.406,0.611l7.094,1.805l4.219,2.388l7.094,4.22h5.656l4.25,3.001
           l4.938,0.584l7.781,1.221l9.188,1.806l6.375,0.583l4.938,1.805l3.531,2.416l4.25,2.389l7.781,0.61l4.938,1.195l3.531,0.608
           l6.375,1.195h6.375h8.469l7.063-1.195l5.656-2.414h7.781h0.719l7.781-1.805l5.656-1.805l4.938-1.807l4.969-1.776h4.938l4.25,2.388
           l4.25,1.195l2.813,2.414l4.25,1.195l4.25,1.806l3.531-1.195l1.406-3.609l2.844-4.193l2.094-1.806h4.25h5.656l5.656,0.584
           l4.969,2.998l3.531,0.611l2.813,1.806l3.531,1.194l4.969,1.805h4.25l4.938,0.61l1.406,2.999l2.843,1.196l3.531,0.607l0.688-1.804
           l2.125-1.804l2.125-1.806l2.844-0.611l2.125,1.222l3.531,1.804l5.656,1.807l4.25,1.777l7.75,1.221l5.656,1.805l4.25,3.582
           l3.531,0.611l4.969,1.804l4.25,2.391l6.344,6.022l3.531,4.807l2.844,2.997l4.938,4.194l2.125,3.609l3.531,4.804l3.531,1.195
           l5.656,3.61l4.25,1.191l1.406,3.002l0.719,4.218h5.656l7.063,1.805c0,0,3.547,0.583,6.375,0.583s8.5,0,8.5,0h7.063h5.656
           l7.781,2.416l7.063,3.61l4.25,1.778l3.531,3.611l5.656,2.414l2.125,4.194h4.938l6.375,2.412h4.938l4.969,1.78l3.531,3.609
           l2.125,1.806l3.531,3.609l2.813,1.803l7.781,2.39l8.5,0.611l10.594,1.193c0,0,2.133,0,6.375,0s11.313-2.999,11.313-2.999
           l4.938-4.193l5.656-1.222l4.969-1.192h5.656h4.938l3.531,2.999v5.414v7.193v5.413l3.531,2.999l4.969,0.609l6.344,0.587h4.969
           l4.938,3.024l6.375,0.583l2.813,2.416l2.125,1.806l6.375,0.583l2.813-5.996l1.438-2.416l4.219-4.805l3.531-3l6.375-1.196
           l2.844-2.998l5.656-1.804l4.938-1.194l4.25-2.416l5.656-1.805l7.063-2.998l4.25-4.805l5.656-3.609l2.125-4.804l2.813-4.803
           l4.969-3.585l3.531-1.804l6.344-0.61l2.125-4.195l7.094-0.608l0.688,1.804l0.719,1.804v2.39h3.531l2.813-2.39l0.719-2.999
           l4.25-2.999l2.813-4.801l2.125-6.61l3.531-2.415l4.25-5.39l4.25-0.609h5.656l6.375,1.806l4.938-0.611l7.063-1.806l9.906-1.803
           l8.5-1.195l7.75-1.805l4.969-1.805l1.406-5.388l3.531-4.221l2.125-4.803l6.375-0.584l4.25-2.416h4.219l2.125,1.195l3.531,1.805
           l1.438,1.222l2.813,3.581h3.531l5.656,0.61h3.531h4.969h2.813l5.656-0.61l5.656-3.581l2.844-1.806l9.188-2.416h5.656l8.5,0.609
           l4.219,1.807l6.375,1.192l5.656,0.613l7.063,1.192l6.375,1.805h4.25l6.344,1.804l4.25-1.22l2.125-3.581l4.25-3.612h4.938h4.969
           l4.219,0.586l4.25,1.221h4.25l4.25-2.999l4.219-4.805h4.969l4.219-4.218l3.563-3l6.344-4.805l1.438-3.61l3.531-2.999l4.219-1.804
           c0,0,0.734-1.806,3.563-1.806s5.656-1.194,5.656-1.194l4.219-1.195h4.969l3.531-1.194l5.656-1.804l2.125-4.222l4.25-4.804h2.813
           l4.25-0.582l4.25-1.222l1.406-2.999l2.125-4.805l2.125-2.999c3.535-1.2,5.656-2.388,5.656-2.388l2.125-3.025l3.531-0.585v-4.22
           l2.125-3.584l0.688-5.414v-3.61v-2.999l2.844-3.608l2.813-2.39l1.406-1.193l1.438-3.609v-2.414l2.813-3.585l2.844-4.219
           l2.125-0.586l1.406-3.607l3.531-6.023l4.938-4.195l3.563-4.805l4.219-1.803l3.531-0.585l2.125-1.805l5.656-1.804h4.969l3.531-1.196
           l4.25-2.414l2.813-2.39l6.375-2.414l4.25-1.807l2.813-2.998l2.125-1.194l1.406-3.609v-1.194l3.531-3.611l2.844-1.805l4.938-1.803
           l3.531-5.388v-4.22v-4.194l-1.406-7.219l-1.406-7.804l-0.719-4.194l-5.656-6.024l-2.125-2.999l-5.656-4.193h-0.688l-6.375-2.417
           l-3.531-2.997l-3.531-5.996l-4.969-7.223l-4.219-5.386l-2.844-4.219l-4.25-1.196l-1.406-1.805l-0.719-2.388l-0.688-3.612v-2.998
           l0.688-5.414v-4.806l2.844-4.192l1.406-3.609l2.125-3.607l0.719-5.999l0.688-3.61l2.125-5.39l2.125-6.022v-2.999l2.844-4.193
           l3.531-6.026l0.688-2.998v-3.583l3.563-5.415l4.219-5.998l1.594-1.353l1.938-1.646l4.969-4.223l7.063-2.997l7.063-2.998
           l9.906-0.613l6.375,0.613l6.344,2.998l4.25,2.997l6.375,5.416l3.531,3.61l4.25,5.997l4.25,1.804l4.938,3.585l4.938,2.417
           l12.75,0.607l9.188,1.195l7.063,0.583l8.5-0.583l4.219-0.611l6.375-1.191l6.375-1.807l7.063-0.61l4.938-4.804l2.844-2.999
           l4.938-1.804l5.656-4.806l2.844-4.805l4.219-1.775l2.125-3.028l3.531-4.193l2.125-1.191l1.438-4.223l1.406-6.607l2.813-5.39
           l1.438-2.998l4.219-3.61l2.844-0.61l6.375-4.803l2.813-0.585l1.406-3.026v-1.193v-6.609l-0.688-3.582l-0.719-4.221l-2.813-2.998
           v-3.609l0.688-2.389l4.25-1.221l4.938-3.584l0.719-1.221l2.813,0.611l0.719,1.193l4.25,4.221c0,0,2.828,0,5.656,0s9.188,0,9.188,0
           h9.906l8.469-2.416l4.25-0.583l9.906-1.222l4.938-4.193l5.656-1.193c0,0,3.547-1.816,6.375-2.418
           c2.828-0.599,6.375-2.388,6.375-2.388l3.531-4.802l5.656-1.807l2.813-7.802l3.531-1.222l2.125-1.194l1.438-3.61l3.531-4.804
           l4.938-3.583l4.25-2.415l4.25-3.607l3.531-3l1.406-1.806v-3.583l-0.719-1.805l-3.531-2.414v-3.612v-2.388v-3.608l2.125-6
           l2.844-4.803l0.688-5.997l1.438-6.611v-6.023l2.813-4.804l4.938-4.194l1.406-3.211l0.719-1.594l1.438-2.997l3.531-4.805
           l2.813-3.611l3.531-2.413l5.656-4.195l4.25-0.607l7.063,2.413l4.969,0.61l10.594,2.999l2.844-1.804l3.531-4.219l0.688-4.193
           l2.125-8.415l1.438-4.192l2.094-5.414l0.719-4.194l1.406-4.22l1.438-4.194l0.688-2.389h2.844h4.25l1.406,0.582l2.125,1.807
           l4.219,2.414v3.584l2.125,0.611l4.25-1.804l3.531-2.392l5.656-4.221l4.969-7.191l7.781-4.803l2.094-6.025l4.969-2.39l6.344-1.193
           h8.5l4.25-4.221l5.656-4.191l7.781-0.612l4.938-1.194l2.844-1.804l1.406-3.61l0.688-2.999v-2.39l1.438-4.219l3.531,0.611v2.998
           l1.406,2.415l2.844,2.388l2.094,3.611l3.563,2.388l2.125,2.417v2.387l5.656,2.416l5.656,1.194c0,0,9.172,0,12,0
           s10.625-1.806,10.625-1.806h7.781l6.344-2.388l4.969-5.414l5.656-0.611l1.406-2.388l1.406-6v-4.804l-4.938-6.608v-4.803
           l-0.719-7.805l-4.938-7.218l-2.844-2.998l-4.25-5.415l-4.219-6l-4.969-5.414l-5.656-3.584l-4.938-4.22l-4.25-2.388l-7.063-4.221
           l-3.531-4.193l-4.25-2.416l-5.656-5.998l-4.25-2.388l-4.938-4.221l-4.969-2.999l-5.656-4.804l-1.406-3.609l-4.938-4.193
           l-4.969-1.806l-9.188-4.193l-6.375-0.611l-4.938-2.415l-4.25-5.388l-2.813-4.804l-1.406-4.22l-6.375-3.583l-2.125-1.22h-4.938
           l-5.656,1.804l-4.25,1.221h-4.969l-0.688-0.611l-2.125-2.415l-3.531-0.584h-5.656l-3.531,0.584l-6.375,5.415
           c0,0-1.422,0.594-4.25,1.194c-2.828,0.601-4.938,1.22-4.938,1.22l-4.25,4.804l-1.406,5.999l-2.125,7.192l-1.406,4.804l-3.531,6.608
           l-0.719,4.221l-3.531,5.999l-4.25,2.388l-2.125,0.61l-4.25-1.805l-2.125-4.194l-3.531-2.414l-2.125,0.61l-3.531-1.194l-2.125-1.221
           l-3.531-2.389c0,0-0.703-2.416-3.531-2.416s-12-1.195-12-1.195h-8.5l-7.063,2.999l-5.656,5.415l-7.781,1.804l-2.844,1.805h-4.938
           l-1.406-1.221l-4.969-1.777l-4.938,1.193l-6.375,1.805l-5.656,6.61l-0.688,4.192l-1.438,5.414h-3.531h-2.125l-3.531-1.803
           l-1.406-0.612c0,0-0.727-1.79-4.969-4.192c-4.242-2.403-5.656-5.415-5.656-5.415l-3.531-2.999l-2.813-4.194l-2.844-4.804
           l-3.531-4.804l-2.125-4.831v-5.388l2.125-6.608l6.375-4.804l4.938-3.61l3.531-2.389l2.125-3l1.438-7.219l-1.438-3.609l-2.125-1.805
           l-3.531-5.388v-6.609l-0.688-9.608l5.656-4.802l3.531-3l2.125-2.415l2.125-7.192v-3.61v-5.415l-3.563-4.193v-4.805l2.844-5.413v-6
           l3.531-6.608l2.844-7.22l2.094-7.803l1.438-5.389l4.219-12.633l3.563-10.802v-6.608l2.813-10.192l2.125-6.024v-4.194l2.125-9.023
           l1.406-4.194l1.406-3.609v-3.582v-3.026l-3.531-4.194l-2.125-2.999l-3.531-1.805v-4.194l-4.938-2.415l-3.531-0.61l-9.906,0.61
           h-6.375l-8.469,3l-7.781,3.609l-4.25,4.193l-6.375,1.806l-2.094,3.608l-5.656,4.194l-4.969-1.194l-4.938-1.805l-5.656-1.195
           l-7.094-0.61l-5.656-2.999l-5.656-3.583l-6.344-4.22l-5.656-2.999l-4.25-3l-4.25-2.998l-7.063-2.415h-15.563h-12.719l-12.719,1.804
           l-7.781,4.805l-2.844,4.192l-4.25,4.832l-8.469,7.192c0,0-0.703,0.598-3.531,3s-8.5,6.608-8.5,6.608l-2.813,3.61l-4.969,5.999
           l-4.25,1.805l-4.219,4.193l-1.438,3.61l-6.344,2.415l-5.656,4.804l-2.125,4.804l-2.125,5.999l-0.719,4.805v7.802l-2.094,6.608
           l-3.563,2.416l-4.938,2.388l-7.781,4.194l-7.781,3.609h-3.531h-6.375l-8.469,1.194l-7.781,1.22l-7.063,3l-6.375,4.193l-5.656,7.22
           l-4.688,2.655l-1.688,0.955h-8.469h-4.25l-5.656,3.582l-3.531,3.611l-7.781,2.414l-4.25-1.805h-4.219l-4.969,1.805l-4.25,2.389
           l-4.219,1.804l-3.563,2.39l-2.813,6.024l-3.531,4.194l-5.656,1.194l-9.188,1.805l-2.844,1.805l-4.25,2.415l-2.813-2.415
           l-2.844-3.61h-5.656l-1.406-2.999l-2.125-2.389h-4.938l-3.531,1.195l-0.719,2.415l-1.406,2.999l-3.531,1.194l-4.25,1.805
           l-7.063,2.999l-2.844,0.611l-5.656-1.806l-4.25-3.609l-4.219-1.194l-2.844-0.61l-5.656-2.999l-4.25,1.194l-3.531,0.61l-4.938,1.805
           l-3.531-1.805l-4.969-2.416l-3.531-2.999l-9.906-1.804l-11.313-1.806h-12.719l-6.375,0.611l-9.875,2.416l-5.688,1.194h-5.656
           l-3.031-1.061l-0.656-0.212l-3.375-1.142l-3.531-3.609l-2.125-4.193l-0.688-3l-2.125-4.193l-4.969-3.025l-5.656-1.778l-5.656-3.026
           l-4.25-0.583l-2.813-4.22l-1.406-3.584c0,0-2.824-2.4-3.531-4.803c-0.707-2.402-1.438-8.414-1.438-8.414l0.719-5.998v-4.804
           l2.125-2.416v-3.61l-4.25-3.582l-2.813-3.611h-3.563l-5.656-0.61l-4.938,0.61l-4.25,2.39l-4.938,1.221h-4.25l-2.813,3.582
           l-2.844,1.805l-2.125-2.388l-2.125-3l-1.406-4.831l-5.656-2.389h-3.531l-3.531-3.609l-3.531-4.193l-2.125-1.805l-4.969-4.804
           l-2.813-4.804l-2.844-3l-5.656-6.024l-4.938-2.999l-6.375-1.804h-4.938h-5.656l-4.25,3.025l-4.25,1.778l-5.656,0.611l-5.656-1.195
           l-4.938-4.804l-6.375-1.804l-5.656-2.417l-7.781,0.61l-6.344-1.804l-4.25-1.194l-7.781-1.194l-7.063-1.221h-4.25l-4.938-0.584
           l-4.25-2.999h-2.125l-4.25,1.194l-2.125,3.61v4.192l-4.938,1.806c0,0-2.141,1.193-4.969,1.193s-6.344,0-6.344,0l-4.25,1.805
           l-3.531,5.414l-1.406,1.806l-2.844,1.194l-4.25-1.804l-2.813-1.805l-5.656,0.61l-7.063,3l-6.375,2.999l-3.938,3.822l-1,0.981
           l-4.25,3.61l-5.656,3l-5.656,0.61h-4.25h-2.813l-4.25-5.414l-3.531-0.611l-2.844,0.611l-5.656,2.998l-2.125,3.001h-4.938h-2.844
           h-4.219l-4.969-3.001l-4.938-4.192l-2.844-3.609l-2.125-3.61l-3.531-3.609l-4.938-1.194l-3.531,1.194l-5.656,2.415l-2.844,0.611
           l-3.531-1.805l-2.813-3.61l-2.125-2.415h-4.969l-2.813-2.999l-2.125-1.193h-4.25l-4.813-3.159l-1.563-1.036l-2.813-3.025l-2.125-3
           l-2.813-4.193l-2.844-1.805l-3.531-2.999l-2.125-5.999v-7.829v-8.388l-1.406-6.024l-2.125-4.804l-0.719-9.609l-1.406-5.387v-4.805
           l-4.25-7.219v-4.803l2.844-7.803l0.688-9.608v-5.999l-2.813-2.415l-3.531-7.803l-7.781-3.611l-6.375-1.193l-6.344-2.999h-5.688
           l-3.531-3h-4.219h-4.969l-4.25,3h-4.219l-0.719-3.609l-2.813-1.806l-2.844-3l-4.938-0.609h-4.25l-5.656-3l-2.844-2.389
           l-4.938-4.221l-4.938-4.193l-4.969-4.193l-4.25-2.416l-5.656-2.389l-4.938-3.609h-4.938l-7.781,0.585l-6.375,0.609l-5.656-1.194
           l-5.656-2.416l-4.25-1.194l-6.344-1.195l-4.969-5.413l-2.813-1.806l-4.25-4.804l-4.938-2.999l-4.969-2.999l-4.938-1.806h-7.781
           l-4.25-2.388l-6.375-5.415l-2.094-2.999l-4.25-3l-3.531-2.999l-2.125-3.025l-1.406-1.195l-0.719-1.805L820.33,20.252z
            M265.455,348.351c-0.097,0.008-0.181,0.02-0.281,0.027C265.266,348.371,265.37,348.358,265.455,348.351z M256.205,348.988
           c0.023-0.001,0.039,0.001,0.063,0c-0.032,0.001-0.062,0.024-0.094,0.026C256.183,349.013,256.196,348.989,256.205,348.988z
            M250.549,349.307c0.021-0.002,0.042,0.003,0.063,0c-0.035,0.003-0.06,0.025-0.094,0.026
           C250.528,349.333,250.538,349.308,250.549,349.307z"
        />
        <path
          className="inactive"
          id="ulaanbaatar"
          stroke="#ffffff"
          stroke-width="3"
          d="M1308.226,455.062
            l4.242,7.808l-2.121,6.606l2.828,10.209l5.658,9.608l-2.83,7.207l5.658,7.207l2.828-4.805l5.656,3.603l12.729,1.202l4.949-4.203
            l7.072,1.199h6.363l11.313,10.211h16.264l-0.707-4.205l3.535-2.402l-0.707-4.203l-0.707-6.006l3.537-5.405v-3.002v-2.403
            l-2.123-2.402l-2.828-0.599l-2.121-0.601l-2.121-0.601h-2.828l-2.121,1.202l-2.828-1.202l-5.658-7.807l-4.242-5.405l-2.828-1.201
            l-2.121-1.202l-3.535-4.204l-2.828,1.803l-4.244-3.002l-5.656,3.002h-4.242l-7.072-2.402l-7.07-0.601h-4.242l-6.365-3.003
            l-2.828-0.6L1308.226,455.062z"
        />
        <path
          className="inactive"
          id="baganuur"
          stroke="#ffffff"
          stroke-width="3"
          d="
           M1460.96,497.102l-8.484,7.805l-4.242,10.813l4.242,14.412l7.777,2.402l2.74-5.781l2.918-6.83l1.414-7.207v-13.211L1460.96,497.102
           z"
        />
        <path
          className="inactive"
          id="bagakhangai"
          stroke="#ffffff"
          stroke-width="3"
          d="M1379.644,531.333
           l3.535,8.407l11.314-4.202l-4.951-7.208l-6.363-1.2L1379.644,531.333z"
        />
      </g>
    </svg>
  );
  return (
    <>
      <Row>
        <Col md={5}>
          <img src="/Standard_icon.jpg" width="72px" alt="s" />
          <img src="/Happy_icon.jpg" width="72px" alt="m" />
          <img src="/Super_icon.jpg" width="72px" alt="l" />
        </Col>
        <Col md={4}>&nbsp;</Col>
        <Col>
          <Form.Select
            value={activeMap}
            onChange={(e) => {
              setActiveMap(e.target.value);
            }}
          >
            {categories.map((e) => {
              return <option value={e.value}>{e.name}</option>;
            })}
          </Form.Select>
        </Col>
      </Row>
      <Row>{map}</Row>
      <Row>
        <div className="mt-4"></div>
      </Row>
      {activeMap == "all" && (
        <Row>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>ЭРДЭНЭТ</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "erdenet" ? (
                    channel(erdenet)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>ЗАВХАН</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "zavkhan" ? (
                    channel(zavkhan)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>УВС</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "uvs" ? (
                    channel(uvs)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>ТӨВ АЙМАГ</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "tuv" ? (
                    channel(tuv)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>БАЯНХОНГОР</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "bayankhongor" ? (
                    channel(bayankhongor)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>ХӨВСГӨЛ</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "khuvsgul" ? (
                    channel(khuvsgul)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>ДОРНОГОВЬ</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "dornogovi" ? (
                    channel(dornogovi)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>ДАРХАН</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "darkhan-uul" ? (
                    channel(darkhan)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>ДОРНОД</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "dornod" ? (
                    channel(dornod)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>ӨВӨРХАНГАЙ</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "uvurkhangai" ? (
                    channel(uvurkhangai)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>ДУНДГОВЬ</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "dundgovi" ? (
                    channel(dundgovi)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>СЭЛЭНГЭ</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "selenge" ? (
                    channel(selenge)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header>АРХАНГАЙ</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "arkhangai" ? (
                    channel(arkhangai)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
              <Accordion.Header>ӨЛГИЙ</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "bayan-ulgii" ? (
                    channel(ulgii)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
              <Accordion.Header>ХЭНТИЙ</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "khentii" ? (
                    channel(khentii)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="15">
              <Accordion.Header>БУЛГАН</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "bulgan" ? (
                    channel(bulgan)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="16">
              <Accordion.Header>ӨМНӨГОВЬ</Accordion.Header>
              <Accordion.Body>
                <Row>
                  {activeMap === "all" || activeMap === "bulgan" ? (
                    channel(umnugovi)
                  ) : (
                    <span>Илэрц хоосон байна. </span>
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
      )}

      <Row>{activeMap === "umnugovi" ? channel(umnugovi) : null}</Row>
      <Row>{activeMap === "erdenet" ? channel(erdenet) : null}</Row>
      <Row>{activeMap === "zavkhan" ? channel(zavkhan) : null}</Row>
      <Row>{activeMap === "uvs" ? channel(uvs) : null}</Row>
      <Row>{activeMap === "tuv" ? channel(tuv) : null}</Row>
      <Row>{activeMap === "bayankhongor" ? channel(bayankhongor) : null}</Row>
      <Row>{activeMap === "khuvsgul" ? channel(khuvsgul) : null}</Row>
      <Row>{activeMap === "dornogovi" ? channel(dornogovi) : null}</Row>
      <Row>{activeMap === "darkhan-uul" ? channel(darkhan) : null}</Row>
      <Row>{activeMap === "dornod" ? channel(dornod) : null}</Row>
      <Row>{activeMap === "uvurkhangai" ? channel(uvurkhangai) : null}</Row>
      <Row>{activeMap === "dundgovi" ? channel(dundgovi) : null}</Row>
      <Row>{activeMap === "selenge" ? channel(selenge) : null}</Row>
      <Row>{activeMap === "arkhangai" ? channel(arkhangai) : null}</Row>
      <Row>{activeMap === "bayan-ulgii" ? channel(ulgii) : null}</Row>
      <Row>{activeMap === "khentii" ? channel(khentii) : null}</Row>
      <Row>{activeMap === "bulgan" ? channel(bulgan) : null}</Row>
    </>
  );
}
