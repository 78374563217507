import {
  Container,
  ListGroup,
  Tab,
  Row,
  Alert,
  Accordion,
  Col,
} from "react-bootstrap";
import NVod from "./NVOD";
import RVOD from "./RVOD";
import Banner from "../Layout/Banner";
import FAQ from "../Help/FAQ";
import AccordionItem from "react-bootstrap/esm/AccordionItem";

export default function MovieList() {
  return (
    <>
      <Banner type="movie" />
      <Container
        className="tab-container section"
        style={{ marginTop: "-30px", position: "relative", zIndex: 19 }}
      >
        <Row>
          <Tab.Container justify defaultActiveKey="#nvod" className="section">
            <ListGroup className="list-group-horizontal-md">
              <ListGroup.Item action href="#nvod" className="tabs-title tabs">
                Шинэ кино 800
              </ListGroup.Item>
              <ListGroup.Item action href="#rvod" className="tabs-title tabs">
                Кино сан
              </ListGroup.Item>
              <ListGroup.Item
                action
                href="#recommended"
                className="tabs-title tabs"
              >
                Санал болгох кино
              </ListGroup.Item>
            </ListGroup>

            <Tab.Content>
              <Tab.Pane eventKey="#nvod" style={{ marginTop: "50px" }}>
                <NVod />
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="#rvod" style={{ marginTop: "50px" }}>
                <RVOD />
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="#recommended" style={{ marginTop: "50px" }}>
                <Row fluid>
                  <Alert variant="warning">Тун удахгүй...</Alert>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Row>
      </Container>
      <Container className="section-content">
        <Tab.Container defaultActiveKey="#help">
          <Row>
            <Col md={{ span: 4, offset: 4 }}>
              <ListGroup className="list-group-horizontal-md" centered>
                <ListGroup.Item action href="#help" className="tabs-title help">
                  Тусламж
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Tab.Content>
              <Tab.Pane eventKey="#help">
                <Accordion>
                  <AccordionItem eventKey="0">
                    <Accordion.Header style={{ backgroundColor: "#f7f8fa" }}>
                      Кино сангаас кино түрээслэн үзэх заавар
                    </Accordion.Header>
                    <Accordion.Body>
                      <FAQ id={42} />
                    </Accordion.Body>
                  </AccordionItem>
                  <AccordionItem eventKey="1">
                    <Accordion.Header>
                      ШИНЭ КИНО 800 сувгаас кино захиалах заавар
                    </Accordion.Header>
                    <Accordion.Body>
                      <FAQ id={18} />
                    </Accordion.Body>
                  </AccordionItem>
                </Accordion>
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
}
