import { mapSecret, webUrl } from "../../helper/globals";
import {
  Marker,
  InfoWindow,
  useJsApiLoader,
  GoogleMap,
} from "@react-google-maps/api";
import ddishMarker from "../../assets/img/undsen-marker.svg";
import unitelMarker from "../../assets/img/unitel-marker.svg";
import React, { memo, useCallback, useState } from "react";
import { Row } from "react-bootstrap";
const style = { position: "relative", height: "80vh", width: "100%" };
function Maps(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: mapSecret,
    language: "mn",
  });
  var centralTower = { lat: 47.9181029, lng: 106.9194135 };
  var karakorum = { lat: 47.113701, lng: 102.485401 };
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [map, setMap] = useState(null);
  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div>
      <GoogleMap
        google={props.google}
        zoom={props.area === "ub" ? 12 : 5}
        center={props.area === "ub" ? centralTower : karakorum}
        visible={props.visible}
        onLoad={onLoad}
        onUnmount={onUnmount}
        mapContainerStyle={style}
      >
        {props.marker.map((m) => {
          let ic = "";
          switch (m.type) {
            case "undsen":
              ic = ddishMarker;
              break;
            case "unitel":
              ic = unitelMarker;
              break;

            default:
              ic = ddishMarker;
              break;
          }
          return (
            <Marker
              icon={ic}
              name={m.name}
              key={m.branch_id}
              position={{ lat: parseFloat(m.lat), lng: parseFloat(m.lon) }}
              onClick={() => {
                setSelectedMarker(m);
                setIsOpen(true);
              }}
            >
              {isOpen && selectedMarker === m && (
                <InfoWindow
                  lng={parseFloat(selectedMarker.lon)}
                  lat={parseFloat(selectedMarker.lat)}
                  onCloseClick={() => {
                    setIsOpen(false);
                    setSelectedMarker(null);
                  }}
                >
                  <div className="branchBox">
                    <div className=" bodyImg pad-no" back-img="test">
                      <div
                        className="img"
                        style={{
                          backgroundImage:
                            "url(" + webUrl + selectedMarker.picture + ")",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                    <div className="item-content">
                      <h5>{selectedMarker.branch_title}</h5>
                      <div>
                        <i className="bairshil"></i>
                        <p>{selectedMarker.branch_content}</p>
                      </div>
                      <div>
                        <i class="timework"></i>
                        <ul class="timetable no-style mar-lft-no">
                          <Row
                            md={2}
                            dangerouslySetInnerHTML={{
                              __html: selectedMarker.time,
                            }}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })}
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}
export default memo(Maps);
