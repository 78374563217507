import "./App.css";
import Footer from "./components/Layout/Footer";
import { BrowserRouter, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ddish_logo from "./assets/img/icons/ddish_logo.svg";
import ddish_theatre from "./assets/img/kino san logo.png";
import ddish_live from "./assets/img/ddishlive.png";
import en from "./assets/img/en.png";
import * as authActions from "./actions/auth";
import * as messageActions from "./actions/messages";
import upoint from "./assets/img/upointddish.png";
import Navbar from "react-bootstrap/Navbar";
import "react-toastify/dist/ReactToastify.css";
import "./style/slick.scss";

import "./style/style.css";
import "./style/main.scss";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import "./style/app.css";
import "./style/circle.css";
import "./style/header.scss";

import "./style/icon.css";
import "./style/about.scss";
import "./style/button.scss";
import "./style/channel.scss";
// import "./style/chatbox.scss";
import "./style/custom.scss";
import "./style/devices.scss";
import "./style/movie-slider.scss";
import "./style/product.css";

import "./style/slider.scss";
import "./style/vod.scss";
import "./style/success.scss";
import "./style/body-card.scss";
import "./style/body-cus.scss";
import "./style/uploader.scss";

// responsive
import "./style/responsive/mobile.scss";
import "./style/responsive/web.scss";

import "./style/OnlineOrder.scss";

import MainRoutes from "./components/Routes";
import LoginForm from "./components/modals/Login";

import { useEffect, useState } from "react";
import {
  Badge,
  Container,
  Modal,
  ModalBody,
  Nav,
  NavDropdown,
  Spinner,
} from "react-bootstrap";
import { connect, shallowEqual, useSelector } from "react-redux";
import axios from "axios";
import { webApiUrl } from "./helper/globals";

function App(props) {
  const [isOpen, setIsOpen] = useState(false);

  const state = useSelector((state) => state, shallowEqual);
  const auth = useSelector((state) => state.auth, shallowEqual);
  const [channelDropdown, setChannelDropdown] = useState(false);

  const [userDropdown, setuserDropdown] = useState(false);
  const [modalShow, setmodalShow] = useState(false);
  const [modalClicked, setModalClicked] = useState(false);
  const [newsCount, setnewsCount] = useState(0);

  useEffect(() => {
    axios.get(webApiUrl + "promo/active").then((res) => {
      setnewsCount(res.data.result[0].active);
    });
  });

  return (
    <BrowserRouter>
      <ToastContainer />
      <div className="App">
        <div className="bgName" id="body">
          <div className="off-canvas-wrapper">
            <div
              id="wr1"
              className={
                "off-canvas-wrapper-inner" + (isOpen ? " is-open-right" : "")
              }
            >
              <div
                id="wr2"
                className={
                  "off-canvas position-right" + (isOpen ? " is-open" : "")
                }
              >
                <ul className="mobile-ofc vertical menu offcanvas-menu-dest">
                  <li>
                    <a href="/packages">Сувгийн багц</a>
                  </li>

                  <li>
                    <a href="#">Сувгууд</a>
                    <ul className="nested vertical menu">
                      <li>
                        <a href="">Сувгууд</a>
                      </li>
                      <li>
                        <a href="/channel/paid">Төлбөрт сувгууд</a>
                      </li>
                      <li>
                        <a href="/channel/additional">Багцууд</a>
                      </li>
                      <li>
                        <a href="/channel/rural">Орон нутгийн сувгууд</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/store"></a>
                  </li>
                  <li>
                    <a href="/promo">Урамшуулал</a>
                  </li>
                  <li>
                    <a href="/helpdesk">Тусламж</a>
                  </li>
                  <li>
                    <a href="/movie">Кино</a>
                  </li>
                  <li>
                    <a href="/live">DDish Live</a>
                  </li>
                  {/* <li>
                    <a href="/en">English version</a>
                  </li> */}
                </ul>
              </div>
              <div className="off-canvas-content" data-off-canvas-content>
                <header id="header" className="headerClass">
                  <Navbar
                    className="top-bar"
                    expand={"xl"}
                    collapseOnSelect={"xl"}
                  >
                    <Container className="menu-row" fluid>
                      <Navbar.Brand href="/" className="top-bar-title">
                        {/* <Link to="/"> */}
                        <img
                          src={ddish_logo}
                          className="main-logo"
                          alt="ddish logo"
                        />
                        {/* </Link> */}
                      </Navbar.Brand>
                      <ul className="menu hide-for-large" data-hide-for="large">
                        {auth.isLoggedIn ? (
                          <li className="btn logged is-dropdown-submenu-parent">
                            <a href="/service/user">
                              <i className="i-header logged"></i>
                            </a>
                          </li>
                        ) : (
                          <li className="btn hide-for-large">
                            <a href="/login">
                              <i className="i-header login"></i>
                            </a>
                          </li>
                        )}

                        <li className=" hide-for-large btn">
                          <button
                            id="button1"
                            className="menu-icon dark right-off-canvas-toggle"
                            type="button"
                            onClick={() => {
                              setIsOpen(!isOpen);
                            }}
                          ></button>
                        </li>
                      </ul>

                      <Nav className="top-bar-right">
                        <div className="dropdown menu">
                          <div className="nav">
                            <Link
                              className="cool-link align-self-center"
                              to="/packages"
                            >
                              Багц сонголт
                            </Link>
                            <NavDropdown
                              title="Сувгууд"
                              className="cool-link opens-right menu dropdown1"
                              onMouseEnter={() => setChannelDropdown(true)}
                              onMouseLeave={() => setChannelDropdown(false)}
                              show={channelDropdown}
                            >
                              <NavDropdown.Item>
                                <Link
                                  className="cool-link vertical channel "
                                  to="/channel/main"
                                >
                                  Сувгууд
                                </Link>
                              </NavDropdown.Item>
                              <NavDropdown.Item>
                                <Link
                                  className="cool-link vertical channel "
                                  to="/channel/paid"
                                >
                                  Төлбөрт сувгууд
                                </Link>
                              </NavDropdown.Item>
                              <NavDropdown.Item>
                                <Link
                                  className="cool-link channel vertical "
                                  to="/channel/additional"
                                >
                                  Нэмэлт сувгууд
                                </Link>
                              </NavDropdown.Item>
                              <NavDropdown.Item>
                                <Link
                                  className="cool-link channel vertical"
                                  to="/channel/rural"
                                >
                                  Орон нутгийн сувгууд
                                </Link>
                              </NavDropdown.Item>
                            </NavDropdown>
                            <Link
                              className="cool-link align-self-center"
                              to="/store"
                            >
                              Төхөөрөмж
                            </Link>
                            <Link
                              className="cool-link align-self-center"
                              to="/promo"
                            >
                              Урамшуулал
                              {newsCount === 0 ? null : (
                                <Badge
                                  pill
                                  bg="primary"
                                  style={{
                                    position: "relative",
                                    left: "5px",
                                    bottom: "10px",
                                  }}
                                >
                                  {newsCount}
                                </Badge>
                              )}
                            </Link>
                            <Link
                              to="/helpdesk"
                              className="cool-link align-self-center"
                            >
                              Тусламж
                            </Link>
                            <Link to="/movie" className="btn align-self-center">
                              <img
                                src={ddish_theatre}
                                style={{ width: "30px" }}
                                alt="ddish theatre logo"
                              />
                            </Link>
                            <Link to="/live" className="btn align-self-center">
                              <img
                                src={ddish_live}
                                style={{ width: "50px" }}
                                alt="ddish live logo"
                              />
                            </Link>
                            <Nav.Link
                              className="btn align-self-center"
                              onClick={() => {
                                window
                                  .open("https://www.upoint.mn/", "_blank")
                                  .focus();
                              }}
                            >
                              <img
                                src={upoint}
                                style={{ width: "30px" }}
                                alt="upoint logo"
                              />
                            </Nav.Link>
                            <Link
                              className="btn search align-self-center"
                              to="/search"
                            >
                              <i className="search"></i>
                            </Link>
                            {auth.isLoggedIn === true ? (
                              <NavDropdown
                                className="btn align-self-center opens-right is-active nocaret"
                                title={<i className="logged"></i>}
                                onMouseLeave={() => setuserDropdown(false)}
                                onMouseEnter={() => setuserDropdown(true)}
                                show={userDropdown}
                              >
                                <NavDropdown.Item>
                                  <Link
                                    className="cool-link"
                                    to="/service/user"
                                  >
                                    Хэрэглэгчийн мэдээлэл
                                  </Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                  <Link
                                    className="cool-link"
                                    onClick={() => {
                                      props.logout();
                                    }}
                                    to="/"
                                  >
                                    Гарах
                                  </Link>
                                </NavDropdown.Item>
                              </NavDropdown>
                            ) : (
                              <Nav.Link
                                className="btn search align-self-center"
                                onClick={() => {
                                  setmodalShow(true);
                                  setModalClicked(true);
                                }}
                              >
                                <i className="login"></i>
                              </Nav.Link>
                            )}
                            {/* <Nav.Link className="btn search align-self-center" href="/en">
                              <img src={en} alt="en logo" />
                            </Nav.Link> */}
                          </div>
                        </div>
                      </Nav>
                    </Container>
                  </Navbar>
                  <Modal
                    centered
                    show={modalShow && (!modalClicked || !auth.isLoggedIn)}
                    onHide={() => {
                      props.clearMessage();
                      setmodalShow(false);
                      setModalClicked(false);
                    }}
                  >
                    <Modal.Header closeButton>
                      <h4>Нэвтрэх</h4>
                    </Modal.Header>
                    <ModalBody>
                      {state.auth.isLoading ? (
                        <Spinner animation="border" />
                      ) : null}
                      <LoginForm />
                    </ModalBody>
                  </Modal>
                </header>
                <div className="page-container">
                  <MainRoutes />
                  <Footer />
                </div>
              </div>
              <div
                id="sh"
                className={"js-off-canvas-exit" + (isOpen ? " is-visible" : "")}
                onClick={() => {
                  setIsOpen(false);
                }}
              />
            </div>
          </div>

          <div className="social-container hide-for-small-only at-content">
            <ul>
              <li className="facebook">
                <a
                  href="http://facebook.com/ddishofficial"
                  target="noopener noreferrer"
                />
              </li>
              <li className="twitter">
                <a
                  href="http://twitter.com/ddish_official"
                  target="noopener noreferrer"
                />
              </li>
              <li className="youtube">
                <a
                  href="https://www.youtube.com/channel/UC7hSKZ9qCAfKACyPt3O_eNw"
                  target="noopener noreferrer"
                />
              </li>
              <li className="instagram">
                <a
                  href="https://www.instagram.com/ddishofficial"
                  target="noopener noreferrer"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authActions.logout()),

    clearMessage: () => dispatch(messageActions.clearMessage()),
  };
};
export default connect(null, mapDispatchToProps)(App);
