import axios from "axios";
import { useEffect, useState } from "react";
import { webApiUrl } from "../../helper/globals";

export default function FAQ(props) {
  const [content, setContent] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      axios.get(webApiUrl + "helpdesks/" + props.id).then((res) => {
        setContent(res.data.result[0].helpdesk_content);
        setIsLoaded(true);
      });
    }
  });

  return (
    <div
      dangerouslySetInnerHTML={{ __html: content }}
      style={{ overflowY: "auto", overflowX: "hidden" }}
    />
  );
}
