import {
  LOGIN,
  LOGIN_FAIL,
  LOGIN_REFRESH_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
  TOAST_WARNING,
  TOAST_INFO,
} from "./types";
import axios from "axios";
import { baseUrl, secret } from "../helper/globals";
import { setMessage } from "./messages";
import { getInfo, getCounter, checkType } from "./user";
import { getProduct } from "./product";

export const login = (payload, navigate) => {
  return function (dispatch) {
    dispatch({ type: LOGIN, payload: payload });
    axios
      .post(
        baseUrl + "login/web",
        {
          grant_type: "password",
          username: payload.username,
          password: payload.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Basic " + secret,
          },
        }
      )
      .then((res) => {
        localStorage.setItem("key", JSON.stringify(res.data));
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });

        dispatch(
          setMessage({ type: TOAST_INFO, text: "Амжилттай нэвтэрлээ." })
        );
        dispatch(checkType());
        dispatch(getInfo());
        dispatch(getProduct());
        dispatch(getCounter());
        navigate("/");
      })
      .catch((err) => {
        localStorage.clear();
        dispatch(
          setMessage({
            type: TOAST_WARNING,
            text: "Нэвтрэх нэр нууц үг буруу байна.",
          })
        );
        dispatch({ type: LOGIN_FAIL, payload: { err: err } });
        return "error";
      });
  };
};
export const refresh = (payload) => {
  return function (dispatch) {
    axios
      .post(
        baseUrl + "login/web",
        {
          grant_type: "refresh_token",
          refresh_token: payload,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Basic " + secret,
          },
        }
      )
      .then((res) => {
        localStorage.setItem("key", JSON.stringify(res.data));
        dispatch({ type: LOGIN_REFRESH_SUCCESS, payload: res.data });
      })
      .catch((err) => {
        localStorage.clear();
        dispatch({ type: LOGIN_FAIL, payload: { err: err.Error } });
      });
  };
};
export const logout = () => {
  localStorage.clear();

  return function (dispatch) {
    dispatch({
      type: LOGOUT,
    });
    dispatch(
      setMessage({ type: TOAST_INFO, text: "Амжилттай системээс гарлаа." })
    );
  };
};
