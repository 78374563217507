import dateFormat from "dateformat";
export const yyyymmdd = (now = new Date()) => {
  var y = now.getFullYear();
  var m = now.getMonth() + 1;
  var d = now.getDate();
  var mm = m < 10 ? "0" + m : m;
  var dd = d < 10 ? "0" + d : d;
  return "" + y + mm + dd;
};
export const dateToString = (now) => {
  var y = now.getFullYear();
  var m = now.getMonth() + 1;
  var d = now.getDate();
  var mm = m < 10 ? "0" + m : m;
  var dd = d < 10 ? "0" + d : d;
  return `${y}-${mm}-${dd}`;
};
export const lineAdder = (str) => {
  return str.slice(0, 4) + "-" + str.slice(4, 6) + "-" + str.slice(6);
};
export const timeAdder = (str) => {
  return str.slice(0, 2) + ":" + str.slice(2, 4);
};
export const getDays = (day = 7) => {
  var days = [];
  for (let i = 0; i < day; i++) {
    var date = new Date();
    date.setDate(date.getDate() + i);
    date = dateFormat(date, "isoDate");
    days.push(date);
  }
  return days;
};
