import axios from "axios";
import { useState, useEffect } from "react";
import { webApiUrl, webUrl } from "../../helper/globals";
import Slider from "react-slick";
import { useNavigate } from "react-router";
import { Carousel } from "react-bootstrap";

function Banner(props) {
  const [banners, setbanners] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const getAllBanners = () => {
    if (!isLoaded) {
      axios
        .get(webApiUrl + "get_banners/" + props.type)
        .then((result) => {
          const AllBanners = result.data.result;
          setbanners(AllBanners);
        })
        .catch((err) => console.log(err));
      setIsLoaded(true);
    }
  };
  useEffect(() => {
    getAllBanners();
  });

  var banner = banners.map((e, index) => {
    return (
      <Carousel.Item key={index}>
        <div className="slider">
          <div
            className="banner"
            style={{
              backgroundImage: `url("${webUrl + e.image}")`,
              // backgroundSize: "cover",
            }}
          >
            <div className="row">
              <div className={"banner-desc " + e.position}>
                {e.url !== null && e.url !== "" ? (
                  <a
                    href={e.url}
                    alt={e.name}
                    className="button secondary hollow"
                    style={{ position: "relative", bottom: "30%" }}
                  >
                    ДЭЛГЭРЭНГҮЙ
                  </a>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
    );
  });
  return (
    <div className="section-banner">
      <Carousel>{banner}</Carousel>
    </div>
  );
}
export default Banner;
