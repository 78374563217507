import { Button, Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import OrderForm from "./OrderForm";
export default function NewOrder() {
  const [buttonClicked, setButtonClicked] = useState(false);
  return (
    <>
      <Container className="section-container " style={{ marginTop: "60px" }}>
        <Row>
          <Col md={4}>
            <img
              src={
                "https://www.ddishtv.mn:3000/u/device/Bagts%20tuhuurumj%202.jpg"
              }
              alt="intek"
            ></img>
          </Col>
          <Col md={8}>
            <h2 style={{ color: "#0047bb" }}>БАГЦ ТӨХӨӨРӨМЖ</h2>
            <p>
              Хүлээн авагчдаа 32GB-с дээш багтаамжтай флаш диск холбосноор ДДЭШ
              КИНО САН, БИЧЛЭГ ХИЙХ үйлчилгээнүүдийг илүү хялбараар авах
              боломжтой шинэ загварын хүлээн авагчтай. Мөн хүлээн авагчаа
              интернэтэд холбож илүү хялбараар КИНО КОНТЕНТ-оо захиалан үзэх
              боломжтой. Төхөөрөмжөө худалдан аваад 150’000₮/Нэмэлт болон
              төлбөрт үйлчилгээнд ашиглах эрх/ буцаан авах боломжтой.
            </p>
            <p>
              <div className="dd-values-con">
                <v style={{ fontSize: "1.4rem", color: "#b0b0b0" }}>340'000₮</v>
              </div>
            </p>
            <Button
              onClick={() => {
                setButtonClicked(true);
              }}
            >
              Захиалга өгөх
            </Button>
          </Col>
        </Row>
      </Container>
      {buttonClicked ? <OrderForm callType="988" /> : null}
    </>
  );
}
