import { useState } from "react";
import { Container, ListGroup, Row, Tab } from "react-bootstrap";
import Breadcrumbs from "./Layout/Breadcrumb";

export default function ServiceAgreement() {
  const [title, setTitle] = useState("Хэрэглэгчийн үйлчилгээний гэрээ");
  return (
    <Breadcrumbs
      title={title}
      content={
        <Container className="section-content" fluid>
          <Tab.Container defaultActiveKey="#user">
            <Row className="md-center">
              <ListGroup className="list-group-horizontal-md">
                <ListGroup.Item
                  action
                  href="#user"
                  className="tabs-title tabs"
                  onClick={() => {
                    setTitle("Хэрэглэгчийн үйлчилгээний гэрээ");
                  }}
                >
                  Хэрэглэгчийн үйлчилгээний гэрээ
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href="#transmission"
                  className="tabs-title tabs"
                  onClick={() => {
                    setTitle("Суваг дамжуулах нөхцөл шаардлага");
                  }}
                >
                  Суваг дамжуулах нөхцөл шаардлага
                </ListGroup.Item>
              </ListGroup>
            </Row>
            <Tab.Content>
              <Tab.Pane eventKey="#user">
                <Row style={{ paddingTop: "20px" }}>
                  <div className="small-12 columns">
                    <p>
                      Хиймэл дагуулын технологийн телевизийн үйлчилгээний гэрээ
                    </p>
                    <p>
                      <a
                        onClick={() => {
                          window.open(
                            "https://ddishtv.mn:3000/u/DDish_undsen%20_geree.pdf",
                            "_blank",
                            "strWindowFeatures"
                          );
                        }}
                        href
                        className="button"
                      >
                        ДЭЛГЭРЭНГҮЙ
                      </a>
                    </p>
                  </div>
                </Row>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="#transmission">
                <Row style={{ paddingTop: "20px" }}>
                  <div className="small-12 columns">
                    <p>
                      Олон суваг дамжуулах үйлчилгээний сүлжээнд холбогдох нөхцөл /RAO/
                    </p>
                    <p>
                      <a
                        onClick={() => {
                          window.open(
                            "https://ddishtv.mn:3000/u/DDish_RAO.pdf",
                            "_blank",
                            "strWindowFeatures"
                          );
                        }}
                        href
                        className="button"
                      >
                        ДЭЛГЭРЭНГҮЙ
                      </a>
                    </p>
                  </div>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
      }
      route={[]}
    />
  );
}
