import axios from "axios";
import { useState, useEffect } from "react";
import { webApiUrl } from "../../helper/globals";
import { Container, ListGroup, Row, Tab } from "react-bootstrap";
import job1 from "../../style/icon/job-icon/1.svg";
import job2 from "../../style/icon/job-icon/2.svg";
import job3 from "../../style/icon/job-icon/3.svg";
import job4 from "../../style/icon/job-icon/4.svg";
import job5 from "../../style/icon/job-icon/5.svg";
import Breadcrumbs from "../Layout/Breadcrumb";
export default function Jobs() {
  const [jobs, setjobs] = useState([]);
  const [title, setTitle] = useState("Манайд ажилласнаар");
  const getAllJobs = () => {
    axios
      .get(webApiUrl + "get_jobs")
      .then((result) => {
        const AllJobs = result.data.result;
        setjobs(AllJobs);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllJobs();
  }, []);

  return (
    <Breadcrumbs
      title={title}
      content={
        <Tab.Container defaultActiveKey="#home">
          <Container fluid className="section-content">
            <Row>
              <ListGroup className="list-group-horizontal-md">
                <ListGroup.Item
                  action
                  href="#home"
                  className="tabs-title tabs"
                  onClick={() => {
                    setTitle("Манайд ажилласнаар");
                  }}
                >
                  Манайд ажилласнаар
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href="#selection"
                  className="tabs-title tabs"
                  onClick={() => {
                    setTitle("Сонгон шалгаруулалт");
                  }}
                >
                  Сонгон шалгаруулалт
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href="#openings"
                  className="tabs-title tabs"
                  onClick={() => {
                    setTitle("Нээлттэй ажлын байр");
                  }}
                >
                  Нээлттэй ажлын байр
                </ListGroup.Item>
              </ListGroup>
            </Row>
          </Container>
          <Container fluid className="section" style={{ marginTop: "-40px" }}>
            <Tab.Content>
              <Tab.Pane eventKey="#home">
                <div class="section section-content">
                  <div class="row">
                    <div class="medium-12 columns">
                      <ul class="job-fair-check">
                        <li>
                          Шинэ үеийн чадварлаг залуустай нэг баг болон хамтран
                          ажиллах
                        </li>
                        <li>Өсөн дээшлэх ижил тэгш боломж</li>
                        <li>Хийж бүтээх таатай орчин, урам зориг</li>
                        <li>Өрсөлдөхүйц цалин, бодит урамшуулал</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="#selection">
                <div class="section section-content">
                  <div class="row">
                    <h4 class="text-success mar-btm-30">
                      Сонгон шалгаруулалтын үе шат
                    </h4>
                    <ul class="medium-up-3 large-up-5 row block steps">
                      <li class="column">
                        <img src={job1} alt="" />
                        <p class="text-center">1. Анкет хүлээн авах</p>
                      </li>
                      <li class="column">
                        <img src={job2} alt="" />
                        <p class="text-center">2. Шалгаруулалт хийх</p>
                      </li>
                      <li class="column">
                        <img src={job3} alt="" />
                        <p class="text-center">
                          3. Нэмэлт даалгавар, мэргэжлийн шалгалт
                        </p>
                      </li>
                      <li class="column">
                        <img src={job4} alt="" />
                        <p class="text-center">4. Ярилцлага хийх</p>
                      </li>
                      <li class="column">
                        <img src={job5} alt="" />
                        <p class="text-center">5. Ажилд авах санал тавих</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="#openings">
                <div class="section section-content">
                  <div class="section section-content">
                    <div class="row">
                      <h4 class="text-success mar-btm-30">
                        Нээлттэй ажлын байр
                      </h4>
                      <div>
                        <a href="https://careers.mcs.mn/">careers.mcs.mn</a>{" "}
                        систем нь ажил горилогчийг бүртгэх, сонгон шалгаруулах,
                        ажилд авах хүртэлх үе шатуудыг нэгтгэсэн хүний нөөцийн
                        сонгон шалгаруулалт бүрдүүлэлтийн нэгдсэн платформ
                        бөгөөд М-Си-Эс группийн компаниудад зарлагдаж буй
                        нээлттэй ажлын байрыг сонирхож буй хүн бүр хандах
                        боломжтой.
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Container>
        </Tab.Container>
      }
      route={[{ url: "/jobs", text: "Ажлын байр" }]}
    />
  );
}
