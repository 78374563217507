import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "react-bootstrap";
import QRCodeSVG from "qrcode.react";
import { getSecured } from "../../helper/requests";
import ebarimt from "../../assets/img/ebarimt.svg";

export default function VAT() {
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoaded, setisLoaded] = useState(false);
  useEffect(() => {
    if (!isLoaded) {
      getSecured(
        `vathistory/0`,
        (res) => {
          setRecords(res.data.vats);
        },
        (err) => {}
      );
      setisLoaded(true);
    }
  }, [isLoaded]);

  return (
    <Container className="section-content">
      <Row md={4}>
        {records.map((e) => {
          return (
            <Col>
              <Card
                style={{ borderRadius: 0, borderColor: "#0047ba" }}
                onClick={() => {
                  setSelectedRecord(e);
                  setModalIsOpen(true);
                }}
              >
                <p>
                  <h4
                    style={{
                      color: "#0047ba",
                      marginLeft: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <b>{e.amount} төг</b>
                  </h4>
                </p>
                <p style={{ marginLeft: "10px" }}>{e.lotteryNo}</p>
                <p style={{ marginLeft: "10px" }}>Огноо: {e.billDate}</p>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)} size="sm">
        <ModalHeader closeButton>
          <h3 style={{ color: "#0047ba" }}>НӨАТ-ын баримт</h3>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <img src={ebarimt} width="50%" alt="ebarimt" />
            </Col>
          </Row>

          <Row>ДДТД:</Row>
          <Row style={{ overflowWrap: "anywhere" }}>
            <h5 style={{ color: "#0047ba" }}>{selectedRecord?.billId}</h5>
          </Row>
          <Row>
            Огноо:
            <h5 style={{ color: "#0047ba" }}>{selectedRecord?.billDate}</h5>
          </Row>
          <Row>Үнийн дүн:</Row>
          <Row>
            <h5 style={{ color: "#0047ba" }}>{selectedRecord?.amount}₮</h5>
          </Row>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <QRCodeSVG value={selectedRecord?.qrData} level="L" size="200" />
          </div>
          <Row className="text-center" style={{ marginTop: "10px" }}>
            <h5 style={{ color: "#0047ba" }}>{selectedRecord?.lotteryNo}</h5>
          </Row>
        </ModalBody>
      </Modal>
    </Container>
  );
}
