import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import $ from "jquery";
import { useEffect } from "react";
import OnlineOrderShowForm from "./OnlineOrderShowForm";
import { useStateContext } from "../../context/ContextProvider";

function OnlineOrderForm() {
  const [show, setShow] = useState(false);
  const saved = JSON.parse(localStorage.getItem("order"));
  useEffect(() => {
    if (saved) {
      setShow(true);
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const states = [
    { aimagName: "Сонгох", aimagId: 0 },
    { aimagName: "Улаанбаатар", aimagId: "11" },
    { aimagName: "Архангай", aimagId: "65" },
    { aimagName: "Баян-Өлгий", aimagId: "83" },
    { aimagName: "Баянхонгор", aimagId: "64" },
    { aimagName: "Булган", aimagId: "63" },
    { aimagName: "Говь-Алтай", aimagId: "82" },
    { aimagName: "Говьсүмбэр", aimagId: "42" },
    { aimagName: "Дархан-Уул", aimagId: "45" },
    { aimagName: "Дорноговь", aimagId: "44" },
    { aimagName: "Дорнод", aimagId: "21" },
    { aimagName: "Дундговь", aimagId: "48" },
    { aimagName: "Орхон", aimagId: "61" },
    { aimagName: "Өвөрхангай", aimagId: "62" },
    { aimagName: "Өмнөговь", aimagId: "46" },
    { aimagName: "Сүхбаатар", aimagId: "22" },
    { aimagName: "Сэлэнгэ", aimagId: "43" },
    { aimagName: "Ховд", aimagId: "84" },
    { aimagName: "Хөвсгөл", aimagId: "67" },
    { aimagName: "Хэнтий", aimagId: "23" },
    { aimagName: "Төв", aimagId: "41" },
    { aimagName: "Увс", aimagId: "85" },
    { aimagName: "Завхан", aimagId: "81" },
  ];

  let count = 0;
  const [trigger, setTrigger] = useState(false);
  $("input").keypress(function (e) {
    var verified = String.fromCharCode(e.which).match(/[a-zA-Z]/);
    if (verified) {
      e.preventDefault();
      count = count + 1;
      setTrigger(true);
    }
  });

  $("textarea").keypress(function (e) {
    var verified = String.fromCharCode(e.which).match(/[a-zA-Z]/);
    if (verified) {
      e.preventDefault();
      count = count + 1;
      setTrigger(true);
    }
  });
  const navigate = useNavigate();

  const { button, setButton } = useStateContext();
  const location = useLocation();

  // window.addEventListener("popstate", handleBackButton);

  const param = location.pathname.slice(19);
  const channelNo = parseInt(param);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [registerNumber, setRegisterNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [initial, setInitial] = useState(0);
  const [second, setSecond] = useState(0);
  const [last, setLast] = useState(0);
  const [streetDetail, setStreetDetail] = useState("");
  const [fullAdress, setFullAdress] = useState("");
  const [letSecond, setLetSecond] = useState(false);
  const [letLast, setLetLast] = useState(false);
  let dataMain = states;
  const [dataSecond, setDataSecond] = useState();
  const handleSecond = (value) => {
    axios
      .get(
        `${process.env.REACT_APP_ONLINE_ORDER_URL}/v1/address/sumAddress/${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "ZGRfb25saW5lMTpERClPTkxJTkUx",
          },
        }
      )
      .then((res) => {
        if (res.data.isSuccess === false) {
          toast.info(res.data.resultMessage, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          let temp = [
            {
              aimagId: 0,
              sumId: 0,
              sumName: "Сонгох",
            },
          ];
          for (let index = 0; index < res.data.sumInfo.length; index++) {
            const element = res.data.sumInfo[index];
            temp.push({
              aimagId: element.aimagId,
              sumId: element.sumId,
              sumName: element.sumName,
            });
          }
          setDataSecond(temp);
          setSecond(parseInt(res.data.sumInfo[0].sumId));
          setLetSecond(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [dataLast, setDataLast] = useState();
  const handleLast = (val) => {
    axios
      .get(
        `${process.env.REACT_APP_ONLINE_ORDER_URL}/v1/address/bagAddress/${initial}/${val}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "ZGRfb25saW5lMTpERClPTkxJTkUx",
          },
        }
      )
      .then((res) => {
        if (res.data.isSuccess === false) {
          toast.info(res.data.resultMessage, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          let temp = [
            {
              aimagId: 0,
              bagId: 0,
              bagName: "Сонгох",
              sumId: 0,
            },
          ];
          for (let index = 0; index < res.data.bagInfo.length; index++) {
            const element = res.data.bagInfo[index];
            temp.push({
              aimagId: element.aimagId,
              bagId: element.bagId,
              bagName: element.bagName,
              sumId: element.sumId,
            });
          }
          setDataLast(temp);
          setLast(parseInt(res.data.bagInfo[0].sumId));
          setLetLast(true);
          setLast(null);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSelectProduct = (value) => {
    if (value == button) {
      setButton(0);
    } else {
      setButton(value);
    }
  };
  let final = {
    fName: fName,
    lName: lName,
    regNo: registerNumber,
    phoneNo1: parseInt(phone),
    phoneNo2: parseInt(phone2),
    aimagId: initial,
    sumId: second,
    bagId: last,
    streetNo: streetDetail,
    fullAddress: fullAdress,
    bundleId: button,
    channelNo: channelNo,
  };
  const handleSubmit = () => {
    let count = 0;
    if (fName == "") {
      count += 1;
    }
    if (lName == "") {
      count += 1;
    }
    if (registerNumber == "") {
      count += 1;
    }
    if (phone == "") {
      count += 1;
    }
    if (phone2 == "") {
      count += 1;
    }
    if (initial == 0) {
      count += 1;
    }
    if (second == 0) {
      count += 1;
    }
    if (last == 0) {
      count += 1;
    }
    if (streetDetail == "") {
      count += 1;
    }
    if (fullAdress == "") {
      count += 1;
    }
    if (button == 0) {
      count += 1;
    }
    if (channelNo == 0) {
      count += 1;
    }
    if (letSecond == false) {
      count += 1;
    }
    if (letLast == false) {
      count += 1;
    }
    if (last == null) {
      count += 1;
    }
    if (count > 3) {
      toast.info("Та мэдээллээ бүрэн оруулна уу.", {
        position: "top-right",
      });
    } else {
      if (fName == "") {
        toast.info("Овог нэрээ оруулна уу.", {
          position: "top-right",
        });
      }
      if (lName == "") {
        toast.info("Овог нэрээ оруулна уу.", {
          position: "top-right",
        });
      }
      if (registerNumber == "") {
        toast.info("Pегистрийн дугаар оруулна уу.", {
          position: "top-right",
        });
      }
      if (phone == "") {
        toast.info("Утасны дугаар оруулна уу.", {
          position: "top-right",
        });
      }
      if (phone2 == "") {
        toast.info("Холбогдох нэмэлт дугаар оруулна уу", {
          position: "top-right",
        });
      }
      if (initial == 0) {
        toast.info("Хот, Аймаг сонгоно уу.", {
          position: "top-right",
        });
      }
      if (second == 0) {
        toast.info("Дүүрэг, Сум сонгоно уу.", {
          position: "top-right",
        });
      }
      if (last == 0) {
        toast.info("Хороо, Баг сонгон уу.", {
          position: "top-right",
        });
      }
      if (streetDetail == "") {
        toast.info("Гудамж, Тоот бөглөнө үү.", {
          position: "top-right",
        });
      }
      if (fullAdress == "") {
        toast.info("Хүргэлтийн дэлгэрэнгүй хаяг бөглөнө үү.", {
          position: "top-right",
        });
      }
      if (button == 0) {
        toast.info("Та захиалах багц төхөөрөмжөө сонгоно уу.", {
          position: "top-right",
        });
      }
      if (channelNo == 0) {
        toast.info("error: channel unknown !", {
          position: "top-right",
        });
      }
      if (letSecond == false) {
        toast.info("Дүүрэг, Сумын мэдээллийг сонгоно уу !", {
          position: "top-right",
        });
      }
      if (letLast == false) {
        toast.info("Хороо, Багын мэдээллийг сонгоно уу", {
          position: "top-right",
        });
      }
      if (last == null) {
        toast.info("Хороо, Багын мэдээллийг сонгоно уу", {
          position: "top-right",
        });
      }
    }
    if (count === 0) {
      localStorage.setItem("order", JSON.stringify(final));
      // navigate("/online-order-user-form", {
      //   state: final,
      // });
      setShow(true);
    }
  };
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem("order")) {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, []);
  const checkPhone = (val) => {
    if (phone === val) {
      setPhone2("");
      toast.error(
        "Дугааруудын утга давхардаж байна. Та нэмэлт утасны дугаар оруулна уу. ",
        {
          position: "top-right",
        }
      );
    }
  };
  const handlePhoneSet = (event) => {
    const inputVal = event.target.value;
    if (!isNaN(inputVal) && inputVal.length <= 8) {
      setPhone(inputVal);
    }
  };
  const handlePhoneSet2 = (event) => {
    const inputVal = event.target.value;
    if (!isNaN(inputVal) && inputVal.length <= 8) {
      setPhone2(inputVal);
    }
  };
  const [checkRegister, setCheckRegister] = useState(false);
  const [caution, setCaution] = useState(false);
  function isStringValid(str) {
    const regex = /^[\u0400-\u04FF]{2}\d{8}$/;
    if (str.length > 7) {
      if (regex.test(str) === true) {
        setCheckRegister(false);
      } else {
        setCheckRegister(true);
        // setRegisterNumber("");
        setCaution(true);
        toast.error("Та регистерийн дугаараа дахин нягтална уу.", {
          position: "top-right",
        });
      }
    } else {
      setCaution(true);
    }
  }
  const [phoneNoti, setPhoneNoti] = useState(false);
  const [phoneNoti2, setPhoneNoti2] = useState(false);
  const handlePhone = (val) => {
    if (val.length < 8) {
      setPhoneNoti(true);
    }
    if (val.length > 8) {
      setPhoneNoti(true);
    }
  };
  const handlePhone2 = (val) => {
    if (val.length < 8) {
      setPhoneNoti2(true);
    }
    if (val.length > 8) {
      setPhoneNoti2(true);
    }
  };
  return (
    <div className="online-order-container relative">
      {trigger && (
        <div className="top-positioned">Крилл үсгийн фонт ашиглана уу.</div>
      )}
      {show && <OnlineOrderShowForm finalCheck={final} closeForm={setShow} />}
      <div style={{ height: "56px" }}></div>
      <div className="container pt-3 pt-md-5 ">
        <div className="top-positioned-2">
          Анхаарах: Таны хүргэлт Улаанбаатар хот дотор бол 48-72 цагт, Аймгийн
          төв бол 5-7 хоногт, сум суурин бол 7-14 хоногт очих боломжтой.
        </div>
        <div className=" width-100">
          <div className="select-cell mt-3 mt-md-3">
            <h6>
              128GB флаштай багц төхөөрөмж
              <br />
              <span style={{ fontSize: "14px" }}>340'000₮</span>
            </h6>
            <div className="img-container shadow">
              <img
                className="img-product"
                src="https://www.ddishtv.mn:3000/u/device/Bagts%20tuhuurumj%202.jpg"
                alt=""
              />
              <div className="content-container">
                <div className="img-content text-cus3 lineheight-cus">
                  <span className="m-0">
                    Үнэд дараах зүйлс багтсан болно:
                    <br />
                    Тавган антен
                    <br />
                    Хүлээн авагч + смарт карт
                    <br />
                    Флаш диск /128GB/
                    <br />
                    Удирдлага
                    <br />
                    Төмөр толгой
                    <br />
                    LNB /дуран/
                    <br />
                    HDMI
                    <br />
                    10м коаксиал кабел
                    <br />
                    Дурангийн суурь
                    <br />
                    Дурангийн хамгаалалт
                  </span>
                </div>
                {button === 1 ? (
                  <div
                    onClick={() => {
                      handleSelectProduct(1);
                    }}
                    className="img-content-btn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      handleSelectProduct(1);
                    }}
                    className="img-content-btn2"
                  >
                    Сонгох
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="select-cell mt-3 mt-md-3">
            <h6>
              128GB флаштай хүлээн авагч төхөөрөмж <br />
              <span style={{ fontSize: "14px" }}>300'000₮</span>
            </h6>
            <div className="img-container shadow">
              <img
                className="img-product"
                src="https://www.ddishtv.mn:3000/u/device/Intek box bagts.jpg"
                alt=""
              />
              <div className="content-container">
                <div className="img-content text-cus3 lineheight-cus">
                  <span className="m-0">
                    Үнэд дараах зүйлс багтсан болно: <br />
                    Хүлээн авагч + смарт карт
                    <br />
                    Флаш диск /128GB/
                    <br />
                    Удирдлага
                    <br />
                    HDMI
                  </span>
                </div>
                {button === 2 ? (
                  <div
                    onClick={() => {
                      handleSelectProduct(2);
                    }}
                    className="img-content-btn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      handleSelectProduct(2);
                    }}
                    className="img-content-btn2"
                  >
                    Сонгох
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container width-100 relative  pt-2 pt-md-4 pb-1">
        <h5
          style={{
            textAlign: "left",
            width: "100%",
            borderBottom: "1px solid silver",
          }}
        >
          <i>Захиалагчийн мэдээлэл</i>
        </h5>
      </div>
      <div className="container width-100 relative ">
        <div className="select-cell relative">
          <h6>Овог</h6>
          <input
            onChange={(e) => {
              setLName(e.target.value);
              setTrigger(false);
            }}
            value={lName}
            type="text"
            className="text-cus uppercase "
            placeholder="Овог"
          />
        </div>
        <div className="select-cell ">
          <h6>Нэр</h6>
          <input
            onChange={(e) => {
              setFName(e.target.value);
              setTrigger(false);
            }}
            type="text"
            className="text-cus"
            placeholder="Нэр"
          />
        </div>
        <div className="select-cell">
          <h6>Регистрийн дугаар</h6>
          <input
            onChange={(e) => {
              setRegisterNumber(e.target.value);
              setCheckRegister(false);
              setCaution(false);
            }}
            onBlur={(e) => {
              isStringValid(e.target.value);
              // setCaution(false);
            }}
            value={registerNumber}
            type="text"
            className="text-cus "
            placeholder="**11223344"
            style={{
              border: caution ? "1px solid red" : "",
            }}
            maxLength={10}
          />
        </div>
      </div>
      <div className="container width-100">
        <div className="select-cell">
          <h6>Холбоо барих дугаар</h6>
          <input
            onChange={(e) => {
              handlePhoneSet(e);
              // setPhone(e.target.value);
              setPhoneNoti(false);
            }}
            onBlur={(e) => {
              handlePhone(e.target.value);
            }}
            style={{
              border: phoneNoti ? "1px solid red" : "",
            }}
            value={phone}
            type="text"
            className="text-cus"
            placeholder="00*****"
            maxLength={8}
          />
        </div>
        <div className="select-cell ">
          <h6>Холбогдох нэмэлт дугаар</h6>
          <input
            onChange={(e) => {
              handlePhoneSet2(e);
              checkPhone(e.target.value);
              setPhoneNoti2(false);
            }}
            onBlur={(e) => {
              handlePhone2(e.target.value);
            }}
            style={{
              border: phoneNoti2 ? "1px solid red" : "",
            }}
            value={phone2}
            type="text"
            id="phoneNum"
            className="text-cus"
            placeholder="00*****"
            maxLength={8}
          />
        </div>
        <div className="select-cell"></div>
      </div>
      <div className="container width-100 relative  pt-2 pt-md-4 pb-1">
        <h5
          style={{
            textAlign: "left",
            width: "100%",
            borderBottom: "1px solid silver",
          }}
        >
          <i>Хүргэлтийн хаяг</i>
        </h5>
      </div>
      <div className="container width-100 ">
        <div className="select-cell">
          <h6>Хот, Аймаг</h6>
          {/* <Select options={options} className="react-select-container" /> */}
          <select
            onChange={(e) => {
              setInitial(parseInt(e.target.value));
              handleSecond(parseInt(e.target.value));
              setLetLast(false);
            }}
            className="react-select-container"
            name=""
            id=""
          >
            {dataMain?.map((item, index) => (
              <option
                key={index}
                className="myOption"
                value={`${item.aimagId}`}
              >
                {item.aimagName}
              </option>
            ))}
          </select>
        </div>
        {letSecond ? (
          <div className="select-cell">
            <h6>Дүүрэг, Сум</h6>
            {/* <Select options={options} className="react-select-container" /> */}
            <select
              onChange={(e) => {
                setSecond(parseInt(e.target.value));
                handleLast(parseInt(e.target.value));
              }}
              defaultValue={dataSecond[0]}
              className="react-select-container"
              name=""
              id=""
            >
              {dataSecond?.map((item, index) => (
                <option
                  key={JSON.stringify(item)}
                  className="myOption"
                  value={`${item.sumId}`}
                >
                  {item.sumName}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="select-cell">
            <h6>Дүүрэг, Сум</h6>
            {/* <Select options={options} className="react-select-container" /> */}
            <div className="react-select-container-block"></div>
          </div>
        )}

        {letLast ? (
          <div className="select-cell">
            <h6>Хороо, Баг</h6>
            <select
              onChange={(e) => {
                setLast(parseInt(e.target.value));
              }}
              defaultValue={dataLast[0]}
              className="react-select-container"
              name=""
              id=""
            >
              {dataLast?.map((item, index) => (
                <option
                  key={JSON.stringify(item)}
                  className="myOption"
                  value={`${item.bagId}`}
                >
                  {item.bagName}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="select-cell ">
            <h6>Хороо, Баг</h6>
            <div className="react-select-container-block"></div>
          </div>
        )}
      </div>
      <div className="container padding-100 pt-1">
        <div className="input-cell">
          <h6>Гудамж, Тоот</h6>
          {/* <Select options={options} className="react-select-container" /> */}
          <textarea
            onChange={(e) => {
              setStreetDetail(e.target.value);
              setTrigger(false);
            }}
            className="text-area-cus"
            name=""
            id=""
            cols="30"
            rows="3"
            placeholder="..."
          ></textarea>
        </div>
      </div>
      <div className="container width-100">
        <div className="select-cell mt-2">
          <h6>Хүргэлтийн дэлгэрэнгүй хаяг</h6>
          <textarea
            onChange={(e) => {
              setFullAdress(e.target.value);
              setTrigger(false);
            }}
            className="text-area-cus p-3"
            name=""
            id=""
            cols="30"
            rows="6"
            placeholder="Та дэлгэргүй хаягаа бичнэ үү..."
          ></textarea>
        </div>
      </div>
      <div className="container width-100 pb-5">
        <div className="select-cell2 mt-2 pt-5 ">
          <div className="parent-btn">
            <button
              onClick={() => {
                handleSubmit();
              }}
              type="submit"
              className="bubbly-button select-none"
            >
              Үргэлжлүүлэх
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnlineOrderForm;
