// src/Download.js
import React, { useEffect, useState } from "react";
import {
  ListGroup,
  Row,
  Tab,
  Container,
  Carousel,
  Col,
  Card,
} from "react-bootstrap";
const EcontractApp = () => {
  const [downloadStarted, setDownloadStarted] = useState(false);

  useEffect(() => {
    const fileUrl = `${process.env.PUBLIC_URL}/app-release.apk`;

    const downloadFile = async () => {
      try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "app-release.apk";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadStarted(true);
      } catch (error) {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      }
    };

    downloadFile();
  }, []);

  return (
    <div className="p-4">
      <Row>
        <h6 className="mt-5 top-positioned-2">
          Цахим гэрээний apk татах процесс хийгдэж байгаа тул түр хүлээнэ үү...
        </h6>
        <h6 className="mt-2 top-positioned-20">
          Хэрэв асуулга, Анхааруулга таны дэлгэцэд гарч ирсэн бол apk татах
          зөвшөөрөл өгнө үү.
        </h6>
      </Row>
    </div>
  );
};

export default EcontractApp;
