import axios from "axios";
import { useState, useEffect } from "react";
import { Card, Col, Row, ListGroup, ListGroupItem } from "react-bootstrap";
import { webApiUrl, webUrl } from "../../helper/globals";
import { useNavigate } from "react-router";

import Promo from "../../assets/img/branch-map_promo.png";
import "../../style/branch-map.css";

export default function HelpList() {
  const [helps, setHelps] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(webApiUrl + "helps/mn")
      .then((result) => {
        const AllHelps = result.data.result;
        setHelps(AllHelps);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Row xs={1} md={3} className="g-4 section-content">
      {helps.map((e) => (
        <Col>
          <Card
            style={{
              background: "#F7F8FA",
              borderStyle: "none",
              height: "300px",
            }}
            onClick={() => navigate("/helpdesk/" + e.help_id)}
          >
            <Card.Img style={{ width: "20%", marginTop: "20px", marginLeft: "32px" }} variant="top" src={webUrl + e.picture} />
            <Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroupItem
                  className="text-start"
                  style={{
                    background: "#F7F8FA",
                  }}
                >
                  {e.help_title}
                </ListGroupItem>
                <ListGroupItem
                  style={{
                    background: "#F7F8FA",
                  }}
                >
                  <div className="text-start" dangerouslySetInnerHTML={{ __html: e.help_content }} />
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
