import {
  Col,
  Container,
  Row,
  Button,
  Tab,
  Form,
  ListGroup,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import * as actions from "../../actions/product";
import * as userActions from "../../actions/user";
import * as chargeActions from "../../actions/charge";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CHARGE_ACCOUNT, CHARGE_OTHER_ACCOUNT } from "../../actions/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faWallet } from "@fortawesome/free-solid-svg-icons";

function AccountCharge(props) {
  const user = useSelector((state) => state.user);

  const search = useSelector((state) => state.search);
  const navigate = useNavigate();
  const [activeTab, setactiveTab] = useState("#self");
  const [selftab, setsetselftab] = useState(false);
  useEffect(() => {
    props.searchAccount(user?.adminNumber);
  }, [selftab]);
  var chargeAccount = (
    <Container className="section-content">
      <Tab.Container
        id="chargeAccount"
        defaultActiveKey={activeTab}
        className="mb-3"
        onSelect={(k) => {
          setactiveTab(k);
          props.searchClear();
        }}
      >
        <Row>
          <ListGroup className="list-group-horizontal-md">
            <ListGroup.Item
              onClick={() => {
                setsetselftab(!selftab);
              }}
              action
              href="#self"
              className="tabs-title tabs"
            >
              Өөрийн данс цэнэглэх
            </ListGroup.Item>
            <ListGroup.Item action href="#others" className="tabs-title tabs">
              Бусдын данс цэнэглэх
            </ListGroup.Item>
          </ListGroup>
        </Row>
        <Tab.Content>
          <Tab.Pane eventKey="#self" title="Өөрийн данс цэнэглэх">
            {search?.result?.isSuccess ? (
              <Row>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="3">
                    Картын дугаар
                  </Form.Label>
                  <Col sm="5">
                    <Form.Control
                      plaintext
                      readOnly
                      name="cardNo"
                      value={search?.result?.cardNo}
                    />
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      plaintext
                      readOnly
                      name="name"
                      isValid="true"
                      value={
                        search?.result?.lastName +
                        " " +
                        search?.result?.firstName
                      }
                    />
                  </Col>
                </Form.Group>
                <Form
                  style={{ marginTop: "20px" }}
                  onSubmit={(event) => {
                    event.preventDefault();
                    props.selectProduct({
                      orderType: CHARGE_ACCOUNT,
                      product: {
                        productName:
                          user?.cardNo + " дугаартай картын цэнэглэлт",
                      },
                      price: parseInt(event.target.amount.value),
                    });
                    navigate("/order");
                  }}
                >
                  <Row>
                    <Col md="9" style={{ paddingLeft: 0 }}>
                      <Form.Control
                        name="amount"
                        type="number"
                        placeholder="Данс цэнэглэх дүнгээ оруулна уу."
                        aria-label="Данс цэнэглэх дүн"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {search?.err?.resultMessage ?? ""}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={3}>
                      <Row style={{ height: "46px", paddingRight: "12px" }}>
                        <Button type="submit" variant="outline-primary">
                          <FontAwesomeIcon
                            icon={faWallet}
                            style={{ marginRight: "5px" }}
                          />
                          Цэнэглэх
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </Row>
            ) : (
              <Form
                style={{ marginTop: "20px" }}
                id="checkCardForm"
                className={search?.result?.isSuccess ? "" : "has-errors"}
                onSubmit={(event) => {
                  setactiveTab("others");
                  event.preventDefault();
                  props.searchAccount(event.target.cardNo.value);
                }}
                noValidate
              >
                <Row>
                  <Col sm={9} style={{ paddingLeft: 0 }}>
                    <p type="invalid">{search?.err?.resultMessage ?? ""}</p>
                  </Col>
                </Row>
              </Form>
            )}
          </Tab.Pane>
        </Tab.Content>
        <Tab.Content>
          <Tab.Pane eventKey="#others" title="Бусдын данс цэнэглэх">
            {search?.result?.isSuccess ? (
              //Картын дугаар олдсон гэж үзээд цэнэглэх дүнгээ оруулна
              <Form
                style={{ marginTop: "20px" }}
                id="other"
                onSubmit={(event) => {
                  event.preventDefault();
                  props.selectProduct({
                    orderType: CHARGE_OTHER_ACCOUNT,
                    product: {
                      productName:
                        event.target.cardNo.value +
                        " дугаартай картын цэнэглэлт",
                      cardNo: event.target.cardNo.value,
                    },
                    price: parseInt(event.target.amount.value),
                  });
                  navigate("/order");
                }}
              >
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="3">
                    Картын дугаар
                  </Form.Label>
                  <Col sm="5">
                    <Form.Control
                      plaintext
                      readOnly
                      name="cardNo"
                      value={search?.result?.cardNo}
                    />
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      plaintext
                      readOnly
                      name="name"
                      isValid="true"
                      value={
                        search?.result?.lastName +
                        " " +
                        search?.result?.firstName
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Col sm="9" style={{ paddingLeft: 0 }}>
                    <Form.Control
                      name="amount"
                      type="number"
                      placeholder="Цэнэглэх дүнгээ оруулна уу"
                      aria-label="Цэнэглэх дүн"
                      required
                    />
                  </Col>
                  <Col sm={3}>
                    <Row style={{ paddingRight: "15px" }}>
                      <Button
                        type="submit"
                        variant="outline-primary"
                        style={{ height: 46 }}
                      >
                        <FontAwesomeIcon
                          icon={faWallet}
                          style={{ marginRight: "5px" }}
                        />
                        Цэнэглэх
                      </Button>
                    </Row>
                  </Col>
                </Form.Group>
              </Form>
            ) : (
              //Картын дугаар шалгах хэсэг, хэрвээ картаа олбол  дээд талын form руу шилжинэ
              <Form
                style={{ marginTop: "20px" }}
                id="checkCardForm"
                className={search?.result?.isSuccess ? "" : "has-errors"}
                onSubmit={(event) => {
                  setactiveTab("others");
                  event.preventDefault();
                  props.searchAccount(event.target.cardNo.value);
                }}
                noValidate
              >
                <Row>
                  <Col sm={9} style={{ paddingLeft: 0 }}>
                    <Form.Control
                      name="cardNo"
                      type="number"
                      placeholder="Цэнэглэх картын дугаараа оруулна уу"
                      aria-label="Цэнэглэх картын дугаар"
                      isInvalid={!(search == null || search?.isSuccess)}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {search?.err?.resultMessage ?? ""}
                    </Form.Control.Feedback>
                  </Col>
                  <Col sm={3}>
                    <Row style={{ paddingRight: "14px " }}>
                      <Button
                        type="submit"
                        style={{ height: "46px" }}
                        variant="outline-primary"
                      >
                        <FontAwesomeIcon
                          icon={faSearch}
                          style={{ marginRight: "5px" }}
                        />
                        Шалгах
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
  return chargeAccount;
}

const mapActionToProps = (dispatch) => {
  return {
    getProduct: () => dispatch(actions.getProduct()),
    getAdditional: (id) => dispatch(actions.getAdditionalProduct(id)),
    getUpgrade: (id) => dispatch(actions.getUpgradeProduct(id)),
    selectProduct: (product) => dispatch(actions.selectProduct(product)),
    getUserInfo: () => dispatch(userActions.getInfo()),
    searchAccount: (cardNo) => dispatch(chargeActions.searchAccount(cardNo)),
    searchClear: () => dispatch(chargeActions.searchClear()),
  };
};
export default connect(null, mapActionToProps)(AccountCharge);
