import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { getAnonymous } from "../helper/requests";
import spinner from "../assets/img/spinner.svg";
import success from "../assets/img/success.png";
import error from "../assets/img/error.png";
export default function Transaction() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderId, setOrderId] = useState(searchParams.get("invoice"));
  const [statusCode, setStatusCode] = useState(searchParams.get("status_code"));
  const [desc, setDesc] = useState(searchParams.get("desc"));
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [message, setMessage] = useState("");
  const handleClose = () => setShowModal(false);
  const navigate = useNavigate();
  useEffect(() => {
      setIsLoading(true);
      getAnonymous(
        `bankPayment/Success/${orderId}?status_code=${statusCode}&desc=${desc}`,
        (res) => {
          console.log("res.data", res.data);
          setIsSuccess(res.data.isSuccess);
          setMessage(res.data.resultMessage);
          setIsLoading(false);
        },
        (err) => {
          console.log("res.data", err.data);
          setIsSuccess(false);
        }
      );
      console.log("success");
  }, []);
  var successModal = (
    <>
      <Row>
        <Col
          xs={{ span: 8, offset: 2 }}
          style={{ padding: "20px" }}
          className="text-center"
        >
          <img src={success} width={150} />
        </Col>
      </Row>
      <Row>
        <h4 className="text-center">{message}</h4>
      </Row>
    </>
  );
  var errorModal = (
    <>
      <Row>
        <Col
          xs={{ span: 8, offset: 2 }}
          style={{ padding: "20px" }}
          className="text-center"
        >
          <img src={error} width={150} />
        </Col>
      </Row>
      <Row>
        <h4 className="text-center">{desc !== null ? message : desc}</h4>
      </Row>
    </>
  );
  var loadingModal = (
    <>
    <Row>
      <Col
        xs={{ span: 8, offset: 2 }}
        style={{ padding: "20px" }}
        className="text-center"
      >
        <img src={spinner} width={150} />
      </Col>
    </Row>
    <Row>
      <h4 className="text-center">Таны хүсэлтийг боловсруулж байна.</h4>
    </Row>
  </>
  );
  return (
    <Container className="section-content">
      <Row>
        <Col>
          <h3>Гүйлгээний баримт:</h3>
          <h5>Гүйлгээний дугаар: {orderId}</h5>
          <h5>Үр дүн: {message}</h5>
          <Modal show={showModal} onHide={handleClose} centered className="rounded-modal">
            <Modal.Body>
              {statusCode === "000" && isLoading
                ? loadingModal
                : statusCode === "000" && isSuccess
                ? successModal
                : errorModal}
            </Modal.Body>
          </Modal>
          <Button
            onClick={() => {
              navigate("/");
            }}
          >
            Буцах
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
