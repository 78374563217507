import { Form, Field } from "react-final-form";
import * as authActions from "../../actions/auth";
import { connect } from "react-redux";
import { Modal, ModalBody } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router";

function LoginForm(props) {
  const [forgotShow, setForgotShow] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Form
        onSubmit={(values) => {
          let s = props.login(
            { ...values, redirect: props.redirect },
            navigate
          );
          if (s === "success") navigate("/");
        }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="username"
              type="text"
              component="input"
              placeholder="Админ дугаар"
            />
            <Field
              name="password"
              type="password"
              component="input"
              placeholder="Нууц үг"
            />
            <a
              onClick={() => {
                setForgotShow(true);
              }}
              href
              style={{ color: "#b0b0b0", fontSize: "14" }}
            >
              Нууц үгээ мартсан уу?
            </a>
            <button
              type="submit"
              className="button success small-12 columns mar-top-30 mar-btm-30"
              disabled={submitting}
            >
              НЭВТРЭХ
            </button>
          </form>
        )}
      </Form>
      <Modal
        centered
        show={forgotShow}
        onHide={() => {
          setForgotShow(false);
        }}
      >
        <Modal.Header closeButton>
          <h4>Санамж</h4>
        </Modal.Header>
        <ModalBody>
          <p className="text-justify">
            Та нэвтрэх нууц үгээ мартсан эсвэл Нэвтрэх нууц үгээ авахыг хүсвэл
            ДДЭШ-н <b>Админ</b> эсвэл <b>Гишүүн</b> дугаараасаа <b>139898</b>
            &nbsp;дугаарт <b>CODE</b> гэж илгээнэ үү. Хэрвээ ДДЭШ-н админ утасны
            дугааргүй бол <b>139898</b>&nbsp;дугаарт <b>Burtgeh</b> зай аваад{" "}
            <b>смарт картын дугаараа </b>бичиж илгээнэ үү.
          </p>
          <button
            onClick={() => {
              setForgotShow(false);
            }}
            className="button success small-12 columns mar-top-30 mar-btm-30"
          >
            Хаах
          </button>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload, navigate) =>
      dispatch(authActions.login(payload, navigate)),
  };
};
export default connect(null, mapDispatchToProps)(LoginForm);
