import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Tab,
  ListGroup,
} from "react-bootstrap";
import * as actions from "../../actions/product";
import * as userActions from "../../actions/user";
import * as chargeActions from "../../actions/charge";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CHARGE_OTHER_ACCOUNT_NOLOGIN,
  CHARGE_OTHER_PRODUCT,
  CHARGE_OTHER_PRODUCT_NOLOGIN,
} from "../../actions/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

import { getAnonymous } from "../../helper/requests";

function AccountCharge(props) {
  const search = useSelector((state) => state.search);
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  var accountCharge = (
    <>
      <Form
        style={{ marginTop: "20px" }}
        id="other"
        onSubmit={(event) => {
          event.preventDefault();
          props.selectProduct({
            orderType: CHARGE_OTHER_ACCOUNT_NOLOGIN,
            product: {
              productName:
                search?.result?.cardNo + " дугаартай картын цэнэглэлт",
              cardNo: search?.result?.cardNo,
            },
            isVat: event.target.isVat.checked,
            email: event.target.email.value,
            deviceImei: "web",
            price: parseInt(event.target.amount.value),
          });
          navigate("/order");
        }}
      >
        <Form.Group as={Row} className="mb-3">
          <Col sm="9" style={{ paddingLeft: "0" }}>
            <Form.Control
              name="amount"
              type="number"
              placeholder="Цэнэглэх дүнгээ оруулна уу."
              aria-label="Цэнэглэх дүн"
              required
            />
          </Col>
          <Col>
            <Row style={{ padding: "0 12px" }}>
              <Button
                type="submit"
                style={{ height: 46 }}
                variant="outline-primary"
              >
                <FontAwesomeIcon
                  icon={faWallet}
                  style={{ marginRight: "5px" }}
                />
                Цэнэглэх
              </Button>
            </Row>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Col sm={{ span: 3, offset: 3 }}>
            <Form.Check
              name="isVat"
              type="checkbox"
              label="НӨАТ-ын баримт авах эсэх"
              onClick={(values) => {
                document.getElementById("email").disabled =
                  !values.target.checked;
              }}
            />
          </Col>
          <Col sm={6}>
            <Form.Control
              id="email"
              name="email"
              type="email"
              disabled={true}
              placeholder="Баримт явуулах цахим хаяг"
              aria-label="Баримт явуулах цахим хаяг"
            />
          </Col>
        </Form.Group>
      </Form>
    </>
  );
  function productCharge() {
    if (search.result.cardNo) {
      let cardNo = search.result.cardNo;
      if (!isLoaded) {
        getAnonymous(
          `productlist/prm/nologin/${cardNo}/4`,
          (res) => {
            setPackages(res.data.productList);
            setSelectedPackage(res.data.productList[0]);
            setIsLoaded(true);
          },
          (err) => {
            setPackages([]);
          }
        );
      }
    }
    return (
      <Form
        style={{ marginTop: "20px" }}
        onSubmit={(e) => {
          e.preventDefault();
          props.selectProduct({
            ...JSON.parse(e.target.product.value),
            isVat: e.target.isVat.checked,
            email: e.target.email.value,
          });
          navigate("/order");
        }}
      >
        <Row>
          <Col md="4">
            <Form.Select
              name="package"
              onChange={(s) => {
                setSelectedPackage(JSON.parse(s.target.value));
              }}
            >
              {packages?.map((e) => {
                return (
                  <option value={JSON.stringify(e)}>{e.productName}</option>
                );
              })}
            </Form.Select>
          </Col>
          <Col md="4">
            <Form.Select name="product">
              {selectedPackage?.priceList?.map((e, indexCus) => {
                return (
                  <option
                    value={JSON.stringify({
                      orderType: CHARGE_OTHER_PRODUCT_NOLOGIN,
                      product: {
                        productName: selectedPackage.productName,
                        productId: selectedPackage.productId,
                        productImg: selectedPackage.productImg,
                        selected: e,
                      },
                      cardNo: search.result.cardNo,
                      price:
                        e.promoId === "" || e.promoId === null
                          ? e.price
                          : e.promoPrice,
                    })}
                    key={e.month}
                  >
                    {/* {e.days} хоног{" "} */}
                    {(indexCus + 1) * 30} хоног{" "}
                    {e.promoId === "" || e.promoId === null
                      ? e.price
                      : e.promoPrice}
                    ₮
                  </option>
                );
              })}
            </Form.Select>
          </Col>
          <Col>
            <Row style={{ padding: " 0 12px" }}>
              <Button
                type="submit"
                variant="outline-primary"
                style={{ height: 46 }}
              >
                Сонгох
              </Button>
            </Row>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Row} className="mb-3">
            <Col sm={{ span: 3, offset: 3 }}>
              <Form.Check
                name="isVat"
                type="checkbox"
                label="НӨАТ-ын баримт авах эсэх"
                onClick={(values) => {
                  document.getElementById("email").disabled =
                    !values.target.checked;
                }}
              />
            </Col>
            <Col sm={6}>
              <Form.Control
                id="email"
                name="email"
                type="email"
                disabled={true}
                placeholder="Баримт явуулах цахим хаяг"
                aria-label="Баримт явуулах цахим хаяг"
              />
            </Col>
          </Form.Group>
        </Row>
      </Form>
    );
  }
  var noLogin = (
    <Container className="section-content" fluid>
      {search?.result?.isSuccess ? (
        <>
          <Row className="text-center">
            <Col>
              <h4>Картын дугаар: {search?.result?.cardNo}</h4>
            </Col>
          </Row>
          <Row className="text-center" style={{ marginBottom: "" }}>
            <h4>
              Хэрэглэгч:{" "}
              {search?.result?.lastName + " " + search?.result?.firstName}
            </h4>
          </Row>
          <Row className="text-center" style={{ marginBottom: "50px" }}>
            <p>
              Санамж: НӨАТ-ын баримт зөвхөн хувь хүн дээр гарна. Та байгууллага
              дээр НӨАТ авах бол бусад үйлчилгээг ашиглана уу.
            </p>
          </Row>
          <Row>
            <Tab.Container defaultActiveKey="#product">
              <ListGroup className="list-group-horizontal-md">
                <ListGroup.Item
                  action
                  href="#product"
                  className="tabs-title tabs"
                >
                  БАГЦ СУНГАХ
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href="#account"
                  className="tabs-title tabs"
                >
                  ДАНС ЦЭНЭГЛЭХ
                </ListGroup.Item>
              </ListGroup>
              <Tab.Content>
                <Tab.Pane eventKey="#product">{productCharge()}</Tab.Pane>
                <Tab.Pane eventKey="#account">{accountCharge}</Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Row>
        </>
      ) : (
        //Картын дугаар шалгах хэсэг, хэрвээ картаа олбол  дээд талын form руу шилжинэ
        <Form
          id="checkCardForm"
          className={search?.result?.isSuccess ? "" : "has-errors"}
          onSubmit={(event) => {
            event.preventDefault();
            props.searchAccount(event.target.cardNo.value);
          }}
          noValidate
        >
          <Form.Group as={Row} className="mb-3">
            <Col sm="9">
              <Form.Control
                name="cardNo"
                type="number"
                placeholder="Админ эсвэл картын дугаар оруулах"
                aria-label="Админ эсвэл картын дугаар"
                isInvalid={!(search == null || search?.isSuccess)}
                required
              />
              <Form.Control.Feedback type="invalid">
                {search?.err?.resultMessage ?? ""}
              </Form.Control.Feedback>
            </Col>
            <Col sm="3">
              <Row>
                <Button
                  type="submit"
                  style={{ height: 46 }}
                  variant="outline-primary"
                >
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{ marginRight: "5px" }}
                  />
                  Шалгах
                </Button>
              </Row>
            </Col>
          </Form.Group>

          <br />
          <Form.Group as={Row} className="mb-3">
            <Col sm={{ span: 10 }}></Col>
          </Form.Group>
        </Form>
      )}
    </Container>
  );
  return noLogin;
}

const mapActionToProps = (dispatch) => {
  return {
    getProduct: () => dispatch(actions.getProduct()),
    getAdditional: (id) => dispatch(actions.getAdditionalProduct(id)),
    getUpgrade: (id) => dispatch(actions.getUpgradeProduct(id)),
    selectProduct: (product) => dispatch(actions.selectProduct(product)),
    getUserInfo: () => dispatch(userActions.getInfo()),
    searchAccount: (cardNo) => dispatch(chargeActions.searchAccount(cardNo)),
    searchClear: () => dispatch(chargeActions.searchClear()),
  };
};
export default connect(null, mapActionToProps)(AccountCharge);
