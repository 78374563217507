import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
function OnlineOrderCheck() {
  const navigate = useNavigate();
  const [number, setNumber] = useState();
  const [data, setData] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .get(
        `${process.env.REACT_APP_ONLINE_ORDER_URL}/v1/OrderItem/orderCheck/${number}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "ZGRfb25saW5lMTpERClPTkxJTkUx",
          },
        }
      )
      .then((res) => {
        if (res.data.isSuccess === false) {
          toast.info(res.data.resultMessage, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          setData(res.data.orderInfo);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [value, setValue] = useState(0);
  return (
    <div className="online-order-container bg-cus">
      <ToastContainer />
      <div style={{ height: "67px" }}></div>
      <div className="container check-100 ">
        <div className="blur-cus"></div>
        <div className="check-header child-z mt-auto">
          {/* <div className="mt-5 mb-5 pb-5 pt-10"></div> */}
          <div className="mt-5"></div>
          <div className="mt-5"></div>
          <h5
            style={{
              textAlign: "center",
              width: "100%",
            }}
            className={` mt-5 text-white transition ${data && "hidden"}`}
          >
            Та захиалга өгсөн утасны дугаар эсвэл захиалгын кодоо оруулна уу
          </h5>
          <form
            action=""
            onSubmit={(e) => {
              handleSubmit(e);
            }}
            className={` ${data && "hidden"}`}
          >
            <input
              onChange={(e) => {
                setNumber(e.target.value);
              }}
              type="text"
              style={{
                textAlign: "center",
              }}
              className="input-cus text-cus2 shadow-sm mt-2"
              autoFocus
            />

            <div className="parent-btn">
              <button type="submit" className="bubbly-button">
                Шалгах
              </button>
            </div>
          </form>
          <div>
            {data && data.length > 1 && (
              <ToggleButtonGroup type="checkbox" value={value}>
                {value > 0 ? (
                  <ToggleButton
                    onChange={() => {
                      if (value > 0) {
                        setValue(value - 1);
                      }
                    }}
                    id="tbg-btn-1"
                    value={1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-arrow-left-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                      />
                    </svg>
                  </ToggleButton>
                ) : (
                  <div
                    style={{
                      width: "53px",
                      height: "54px",
                    }}
                  ></div>
                )}
                {data?.length - 1 !== value ? (
                  <ToggleButton
                    onChange={() => {
                      setValue(value + 1);
                    }}
                    id="tbg-btn-2"
                    value={2}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-arrow-right-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                      />
                    </svg>
                  </ToggleButton>
                ) : (
                  <div
                    style={{
                      width: "53px",
                      height: "54px",
                    }}
                  ></div>
                )}
              </ToggleButtonGroup>
            )}
          </div>
          {data &&
            data?.map((el, ind) => (
              <div
                key={ind}
                className={`body-cus ${value === ind ? "" : "hidden"}`}
              >
                <div
                  style={{
                    width: "300px",
                    height: "360px",
                    background: "#5fa0b8",
                    borderRadius: "10px",
                    padding: "20px",
                    color: "white",
                    overflow: "scroll",
                    overflowX: "hidden",
                  }}
                  className="pseudo-parallax-card-container "
                >
                  <p className="mb-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-dot"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                    </svg>
                    Захиалгын код: <span>{el.orderCode}</span>
                  </p>
                  <p className="mb-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-dot"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                    </svg>
                    Утас: <span>{el.phone}</span>
                  </p>
                  <p className="mb-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-dot"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                    </svg>
                    Захиалгын хаяг: <span>{el.shipAddress}</span>
                  </p>
                  <p className="mb-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-dot"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                    </svg>
                    Хүргэлтийн хугацаа: <span>{el?.shipTime}</span>
                  </p>
                  <p className="">
                    Статус: <span>{el.status}</span>
                  </p>
                  <p className="mb-0" style={{ textAlign: "center" }}>
                    Хүргэлттэй холбоотой дэлгэрэнгүй мэдээллийг 18001613
                    дугаараас дугаараа хэлж шалгуулах боломжтой
                  </p>
                </div>
              </div>
            ))}
          <button
            onClick={() => {
              navigate("/");
            }}
            className="bubbly-button6 mt-3"
          >
            Буцах
          </button>
        </div>
        <div className="mt-5"></div>
      </div>
    </div>
  );
}

export default OnlineOrderCheck;
