import { useLocation } from "react-router";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Deal from "./Deal";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useWindowDimensions from "./GetWindowDimensions";

function OnlinePayment() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setShow(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const [show, setShow] = useState(false);
  const { width } = useWindowDimensions();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();
  const base = location?.state?.qpay?.qrImageUrl;
  const invoice = location?.state?.qpay.invoiceNo;
  const orderCode = location?.state?.invoice;
  const channel = location?.state?.channel;
  const price = location?.state?.bundle === 1 ? "340'000" : "300'000";
  const app = location?.state?.qpayUrl;
  useEffect(() => {
    const id = setInterval(async () => {
      axios
        .get(
          `https://my.ddishtv.mn/api/bankPayment/CheckTransaction/${invoice}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "YGHM9SHBC81LMR4G",
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.isSuccess == true) {
            setModalShow(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 3000);
    return () => {
      clearInterval(id);
    };
  }, []);

  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate("/");
  };
  if (location.pathname === "/online-payment") {
    window.addEventListener("popstate", handleBackButton);
  }
  return (
    <div
      style={{
        height: "",
      }}
      className="online-order-container-main relative "
    >
      <>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Body
            style={{
              paddingBottom: "0",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="#0047bb"
                className="bi bi-exclamation-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
              </svg>
            </div>
            <h6
              style={{
                textAlign: "center",
              }}
            >
              <i>
                Төлбөр төлөх хугацаа 30мин бөгөөд төлбөр төлөгдөөгүй тохиолдолд
                захиалга цуцлагдана.
              </i>
            </h6>
          </Modal.Body>
          <Modal.Footer
            style={{
              margin: "0",
              paddingTop: "0",
            }}
          >
            <Button
              variant="primary"
              onClick={handleClose}
              style={{
                fontSize: "14px",
              }}
            >
              <i>Хаах</i>
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <div style={{ height: "60px" }}></div>
      <div className="container width-102  ">
        <div className="center2">
          <h6>Үнийн дүн: {price}₮</h6>
          <h6>Захиалгын код: {orderCode}</h6>
          {width < 768 && (
            <a
              target={"_blank"}
              onClick={() => {
                window.open(`${app}`, "_blank");
              }}
              className="custom-btn btn-1"
            >
              Application нээх
            </a>
          )}
          <img
            className="select-none mt-5"
            src={`data:image/jpeg;base64,${base ? base : null}`}
            alt="qr image"
          />
        </div>
      </div>

      <>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          channel={channel}
          orderCode={orderCode}
        />
      </>
    </div>
  );
}

export default OnlinePayment;

function MyVerticallyCenteredModal(props) {
  const [checked, setChecked] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [org, setOrg] = useState("");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const handleE = (el) => {
    if (el == "I") {
      setShowInput(true);
    } else {
      setOrg("");
      setShowInput(false);
    }
  };
  let body = {
    orderCode: props.orderCode,
    channelNo: `${props.channel}`,
    organizationNo: "",
  };
  const makeTransaction = () => {
    axios
      .post(
        `${process.env.REACT_APP_ONLINE_ORDER_URL}/v1/OrderItem/orderConfirm`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "ZGRfb25saW5lMTpERClPTkxJTkUx",
          },
        }
      )
      .then(function (response) {
        if (response.data.resultMessage !== "Амжилттай") {
          toast.info(`${response.data.resultMessage}`, {
            position: "top-right",
          });
        } else {
          navigate("/online-order-contract", {
            state: response.data,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h5>ХИЙМЭЛ ДАГУУЛЫН ТЕЛЕВИЗИЙН ҮЙЛЧИЛГЭЭНИЙ ГЭРЭЭ</h5>
        <div className="deal-container">
          <Deal />
        </div>
        <div className="checkbox-container">
          <div className="custom">
            <label
              style={{
                fontSize: "13px",
                textTransform: "uppercase",
              }}
              htmlFor=""
            >
              <input
                type="checkbox"
                className="checkbox"
                onChange={() => {
                  setChecked(!checked);
                }}
              />
              Гэрээний нөхцөлтэй танилцсан
            </label>
          </div>
          {checked ? (
            <button
              onClick={() => {
                makeTransaction();
              }}
              className="bubbly-button6"
            >
              Үргэлжлүүлэх
            </button>
          ) : (
            <button
              onClick={() => {
                toast.info(
                  "Үргэлжлүүлэхийн тулд гэрээний нөхцөлтэй танилцсан цэсийг бөглөнө үү.",
                  {
                    position: "top-right",
                  }
                );
              }}
              className="bubbly-button7"
            >
              Үргэлжлүүлэх
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
