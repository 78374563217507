import {
  ORDER_START,
  ORDER_GET_ORDER_ID,
  ORDER_SUCCESS,
  ORDER_ERROR,
  ORDER_CLEAR,
  ORDER_LOAD,
  QPAY_CREATE_ORDER,
  LOGOUT,
} from "../actions/types";

const initialState = null;
const orderReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_START: {
      return {
        ...state,
        order: payload.order,
        method: payload.order.method,
        product: payload.order.product,
        loading: true,
      };
    }
    case QPAY_CREATE_ORDER: {
      return {
        ...state,
        loading: false,
      };
    }
    case ORDER_LOAD: {
      return {
        ...payload,
      };
    }

    case ORDER_GET_ORDER_ID: {
      return {
        ...state,
        orderId: payload.orderId,
        qpay: payload.qpay,
        savedCards: payload.savedCards,
        paymentUrl: payload.paymentUrl,
      };
    }
    case ORDER_ERROR: {
      return {
        ...state,
        isSuccess: false,
      };
    }
    case ORDER_SUCCESS: {
      return {
        ...state,
        isSuccess: payload.isSuccess,
        loading: false,
      };
    }

    case ORDER_CLEAR: {
      return null;
    }

    case LOGOUT: {
      return null;
    }
    default:
      return state;
  }
};

export default orderReducer;
