export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const LOGIN_REFRESH_SUCCESS = "LOGIN_REFRESH_SUCCESS";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const GET_INFO = "GET_INFO";
export const GET_INFO_SUCCESS = "GET_INFO_SUCCESS";
export const GET_INFO_FAIL = "GET_INFO_FAIL";

export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_FAIL = "GET_ACCOUNT_FAIL";
export const GET_ID = "GET_ID";

export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL";

export const GET_ADDITIONAL_PRODUCT = "GET_ADDITIONAL_PRODUCT";
export const GET_ADDITIONAL_PRODUCT_SUCCESS = "GET_ADDITIONAL_PRODUCT_SUCCESS";
export const GET_ADDITIONAL_PRODUCT_FAIL = "GET_ADDITIONAL_PRODUCT_FAIL";

export const GET_UPGRADE_PRODUCT = "GET_UPGRADE_PRODUCT";
export const GET_UPGRADE_PRODUCT_SUCCESS = "GET_UPGRADE_PRODUCT_SUCCESS";
export const GET_UPGRADE_PRODUCT_FAIL = "GET_UPGRADE_PRODUCT_FAIL";

export const SELECT_PRODUCT = "SELECT_PRODUCT";
export const CLEAR_PRODUCT = "CLEAR_PRODUCT";

export const SET_ROUTE = "SET_ROUTE";
export const CLEAR_ROUTE = "CLEAR_ROUTE";

export const CHARGE_BEGIN = "CHARGE_BEGIN";
export const CHARGE_SUCCESS = "CHARGE_SUCCESS";
export const CHARGE_FAIL = "CHARGE_FAIL";

export const UPGRADE_BEGIN = "UPGRADE_BEGIN";
export const UPGRADE_SUCCESS = "UPGRADE_SUCCESS";
export const UPGRADE_FAIL = "UPGRADE_FAIL";

export const MERCHANT_PAYMENT = "MERCHANT_PAYMENT";
export const QPAY_PAYMENT = "QPAY_PAYMENT";

export const UPOINT_PAYMENT = "UPOINT_PAYMENT";

export const ACCOUNT_PAYMENT = "ACCOUNT_PAYMENT";

export const CHARGE_PRODUCT = {
  code: "1001",
  methods: [ACCOUNT_PAYMENT, QPAY_PAYMENT, MERCHANT_PAYMENT, UPOINT_PAYMENT],
  text: "Багц идэвхижүүлэх",
};
export const CHARGE_OTHER_PRODUCT = {
  code: "1002",
  methods: [QPAY_PAYMENT, MERCHANT_PAYMENT],
  text: "Багц идэвхижүүлэх",
};
export const CHARGE_NVOD = {
  code: "1004",
  methods: [ACCOUNT_PAYMENT, QPAY_PAYMENT, MERCHANT_PAYMENT, UPOINT_PAYMENT],
  text: "ШИНЭ КИНО /800р суваг/",
};
export const CHARGE_ACCOUNT = {
  code: "1007",
  methods: [QPAY_PAYMENT, MERCHANT_PAYMENT, UPOINT_PAYMENT],
  text: "Данс цэнэглэх",
};
export const CHARGE_OTHER_ACCOUNT = {
  code: "1010",
  methods: [QPAY_PAYMENT, MERCHANT_PAYMENT, UPOINT_PAYMENT],
  text: "Данс цэнэглэх",
};
export const UPGRADE_PRODUCT = {
  code: "1013",
  methods: [ACCOUNT_PAYMENT, QPAY_PAYMENT, MERCHANT_PAYMENT],
  text: "Багц ахиулах",
};
export const CHARGE_LIVE = {
  code: "2020",
  methods: [ACCOUNT_PAYMENT, QPAY_PAYMENT, MERCHANT_PAYMENT, UPOINT_PAYMENT],
  text: "Live захиалах",
};
export const CHARGE_OTHER_ACCOUNT_NOLOGIN = {
  code: "1017",
  methods: [QPAY_PAYMENT, MERCHANT_PAYMENT],
  text: "Данс цэнэглэх",
};
export const CHARGE_OTHER_PRODUCT_NOLOGIN = {
  code: "2026",
  methods: [QPAY_PAYMENT, MERCHANT_PAYMENT],
  text: "Багц сунгах",
};
export const CHARGE_RVOD = {
  code: "CHARGE_RVOD",
  methods: [ACCOUNT_PAYMENT],
  text: "Кино сангаас кино захиалах",
};

export const MERCHANT_ORDER = "MERCHANT_ORDER";

export const KHAN_BANK = "KhanBank";
export const GOLOMT_BANK = "GolomtBank";
export const XAC_BANK = "XacBank";
export const STATE_BANK = "StateBank";
export const CARD = "BankCard";
export const QPAY = "QPay";
export const SOCIAL_PAY = "SocialPay";
export const UPOINT = "UPoint";

export const ORDER_START = "ORDER_START";
export const ORDER_LOAD = "ORDER_LOAD";

export const ORDER_GET_ORDER_ID = "ORDER_GET_ORDER_ID";
export const ORDER_ERROR = "ORDER_ERROR";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_CLEAR = "ORDER_CLEAR";
export const QPAY_CLEAR = "QPAY_CLEAR";

export const KHAN_CREATE_ORDER = "KHAN_CREATE_ORDER";
export const KHAN_RESULT = "KHAN_RESULT";
export const GOLOMT_RESULT = "GOLOMT_RESULT";
export const XAC_RESULT = "XAC_RESULT";

export const LOAD_ORDER = "LOAD_ORDER";

export const SEARCH_ACCOUNT = "SEARCH_ACCOUNT";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_ERROR = "SEARCH_ERROR";
export const SEARCH_CLEAR = "SEARCH_CLEAR";

export const GET_CHANNELS = "GET_CHANNELS";
export const GET_CHANNELS_SUCCESS = "GET_CHANNELS_SUCCESS";
export const GET_CHANNELS_ERROR = "GET_CHANNELS_ERROR";

export const GET_MOVIES = "GET_MOVIES";
export const GET_MOVIES_SUCCESS = "GET_MOVIES_SUCCESS";
export const GET_MOVIES_ERROR = "GET_MOVIES_ERROR";

export const GET_MOVIE = "GET_MOVIE";
export const GET_MOVIE_SUCCESS = "GET_MOVIE_SUCCESS";
export const GET_MOVIE_ERROR = "GET_MOVIE_ERROR";

export const GET_LIVE = "GET_LIVE";
export const GET_LIVE_ERROR = "GET_LIVE_ERROR";

export const GET_NEW_RVOD = "GET_NEW_RVOD";
export const GET_NEW_RVOD_ERROR = "GET_NEW_RVOD";

export const GET_NOTT = "GET_NOTT";
export const GET_NOTT_ERROR = "GET_NOTT_ERROR";

export const SEARCH_RVOD = "SEARCH_RVOD";
export const SEARCH_RVOD_SUCCESS = "SEARCH_RVOD_SUCCESS";
export const SEARCH_RVOD_ERROR = "SEARCH_RVOD_ERROR";

export const ORDER_RVOD_BEGIN = "ORDER_RVOD_BEGIN";
export const ORDER_RVOD_SUCCESS = "ORDER_RVOD_SUCCESS";
export const ORDER_RVOD_ERROR = "ORDER_RVOD_ERROR";

export const ORDER_LIVE_BEGIN = "ORDER_LIVE_BEGIN";
export const ORDER_LIVE_SUCCESS = "ORDER_LIVE_SUCCESS";
export const ORDER_LIVE_ERROR = "ORDER_LIVE_ERROR";

export const ORDER_NVOD_BEGIN = "ORDER_NVOD_BEGIN";
export const ORDER_NVOD_SUCCESS = "ORDER_NVOD_SUCCESS";
export const ORDER_NVOD_ERROR = "ORDER_NVOD_ERROR";

export const QPAY_CREATE_ORDER = "QPAY_CREATE_ORDER";

export const TOAST_INFO = "info";
export const TOAST_NORMAL = "";
export const TOAST_ERROR = "error";
export const TOAST_SUCCESS = "success";
export const TOAST_WARNING = "warning";

export const GET_DISTRICT = "GET_DISTRICT";
export const GET_KHOROO = "GET_KHOROO";
export const GET_EQUIPMENT = "GET_EQUIPMENT";
export const CEC_SUCCESS = "CEC_SUCCESS";

export const POSTPAID = "POSTPAID";
export const PREPAID = "PREPAID";
