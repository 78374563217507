import { Row } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";

export default function Breadcrumbs(props) {
  let lang = props.language ?? "mn";
  return (
    <div className="page-content">
      <div className="page-banner">
        <Row>
          <div className="desc-box">
            <h2>{props.title}</h2>
            <Breadcrumb className="breadcrumbs">
              <Breadcrumb.Item href="/">
                {lang === "mn" ? "Нүүр хуудас" : "Home"}
              </Breadcrumb.Item>
              {props.route.map((e, i) => {
                return (
                  <Breadcrumb.Item key={i} href={e.url}>
                    {e.text}
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
          </div>
        </Row>
      </div>
      <div className="margin-top">{props.content}</div>
    </div>
  );
}
