import "bootstrap/dist/css/bootstrap.min.css";
import "./style/custom.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { ContextProvider } from "./context/ContextProvider";
// import Snowfall from "react-snowfall";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
ReactDOM.render(
  <ContextProvider>
    <Provider store={store}>
      {/* <Snowfall
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        zIndex: "100",
      }}
    /> */}
      <App />
    </Provider>
  </ContextProvider>,

  document.getElementById("root")
);
