import { get, getSecured } from "../helper/requests";
import { setMessage } from "./messages";
import {
  GET_NEW_RVOD,
  GET_NEW_RVOD_ERROR,
  SEARCH_RVOD_ERROR,
  SEARCH_RVOD_SUCCESS,
  TOAST_INFO,
} from "./types";

export const getNewArrivals = () => {
  return function (dispatch) {
    get(
      "pushVod",
      (res) => {
        dispatch({
          type: GET_NEW_RVOD,
          payload: res.data,
        });
      },
      (err) => {
        dispatch({
          type: GET_NEW_RVOD_ERROR,
          payload: err.data,
        });
      }
    );
  };
};

export const searchContent = (contentId) => {
  return function (dispatch) {
    getSecured(
      `searcharvod/prm/${contentId}`,
      (res) => {
        dispatch({
          type: SEARCH_RVOD_SUCCESS,
          payload: { ...res.data, contentId: contentId },
        });
      },
      (err) => {
        dispatch(
          setMessage({
            type: TOAST_INFO,
            message: "Таны хайсан контент олдсонгүй",
          })
        );
        dispatch({
          type: SEARCH_RVOD_ERROR,
          payload: err,
        });
      }
    );
  };
};
