import axios from "axios";
import { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
  Pagination,
  Container,
  PageItem,
  Tab,
} from "react-bootstrap";
import { webApiUrl, webUrl } from "../../helper/globals";
import dateFormat from "dateformat";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
var h2p = require("html2plaintext");
export default function PostList() {
  const [news, setNews] = useState([]);
  const [content, setContent] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [tab, setTab] = useState(" МЭДЭЭЛЭЛ");
  const itemsPerPage = 9;
  const navigate = useNavigate();
  var items = (pageCount) => {
    let list = [];
    let count = Math.ceil(pageCount / itemsPerPage);

    for (let i = 1; i <= count; i++) {
      list.push(
        <PageItem
          key={i}
          active={i === activePage}
          onClick={(s) => {
            setActivePage(parseInt(s.target.text));
          }}
        >
          {i}
        </PageItem>
      );
    }
    return list;
  };
  useEffect(() => {
    axios
      .get(webApiUrl + `press/${activePage}/${itemsPerPage}`)
      .then((result) => {
        const AllNews = result.data.result.press;
        setTotalCount(result.data.result.total);
        setNews(AllNews);
      })
      .catch((err) => console.log(err));
  }, [activePage]);
  useEffect(() => {
    axios
      .get(webApiUrl + `content`)
      .then((result) => {
        let container = [];
        const AllContent = result.data.result;
        for (let i = 0; i < AllContent.length; i++) {
          const element = AllContent[i];
          if (element.is_active == "1") {
            container.push(element);
          }
        }
        setContent(container);
      })
      .catch((err) => console.log(err));
  }, [activePage]);
  return (
    <Container className="section-content">
      <Tab.Container activeKey={tab} onSelect={(k) => setTab(k)}>
        <Container fluid className="section-content">
          <Row>
            <ListGroup className="list-group-horizontal-md">
              <ListGroup.Item
                action
                eventKey=" МЭДЭЭЛЭЛ"
                href
                className="tabs-title tabs"
              >
                МЭДЭЭЛЭЛ
              </ListGroup.Item>
              <ListGroup.Item
                action
                eventKey="КОНТЕНТ"
                href
                className="tabs-title tabs"
              >
                КОНТЕНТ
              </ListGroup.Item>
            </ListGroup>
          </Row>
        </Container>
        <Tab.Content style={{ padding: "0px 12 px" }}>
          <Tab.Pane eventKey=" МЭДЭЭЛЭЛ" fluid>
            <Row xs={1} md={3} className="g-4 ">
              {news.map((e) => (
                <Col>
                  <Card style={{ height: "380px", backgroundColor: "#F3F4F6" }}>
                    <Card.Img
                      variant="top"
                      src={webUrl + e.picture}
                      style={{ height: "160px" }}
                      onClick={() => navigate("/press/" + e.post_id)}
                    />
                    <Card.Body
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // flexWrap: "wrap",
                        justifyContent: "flex-end",
                        alignItems: "stretch",
                        alignContent: "center",
                      }}
                    >
                      <Card.Title>
                        <Link
                          to={"/press/" + e.post_id}
                          style={{ color: "#524f4f", fontSize: "18" }}
                        >
                          {e.post_title}
                        </Link>
                      </Card.Title>
                      <ListGroup className="list-group-flush">
                        <ListGroupItem
                          style={{
                            backgroundColor: "#F3F4F6",
                            color: "#868181",
                          }}
                        >
                          {h2p(e.post_content).substring(0, 50)}...
                        </ListGroupItem>
                        <ListGroupItem
                          style={{
                            backgroundColor: "#F3F4F6",
                            color: "#868181",
                          }}
                        >
                          {dateFormat(e.created_at, "yyyy-mm-dd")}
                        </ListGroupItem>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row>
              <Col className="d-flex flex-row-reverse">
                <Pagination size="sm">{items(totalCount)}</Pagination>
              </Col>
            </Row>
          </Tab.Pane>
        </Tab.Content>
        <Tab.Content style={{ padding: "0px 12 px" }}>
          <Tab.Pane eventKey="КОНТЕНТ" fluid>
            <Row xs={1} md={3} className="g-4 ">
              {content?.length > 0 ? (
                content?.map((e) => (
                  <Col>
                    <Card
                      style={{ height: "380px", backgroundColor: "#F3F4F6" }}
                    >
                      <Card.Img
                        variant="top"
                        src={webUrl + e.picture}
                        style={{ height: "160px" }}
                        onClick={() => navigate("/content/" + e.content_id)}
                      />
                      <Card.Body
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // flexWrap: "wrap",
                          justifyContent: "flex-end",
                          alignItems: "stretch",
                          alignContent: "center",
                        }}
                      >
                        <Card.Title>
                          <Link
                            to={"/content/" + e.content_id}
                            style={{ color: "#524f4f", fontSize: "18" }}
                          >
                            {e.content_title}
                          </Link>
                        </Card.Title>
                        <ListGroup className="list-group-flush">
                          <ListGroupItem
                            style={{
                              backgroundColor: "#F3F4F6",
                              color: "#868181",
                            }}
                          >
                            {h2p(e.content_html).substring(0, 50)}...
                          </ListGroupItem>
                          <ListGroupItem
                            style={{
                              backgroundColor: "#F3F4F6",
                              color: "#868181",
                            }}
                          >
                            {dateFormat(e.created_at, "yyyy-mm-dd")}
                          </ListGroupItem>
                        </ListGroup>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Тун удахгүй
                </p>
              )}
            </Row>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
}
