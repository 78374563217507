const info = [
  {
    header: "Онцлох сувгууд",
    channels: [
      {
        name: "DDISH Promo*",
        status: "HD",
        property: [1, 1, 1],
      },
      {
        name: "DDISH Social*",
        status: "SD",
        property: [1, 1, 1],
      },
    ],
  },
  {
    header: "Киноны сувгууд",
    channels: [
      {
        name: "Movie box",
        status: "HD",
        property: [1, 1, 1],
      },
      {
        name: "Movie box",
        status: "HD",
        property: [1, 1, 1],
      },
      {
        name: "Шилдэг кино сан*",
        status: "HD",
        property: [0, 1, 1, 0, 1],
      },
      {
        name: "Адал явдалт кино сан*",
        status: "HD",
        property: [0, 1, 1, 0, 1],
      },
      {
        name: "Хүүхдийн кино сан*",
        status: "HD",
        property: [0, 1, 1, 0, 1],
      },
      {
        name: "Холливуд цуврал*",
        status: "HD",
        property: [0, 1, 1, 0, 1],
      },
      {
        name: "Ази цуврал*",
        status: "HD",
        property: [0, 1, 1, 0, 1],
      },
      {
        name: "Монгол цуврал*",
        status: "HD",
        property: [0, 1, 1, 0, 1],
      },
      {
        name: "Хит цуврал*",
        status: "HD",
        property: [0, 1, 1, 0, 1],
      },
      {
        name: "Хүүхдийн багц*",
        status: "HD",
        property: [0, 1, 1, 0, 1],
      },
      {
        name: "Орос багц*",
        status: "HD",
        property: [0, 1, 1, 0, 1],
      },
      {
        name: "Аниме*",
        status: "SD",
        property: [0, 1, 1, 0, 1],
      },
      {
        name: "HBO*",
        status: "HD",
        property: [0, 1, 1, 0, 1],
      },
      {
        name: "HBO Hits*",
        status: "HD",
        property: [0, 1, 1, 0, 1],
      },
      {
        name: "HBO Family*",
        status: "HD",
        property: [1, 1, 1],
      },
      {
        name: "HBO Signature*",
        status: "HD",
        property: [0, 1, 1, 0, 1],
      },
      {
        name: "Cinemax*",
        status: "HD",
        property: [0, 1, 1, 0, 1],
      },
    ],
  },
  {
    header: "Спортын сувгууд",
    channels: [
      {
        name: "Premier Sports 1",
        status: "HD",
        property: [0, 0, 1],
      },
      {
        name: "Premier Sports 2",
        status: "HD",
        property: [0, 0, 1],
      },
      {
        name: "Premier Sports 3",
        status: "HD",
        property: [0, 0, 1],
      },
      {
        name: "Premier Sports 4",
        status: "HD",
        property: [1, 1, 1],
      },
      {
        name: "Premier Sports 5",
        status: "HD",
        property: [0, 0, 1],
      },
      // {
      //   name: "MNBA",
      //   status: "SD",
      //   property: [1, 1, 1],
      // },
      {
        name: "Eurosport",
        status: "SD",
        property: [0, 1, 1],
      },
      {
        name: "МҮОНТВ – MNB Sport",
        status: "SD",
        property: [1, 1, 1],
      },
    ],
  },
  {
    header: "Хүүхдийн сувгууд",
    channels: [
      {
        name: " Dreambox",
        status: "HD",
        property: [1, 1, 1],
      },
      {
        name: "Baby TV, Cbeebies, Карусель, Cartoon Network, Boomerang, Nickelodeon*,Balapan*, Lolly kids*",
        status: "SD",
        property: [1, 1, 1],
      },
    ],
  },
  {
    header: "МҮОНТВ сувгууд",
    channels: [
      {
        name: "МҮОНТВ",
        status: "HD",
        property: [1, 1, 1],
      },
      {
        name: "МҮОНТВ - Монголын мэдээ",
        status: "HD",
        property: [1, 1, 1],
      },
      {
        name: "МҮОНТВ - MNB World",
        status: "SD",
        property: [1, 1, 1],
      },
      {
        name: "МҮОНТВ - MNB Sport",
        status: "SD",
        property: [1, 1, 1],
      },
    ],
  },
  {
    header: "Өргөн нэвтрүүлгийн ТВ сувгууд",
    channels: [
      {
        name: "UBS, Боловсрол, NTV, Central TV",
        status: "HD",
        property: [1, 1, 1],
      },
      {
        name: "MN25, TV5, TV9, Eagle news, C1, Малчин ТВ, Mongol HD, SBN, Star TV, ETV, TenGer, TV8, TM, Сүлд ТВ",
        status: "SD",
        property: [1, 1, 1],
      },
    ],
  },
  {
    header: "Арилжааны сувгууд",
    channels: [
      {
        name: " Bloomberg Mongolia, Channel 11, MNC, Өлзий Телевиз, Глобал ТВ, Нийслэл ТВ, ONTV, Масс ТВ, Эко, Мянгат Малчин, Соён гэгээрүүлэгч, BBS, SB, Шууд ТВ, Талын Монгол, Health, O TV, TV5-2, TV9-2 (Cinema), ТВ4, Like, TB24, NTN, TB 6,GTV, Их засаг, Парламентын суваг, ТV 2, Бидний цөөхөн Монголчууд",
        status: "SD",
        property: [1, 1, 1],
      },
    ],
  },
  {
    header: "Танин мэдэхүйн сувгууд",
    channels: [
      {
        name: "History channel*, National Geographic Channel, Nat Geo Wild, Discovery Asia, Animal Planet*, Travel*, Lifetime*, Discovery science, Eve*, Supreme master*",
        status: "SD",
        property: [1, 1, 1],
      },
    ],
  },
  {
    header: "Олон улсын мэдээний сувгууд",
    channels: [
      {
        name: "CNN*, NHK World, Kazakh TV, CGTN, CCTV4*, France 24, TV5 Monde, BBC World News*, DW",
        status: "SD",
        property: [1, 1, 1],
      },
    ],
  },
  {
    header: "Энтертайнмент сувгууд",
    channels: [
      {
        name: "HGTV, Food Network, DMAX, Trace Sports Star*, Arirang, KBS World, Trace Urban*, MTV Live, Өвөр монгол",
        status: "SD",
        property: [1, 1, 1],
      },
    ],
  },
  {
    header: "Орос сувгууд",
    channels: [
      {
        name: "ORT-1, ТВ3, CTC*, Время*, Astana*, Qazaqstan* Домкино, Музыка Первого*",
        status: "SD",
        property: [1, 1, 1],
      },
    ],
  },
  {
    header: "Орон нутгийн ТВ сувгууд ",
    nullable: true,
    unique: 1,
    channels: [
      {
        name: "Дархан /DBS/*",
        status: "SD",
        property: [1, 1, 1],
      },
      {
        name: "Дорнод /Мижи, Дорнод, ДорнынТВ6/*",
        status: "SD",
        property: [1, 1, 1],
      },
      {
        name: "Хэнтий /Өндөрхаан/*",
        status: "SD",
        property: [1, 1, 1],
      },
      {
        name: "Баян-Өлгий /Дербес/*",
        status: "SD",
        property: [1, 1, 1],
      },
      {
        name: "Завхан /Air 1, Шинэ Завхан/*",
        status: "SD",
        property: [1, 1, 1],
      },
      {
        name: "Увс /MBC, Сама/*",
        status: "SD",
        property: [1, 1, 1],
      },
      {
        name: "Дорноговь /DTV/*",
        status: "SD",
        property: [1, 1, 1],
      },
      {
        name: "Өмнөговь /Говийн наран, UC, Алтан говь/*",
        status: "SD",
        property: [1, 1, 1],
        show: true,
      },
      {
        name: "Сэлэнгэ /Миний монгол/*",
        status: "SD",
        property: [1, 1, 1],
      },
      {
        name: "Төв /Төв/*",
        status: "SD",
        property: [1, 1, 1],
      },
      {
        name: "Архангай /AB/*",
        status: "SD",
        property: [1, 1, 1],
      },
      {
        name: "Баянхонгор /Баянхонгор/* ",
        status: "SD",
        property: [1, 1, 1],
      },
      {
        name: "Булган /Сайхан Монгол/*",
        status: "SD",
        property: [1, 1, 1],
      },
      {
        name: "Хөвсгөл /Түмний ТВ, Хөвсгөл ТВ/*",
        status: "SD",
        property: [1, 1, 1],
      },
      {
        name: "Эрдэнэт /ETV/* ",
        status: "SD",
        property: [1, 1, 1],
      },
    ],
  },
  {
    header: "Радиогийн сувгууд",
    channels: [
      {
        name: "Монголын үндэсний радио 1, Монголын Үндэсний радио 2, Лавайн эгшиг, Өлгий радио",
        status: "",
        property: [1, 1, 1],
      },
    ],
  },
  {
    header: "Төлбөрт сувгууд ",
    nullable: true,
    unique: 2,
    channels: [
      {
        name: "Шинэ кино*",
        status: "HD",
        property: [1, 1, 1],
      },
      {
        name: "DDISHLIVE*",
        status: "HD",
        property: [1, 1, 1],
      },
      {
        name: "Playboy*",
        status: "HD",
        property: [1, 1, 1],
      },
    ],
  },
];

module.exports = { info };
