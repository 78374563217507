import {
  GET_PRODUCT,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_SUCCESS,
  GET_ADDITIONAL_PRODUCT,
  GET_ADDITIONAL_PRODUCT_SUCCESS,
  GET_ADDITIONAL_PRODUCT_FAIL,
  GET_UPGRADE_PRODUCT,
  GET_UPGRADE_PRODUCT_SUCCESS,
  GET_UPGRADE_PRODUCT_FAIL,
  SELECT_PRODUCT,
  CLEAR_PRODUCT,
  GET_NOTT,
  GET_NOTT_ERROR,
} from "./types";
import { getSecured } from "../helper/requests";
export const getProduct = () => {
  return function (dispatch) {
    dispatch({ type: GET_PRODUCT });
    getSecured(
      "productList/prm",
      (res) => {
        dispatch({
          type: GET_PRODUCT_SUCCESS,
          payload: {
            isLeasingUser: res.data.isLeasingUser,
            productList: res.data.productList,
          },
        });
      },
      (err) => {
        dispatch({ type: GET_PRODUCT_FAIL, payload: { err: err.Error } });
      }
    );
  };
};

export const getNott = () => {
  return function (dispatch) {
    getSecured(
      `productlist/nott`,
      (res) => {
        dispatch({
          type: GET_NOTT,
          payload: {
            nott: res.data.additionalProducts,
          },
        });
      },
      (err) => {
        dispatch({ type: GET_NOTT_ERROR, payload: { err: err.Error } });
      }
    );
  };
};

export const getAdditionalProduct = (productID) => {
  return function (dispatch) {
    dispatch({
      type: GET_ADDITIONAL_PRODUCT,
      payload: {
        id: productID,
      },
    });
    getSecured(
      "productList/prm/" + productID,
      (res) => {
        dispatch({
          type: GET_ADDITIONAL_PRODUCT_SUCCESS,
          payload: {
            additionalProducts: res.data.additionalProducts,
          },
        });
      },
      (err) => {
        dispatch({
          type: GET_ADDITIONAL_PRODUCT_FAIL,
          payload: { err: err.Error },
        });
      }
    );
  };
};

export const getUpgradeProduct = (productID) => {
  return function (dispatch) {
    dispatch({
      type: GET_UPGRADE_PRODUCT,
      payload: {
        id: productID,
      },
    });
    getSecured(
      "upgradeProductNew/" + productID,
      (res) => {
        dispatch({
          type: GET_UPGRADE_PRODUCT_SUCCESS,
          payload: {
            upgradeProducts: res.data.upProducts,
          },
        });
      },
      (err) => {
        dispatch({
          type: GET_UPGRADE_PRODUCT_FAIL,
          payload: { err: err.Error },
        });
      }
    );
  };
};

export const selectProduct = (product) => {
  return function (dispatch) {
    dispatch({
      type: SELECT_PRODUCT,
      payload: product,
    });
  };
};

export const clearProduct = () => {
  return function (dispatch) {
    dispatch({
      type: CLEAR_PRODUCT,
    });
  };
};
