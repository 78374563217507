import axios from "axios";

import { useState, useEffect } from "react";
import { webApiUrl } from "../../helper/globals";
import { useParams } from "react-router";
import { Accordion, Container, Row } from "react-bootstrap";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import Breadcrumbs from "../Layout/Breadcrumb";

export default function Help() {
  const [post, setPost] = useState([]);
  const { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      axios
        .get(webApiUrl + "helpdesk/" + id) //todo: change id
        .then((res) => {
          const post = res.data;
          setPost(post);
        })
        .catch((err) => console.log(err));
    }
  }, [id, loaded]);
  return (
    <Breadcrumbs
      title={post.title}
      content={
        <Container className="section-content">
          <Row>
            <Accordion defaultActiveKey="0">
              {post?.result?.map((post) => {
                return (
                  <AccordionItem eventKey={post.id}>
                    <AccordionHeader style={{ textColor: "#868181" }}>{post.title}</AccordionHeader>
                    <AccordionBody>
                      <div dangerouslySetInnerHTML={{ __html: post.content }} style={{ overflowY: "hidden", overflowX: "auto" }} />
                    </AccordionBody>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </Row>
        </Container>
      }
      route={[{ url: "/helpdesk", text: "Тусламж" }]}
    />
  );
}
