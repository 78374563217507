import { Button, Col, Modal,Row} from "react-bootstrap";
import { useNavigate } from "react-router";

export default function PleaseLogin(props) {
  const navigate = useNavigate();
  return (
    <Modal show={props.isOpen} onHide={props.onHide} centered fullscreen="sm">
      <Modal.Header closeButton>
        <Modal.Title>Нэвтрэнэ үү</Modal.Title>
      </Modal.Header>
      <Modal.Body>Та нэвтэрч орсноор үйлчилгээг авах боломжтой.</Modal.Body>
      <Modal.Footer>
        <Col>
          <Row>
            <Button
              variant="secondary"
              className="modal-cancel"
              onClick={props.onHide}
            >
              БОЛИХ
            </Button>
          </Row>
        </Col>
        <Col>
          <Row>
            <Button variant="primary" onClick={() => navigate("/login")}>
              НЭВТРЭХ
            </Button>
          </Row>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}
