import {
  GET_INFO_FAIL,
  GET_INFO_SUCCESS,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAIL,
  LOGOUT,
  POSTPAID,
  PREPAID
} from "../actions/types";
const user = JSON.parse(localStorage.getItem("user"));
const userReducer = (state = user, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INFO_SUCCESS: {
      return {
        ...state,
        userFirstName: payload.userFirstName,
        userLastName: payload.userLastName,
        userRegNo: payload.userRegNo,
        cardNo: payload.cardNo,
        adminNumber: payload.adminNumber,
        productId: payload.productId,
        activeProducts: payload.activeProducts ?? [],
        additionalProducts: payload.additionalProducts ?? [],
        activeCounters: payload.activeCounters ?? [],
        err: null,
      };
    }
    case GET_INFO_FAIL: {
      return { ...state, err: payload.err };
    }

    case GET_ACCOUNT_SUCCESS: {
      return { ...state, account: payload, err: null };
    }

    case GET_ACCOUNT_FAIL: {
      return { ...state, err: payload.err };
    }
    case PREPAID: {
      return { ...state, isPostpaid: false };
    }
    case POSTPAID: {
      return { ...state, isPostpaid: true };
    }

    case LOGOUT: {
      return null;
    }
    default:
      return state;
  }
};

export default userReducer;
