import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
export default function GolomtRedirect() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(searchParams.get("success"));
    if (searchParams.get("success") === "0") {
      return navigate("/payment/result");
    } else return navigate("/js/app.efb8c7cd3f442e6b55e4.js");
  });
  return <div style={{ marginTop: 100 }}></div>;
}
