import { Col, Container, Row } from "react-bootstrap";
import * as actions from "../../actions/product";
import * as userActions from "../../actions/user";
import * as chargeActions from "../../actions/charge";
import * as authActions from "../../actions/auth";

import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect } from "react";
function UserInfo(props) {
  useEffect(() => {
    props.getUserInfo();
    props.getAccount();
  }, [props]);

  const user = useSelector((state) => state.user);

  return (
    <Container className="section-content">
      <Row xs={1} sm={1} md={3} lg={3}>
        <Col class="column">
          <div class="item-box">
            <h5>Хэрэглэгчийн мэдээлэл</h5>
            <div class="container">
              <ul>
                <li>
                  <span>Смарт картын дугаар</span>
                  <p>{user?.cardNo}</p>
                </li>
                <li>
                  <span>Админ дугаар</span>
                  <p>{user?.adminNumber}</p>
                </li>
                <li>
                  <span>Овог нэр</span>
                  <p>
                    {user?.userLastName} {user?.userFirstName}
                  </p>
                </li>
                <li>
                  <span>
                    <Link to="/service/vat">НӨАТ-ын баримтын түүх</Link>
                  </span>
                </li>
                <li class="hide-for-large-only">
                  <span>
                    <button
                      class="button expanded"
                      onClick={() => props.logout()}
                    >
                      Гарах
                    </button>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col class="column">
          <div class="item-box">
            <h5>Идэвхтэй үйлчилгээ</h5>
            <div class="container">
              <ul>
                <li>
                  {user?.activeProducts?.map((e) => {
                    return (
                      <>
                        <span>{e.productName}</span>
                        <p>{e.endDate.replace(" 23:59:59", "")}</p>
                      </>
                    );
                  })}
                  {user?.additionalProducts?.map((e) => {
                    return (
                      <>
                        <span>{e.productName}</span>
                        <p>{e.endDate.replace(" 23:59:59", "")}</p>
                      </>
                    );
                  })}
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col class="column">
          <div class="item-box">
            <h5>Дансны мэдээлэл</h5>
            <div class="container">
              <ul>
                <li>
                  <span>{user?.account?.counter?.counterName}</span>
                  <p>
                    {user?.account?.counter?.counterBalance}
                    {user?.account?.counter?.counterMeasureUnit}
                  </p>
                  {user?.activeCounters?.map((e) => {
                    return (
                      <>
                        <span>{e.counterName}</span>
                        <p>
                          {e.counterBalance}
                          {e.counterMeasureUnit} - {e.counterExpireDate}
                        </p>
                      </>
                    );
                  })}
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

const mapActionToProps = (dispatch) => {
  return {
    getProduct: () => dispatch(actions.getProduct()),
    getAdditional: (id) => dispatch(actions.getAdditionalProduct(id)),
    getUpgrade: (id) => dispatch(actions.getUpgradeProduct(id)),
    selectProduct: (product) => dispatch(actions.selectProduct(product)),
    getUserInfo: () => dispatch(userActions.getInfo()),
    getAccount: () => dispatch(userActions.getCounter()),
    searchAccount: (cardNo) => dispatch(chargeActions.searchAccount(cardNo)),
    searchClear: () => dispatch(chargeActions.searchClear()),
    logout: () => dispatch(authActions.logout()),
  };
};
export default connect(null, mapActionToProps)(UserInfo);
