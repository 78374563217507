import { connect, useSelector } from "react-redux";
import * as userActions from "../actions/user";
import * as merchantActions from "../actions/merchant";
import * as messageActions from "../actions/messages";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { TOAST_SUCCESS, TOAST_WARNING } from "../actions/types";
import { getSecured } from "../helper/requests";

function BankCard(props) {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const order = useSelector((state) => state.order);
  const [savedCards, setSavedCards] = useState(order?.savedCards);
  function SavedCards() {
    return (
      <>
        {savedCards?.map((e) => {
          return (
            <Col>
              <Card
                style={{ borderRadius: 20, borderColor: "#0047ba" }}
                onClick={() => {
                  SavedCardOrder(order?.orderId, e.cardId);
                }}
                className="cursor-pointer card-bank"
              >
                <h4
                  style={{
                    color: "#0047ba",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                  className="text-center"
                >
                  <b>{e.bankName}</b>
                </h4>
                <p className="text-center">
                  Картын дугаар: <b>{e.cardNumber}</b>
                </p>
                <p className="text-center">{e.createdDate}</p>
              </Card>
            </Col>
          );
        })}
        {/* <div className="text-center">эсвэл</div> */}
        <Col>
          <Row>
            <Button
              className="expanded"
              onClick={() => {
                RedirectToCardUrl(order?.paymentUrl);
              }}
            >
              Карт нэмэх
            </Button>
          </Row>
        </Col>
        <Col style={{ paddingTop: 10 }}>
          <Row>
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="card-button-custom"
            >
              Цуцлах
            </button>
          </Row>
        </Col>
      </>
    );
  }
  function SavedCardOrder(transactionId, cardId) {
    getSecured(
      `bankpayment/token/${transactionId}/${cardId}`,
      (res) => {
        props.setMessage({
          type: TOAST_SUCCESS,
          text: "Таны гүйлгээ амжилттай боллоо.",
        });
        props.orderClear();
      },
      (err) => {
        props.setMessage({
          type: TOAST_WARNING,
          text: "Гүйлгээ хийхэд алдаа гарлаа.",
        });
        props.orderClear();
      }
    );
    navigate("/");
  }
  function RedirectToCardUrl(formUrl) {
    window.location.replace(formUrl);
  }
  useEffect(() => {
    if (!auth.isLoggedIn) RedirectToCardUrl(order.paymentUrl);
    // if (savedCards?.length == 0) RedirectToCardUrl(order.paymentUrl);
  }, []);

  return (
    <Container>
      <Modal show={true} centered>
        <Modal.Header>Та гүйлгээ хийх картаа сонгоно уу.</Modal.Header>
        <Modal.Body>{SavedCards()}</Modal.Body>
      </Modal>
    </Container>
  );
}
const mapActionToProps = (dispatch) => {
  return {
    getAccount: () => dispatch(userActions.getCounter()),
    merchantOrder: (bank, selectedProduct) =>
      dispatch(merchantActions.merchantOrder(bank, selectedProduct)),
    setMessage: (payload) => dispatch(messageActions.setMessage(payload)),
    orderClear: () => dispatch(merchantActions.orderClear()),
  };
};
export default connect(null, mapActionToProps)(BankCard);
