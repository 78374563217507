import axios from "axios";
import { useEffect, useState } from "react";
import { Card, Col, Container, ListGroup, Row, Tab } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { webApiUrl, webUrl } from "../helper/globals";
import Breadcrumbs from "./Layout/Breadcrumb";
import CurrencyFormat from "react-currency-format";

export default function Devices() {
  const [title, setTitle] = useState("ТӨХӨӨРӨМЖ");
  const [packages, setPackages] = useState([]);
  const [devices, setDevices] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios.get(webApiUrl + "get_devices/device").then((res) => {
      setDevices(res.data.result.result);
    });
  }, []);
  useEffect(() => {
    axios.get(webApiUrl + "get_devices/accessory").then((res) => {
      setAccessories(res.data.result.result);
    });
  }, []);
  useEffect(() => {
    axios.get(webApiUrl + "get_devices/package").then((res) => {
      setPackages(res.data.result.result);
    });
  }, []);

  function cards(productList) {
    return (
      <Row
        xs={1}
        md={3}
        className="g-4"
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "start",
        }}
      >
        {productList.map((product) => {
          return (
            <Col>
              <Card
                onClick={() => navigate("/store/" + product.device_id)}
                style={{ borderTop: "none", height: "380px" }}
              >
                <div
                  className="text-right dd-values-con"
                  style={{ fontSize: "1.3rem", paddingRight: "10px" }}
                >
                  <CurrencyFormat
                    value={product?.price}
                    displayType="text"
                    thousandSeparator="'"
                  />
                  ₮
                </div>

                <Card.Body
                  style={{
                    padding: 0,

                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    justifyContent: "center ",
                    alignItems: "stretch",
                    alignContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={webUrl + product.picture}
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = webUrl + "img/default.png";
                      }}
                      style={{
                        width: "271px",
                      }}
                      alt=""
                    />
                  </div>
                </Card.Body>
                <Card.Title
                  className="text-center"
                  style={{
                    paddingBottom: "15px",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    justifyContent: "flex-end ",
                    alignItems: "stretch",
                    alignContent: "center",
                  }}
                >
                  <Link to={"/store/" + product.device_id}>
                    {product.device_title}
                  </Link>
                </Card.Title>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  }

  return (
    <Breadcrumbs
      title={title}
      content={
        <Container className="section-content">
          <Row className="md-center">
            <Tab.Container defaultActiveKey="#package">
              <ListGroup className="list-group-horizontal-md">
                <ListGroup.Item
                  action
                  href="#package"
                  className="tabs-title tabs"
                  onClick={() => setTitle("ТӨХӨӨРӨМЖ")}
                >
                  ТӨХӨӨРӨМЖ
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href="#device"
                  className="tabs-title tabs"
                  onClick={() => setTitle("ХҮЛЭЭН АВАГЧ")}
                >
                  ХҮЛЭЭН АВАГЧ
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href="#accessory"
                  className="tabs-title tabs"
                  onClick={() => setTitle("ДАГАЛДАХ ХЭРЭГСЭЛ")}
                >
                  ДАГАЛДАХ ХЭРЭГСЭЛ
                </ListGroup.Item>
              </ListGroup>
              <Tab.Content>
                <Tab.Pane eventKey="#package">{cards(packages)}</Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="#device">{cards(devices)}</Tab.Pane>
              </Tab.Content>
              <Tab.Content>
                <Tab.Pane eventKey="#accessory">{cards(accessories)}</Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Row>
        </Container>
      }
      route={[{ url: "/store", text: "Төхөөрөмж" }]}
    />
  );
}
