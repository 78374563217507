import axios from "axios";

import { useState, useEffect } from "react";
import { mainUrl, webApiUrl } from "../../helper/globals";
import { useParams } from "react-router";
import { Row, Col } from "react-bootstrap";
import Breadcrumbs from "../Layout/Breadcrumb";
import { Twitter, Facebook } from "react-social-sharing";
import MetaTags from "react-meta-tags";
var h2p = require("html2plaintext");

export default function Content() {
  const [post, setPost] = useState({
    content_title: "",
    content_title: "",
    content_id: 0,
    content_type: "",
    created_at: "",
    is_active: 1,
    ffrom: "",
    tto: "",
    type: "",
    picture: "",
    backimage: "/u/promo/1-1.jpg",
  });
  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (!isLoaded) {
      axios
        .get(webApiUrl + "content/" + id)
        .then((result) => {
          const post = result.data.result[0];
          setPost(post);
          setIsLoaded(true);
        })
        .catch((err) => console.log(err));
    }
  });
  console.log(post);
  return (
    <>
      <MetaTags>
        <meta
          property="og:description"
          content={h2p(post.content_html).substring(0, 50) + "..."}
        />
        <meta
          property="og:url"
          content={["https://ddishtv.mn/press" + post.content_id]}
        />
        <meta property="og:title" content={[post.content_title]} />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content={["https://ddishtv.mn:3000/" + post.picture]}
        />
      </MetaTags>
      <Breadcrumbs
        title="Хэвлэлийн мэдээ"
        content={
          <Row className="section-content">
            <h5>{[post.content_title]}</h5>
            <Row>
              <Col md={{ span: 3, offset: 9 }} float-end>
                <Twitter link={mainUrl + `content/${id}`} label="Tweet" />
                <Facebook link={mainUrl + `content/${id}`} label="Share" />
              </Col>
            </Row>
            <div
              dangerouslySetInnerHTML={{ __html: post.content_html }}
              style={{ overflowY: "hidden", overflowX: "auto" }}
            />
            <Row>
              <Col md={{ span: 3, offset: 9 }} float-end>
                <Twitter link={mainUrl + `content/${id}`} label="Tweet" />
                <Facebook link={mainUrl + `content/${id}`} label="Share" />
              </Col>
            </Row>
          </Row>
        }
        route={[{ url: "/press", text: "Хэвлэлийн мэдээ" }]}
      />
    </>
  );
}
